export const en = {
  account: 'Account',
  overview: 'Overview',
  accountDetails: 'Account Details',
  accountName: 'Account Name',
  dateJoined: 'Date Joined',
  merchantDetails: 'Merchant Details',
  usage: 'Usage',
  totalOrders: 'Total Orders',
  totalMessages: 'Total Messages',
  users: 'Users',
  username: 'Username',
  nameRequired: 'Account name is required',

  userType: 'User Type',
  memberShipType: 'Membership Type',
  merchantInfo: 'Merchant Info',
  accountNameLimit: 'Account Name can only be 30 characters long',
  waiterAIEnabled: 'Show AI Waiter',
  showAIRecommendations: 'Show AI Recommendations',
  enableEmailVerification: 'Enable email verification',
  emailVerificationField: 'Email verification field (e.g. gmail.com)',
  domainRequired: 'Email domain verification field is required',
  itemImageRecc: 'Item Image recommendations',
  itemImageReccDesc: 'Will generate stunning AI images for your items',
  itemTaglineRecc: 'Item Tagline Recommendations',
  itemTaglineReccDesc: 'Will recommend AI generated taglines for your items',
};

export const ar: typeof en = {
  itemImageRecc: 'Item Image recommendations',
  itemImageReccDesc: 'Will generate stunning AI images for your items',
  itemTaglineRecc: 'Item Tagline Recommendations',
  itemTaglineReccDesc: 'Will recommend AI generated taglines for your items',
  domainRequired: 'اسم الموقع الدومين للبريد الألكتروني مطلوب',
  emailVerificationField: 'خانة تأكيد البريد الألكتروني',
  enableEmailVerification: 'تفعيل خاصية تأكيد البريد الألكتروني',
  showAIRecommendations: 'تفعيل خاصية المنصوح بها',
  waiterAIEnabled: 'تفعيل الويتر ذو الذكاء الأصطناعي',
  account: 'الحساب',
  nameRequired: 'الاسم مطلوب',
  accountDetails: 'معلومات الحساب',
  merchantInfo: 'معلومات البائع',
  overview: 'نبذة',
  accountNameLimit: 'لابد من ان اسم الحساب يكون ٣٠ حرف او اقل',
  accountName: 'اسم الحساب',
  dateJoined: 'تاريخ الأشتراك',
  merchantDetails: 'تفاصيل مالك المنشأة',
  usage: 'بيانات الاستخدام',
  totalOrders: 'مجموع الطلبات',
  totalMessages: 'مجموع الرسائل',
  users: 'المستخدمين',
  username: 'اسم المستخدم',
  userType: 'نوع المستخدم',
  memberShipType: 'نوع الإشتراك',
};
