import { useConfirm } from 'src/common/components/Confirm/hooks/useConfirm';
import { useLogout } from 'src/common/hooks/useLogout';
import { t } from 'src/i18n/translate';

export const useHandleLogout = () => {
  const { logout } = useLogout();
  const { confirm } = useConfirm();

  const handleLogout = async () => {
    try {
      await confirm({
        title: t('common:logoutConfirmTitle'),
        message: t('common:logoutConfirmMessage'),
        confirmationText: t('common:yes'),
        cancellationText: t('common:cancel'),
      });
      logout();
    } catch (e) {}
  };

  return { handleLogout };
};
