export const en = {
  title: 'App Market',
  integrations: 'Integrations',
  integrationSuccess: 'Integration Installed Successfully!',
  integrationError: 'Unable To Install Integration',
  isNotAuthenticated:
    'Not authenticated. Please re-install the integration to fix the issue',
  reInstall: 'Re-Install',
  install: 'Install',
  installed: 'Installed',
  pending: 'Pending',
  resetDesc:
    'In order to properly integrate and fetch all data from the POS, you must reset all current data from you account. This can be done by clicking the “Reset“ button below.',
  reset: 'Reset',
  resetRecommnended:
    'Recommended when installing integration for the first time',
  continueWithoutReset: 'Continue without reset',
  resetWaitMessage:
    'Resetting account might take few seconds to complete. Please wait.',
  resetSuccess: 'Account reset successful',
  resetError: 'There was an error resetting the account',
};

export const ar: typeof en = {
  resetDesc:
    'ننصح بحذف المحتويات الحساب عند تثبيت الربط لأول مرة. سنقوم بحذف المحتويات',
  reset: 'حذف محتويات الحساب',
  resetRecommnended: 'حذف المحتويات  منصوح به عند الربط لأول مرة',
  continueWithoutReset: 'اربط من دون حذف المحتويات',
  resetWaitMessage: 'قد يستغرق حذف محتويات الحساب دقيقة. الرجاء الأنتظار',
  resetSuccess: 'تم حذف المحتويات بنجاح',
  resetError: 'حصل خطأ في عملية حذف المحتويات',
  title: 'صفحة الربط',
  integrations: 'الربط',
  integrationError: 'عفواً، حصل خطأ في عملية الربط',
  integrationSuccess: 'تم الربط بنجاح',
  isNotAuthenticated: 'غير مصرح، الرجاء اعادة عملية الربط لأصلاح المشكلة',
  reInstall: 'إعادة الربط',
  install: 'ربط',
  installed: 'تم الربط',
  pending: 'جاري التنفيذ',
};
