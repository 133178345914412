import { useQuery } from 'react-query';
import Api from 'src/api/Api';
import { ReactQueryKey } from 'src/common/enums';

export const useFetchAccount = () => {
  const {
    data,
    isLoading: loading,
    error,
  } = useQuery(ReactQueryKey.Account, Api.fetchAccount, {
    staleTime: Infinity,
    retry: false,
  });

  const merchant =
    data?.merchants && data.merchants.length > 0
      ? data.merchants[0]
      : undefined;

  return {
    account: data,
    membership: data?.membership,
    isPaid: true /* data?.membership?.type === MembershipType.Paid */,
    merchants: data?.merchants,
    user: data?.users[0],
    merchant,
    loading,
    error,
    currency: merchant?.currency,
  };
};
