export const en = {
  pickup_selection_error_title: 'Sorry',
  pickup_selection_error: 'You must have one pickup type enabled',
  locale_currency: 'Locale & Currency',
  translations_currency: 'Translations & Currency',
  general: 'General',
  settings: 'Settings',
  settingsUpdated: 'Settings Updated Successfully!',
  selfService: 'Self Serve',
  selfServiceInfo: 'Customer will pickup the order',
  waiterService: 'Waiter Service',
  requestWaiter: 'Allow Customer To Request Waiter',
  requestWaiterInfo:
    'Allow Customers To Request Waiter Assistance via the webapp',
  requestCheckout: 'Allow Customers to Request Check',
  requestCheckoutInfo: 'Allow customers to request check via the webapp',
  tGoOrders: 'Allow customer to order items ToGo (take home)',
  toGoInfo:
    'When enabled, the customer can dine in and order things like Pizza, Cola but also order desserts ToGo',
  waiterServiceInfo: 'Waiter Will serve the order',
  settingsNotUpdated: 'Unable To Update Settings',
  openTab: 'Open/Closed Tab (Dine-In)',
  openTabOpen: 'Open Tab',
  openTabTooltip: 'Customer places multiple orders before payment',
  openTabClose: 'Close Tab',
  openTabDesc:
    'Allow customers to place multiple orders before payment. Open tab works better with high end restaurants.',
  closeTabDesc: 'Customer will not be able to place multiple orders at a time',
  orderTypes: 'Order Types',
  payments: 'Payments',
  delivery: 'Delivery',
  orders: 'Orders',
  subHeading: 'Do not forget to press save after making some changes.',
  test: 'Test',
  pickup: 'Pickup',
  inStorePickup: 'In Store Pickup',
  fleetHeading: 'Fleet Management',
  noFleetHeading: 'No Fleet Management',
  office: 'Office',
  room: 'Room',
  address: 'Address',
  theatre: 'Theatre',
  other: 'Other',
  driveThru: 'Drive Thru',
  driveThruDesc:
    'Customer will notify you at arrival to pickup the order from their car',
  cash: 'Cash',
  dineIn: 'Dine In',
  onlinePayment: 'Online Payment',
  cardPayment: "Card (via Driver's PoS)",
  payAtStore: 'Pay at Store',
  pickupDesc: 'Customer picks up the order',
  deliveryDesc: 'We manage your fleet',
  dineInDesc: 'Table dine in orders',
  minimumDeliveryOrder: 'Minimum Delivery order',
  minimumDeliveryOrderDesc:
    'Customers will need order the minimum before qualifying for delivery',
  deliveryFee: 'Delivery fee',
  deliveryFeeDesc: 'Customers will pay this fee in addition to the order total',
  fleetManagement: 'In house fleet vs. Third party fleet',
  fleetManagementDesc:
    'Are you using your own fleet only or outsourcing delivery to third party. If you don’t know select, default',
  locationTypeTitle: 'Location Type',
  locationTypeDesc:
    'What type of location are you going to deliver to your customer',
  deliveryRadius: 'Delivery Radius',
  deliveryRadiusDesc:
    'Accept delivery orders for customers within this radius only',
  tax: 'Tax',
  taxDesc: 'Is tax included in your menu prices?',
  taxIncludedInPrices: 'Tax is included in prices',
  useSameTimings: 'Apply same Hours for all stores',
  inHouseFleet: 'In house fleet',
  thirdPartyFleet: 'Third party fleet (default)',
  ordersForPaidMembers: 'Orders are enabled for paid members only',
  currency: 'Currency',
  add: 'Add',
  stores: 'Stores',
  menu: 'Menu',
  translations: 'Translations',
  otherLocationDesc: 'Customer can enter location notes',
  theatreDesc: 'Customer enters a seat number',
  seatNo: 'Seat No.',
  officeNo: 'Office No.',
  roomNo: 'Room No.',
  officeDesc: 'Customer enters an office number',
  roomDesc: 'Customer enters a room number in a hotel or a similar place',
  addressDesc: 'Customer enters a location by map',
  enterTax: 'Enter Tax Percentage',
  fleetManagementInfo:
    'Let us manage assignments for your drivers. Your drivers will download an app and receive order notifications automatically',
  noFleetManagementInfo:
    'You manage your own fleet assignments. For each order assignment, we send a message to your driver to notify them of the deliver. No app is needed!',
  km: 'KM',
  mi: 'MI',
  onDemand: 'On Demand',
  onDemandInfo: 'On Demand Info',
  selectDeliveryProvider: 'Select Your Delivery Provider',
  selectDeliveryProviderDesc:
    'Local third party provider will take care of delivering orders to your customers',
  noOnDemandDelivery:
    'Please connect with a local third party delivery integrations first',
  seeAvailableIntegration: 'SEE AVAILABLE INTEGRATIONS',
  mustHavePaymentMethod: 'Must have one payment method selected',
  toGo: 'To Go',
};

export const ar: typeof en = {
  toGo: 'طلبات ToGo لعملاء الطلبات المحلية',
  closeTabDesc: 'لن يتمكن العميل من تقديم طلبات متعددة في وقت واحد',
  mustHavePaymentMethod: 'لابد من اختيار طريقة الدفع',
  pickup_selection_error_title: 'عفوا',
  pickup_selection_error: 'لابد من وجود اختيار واحد على الأقل لطلبات الأستلام',
  selfService: 'العميل يقوم باستلام الطلب بشكل ذاتي من دون الحاجة للنادل',
  selfServiceInfo: 'خدمة الطلبات الذاتيه',
  waiterService: 'خدمة الطلبات مع النادل',
  waiterServiceInfo: 'النادل يقوم بتقديم الطلب للعميل',
  requestWaiter: 'طلب النادل عن طريق المنيو',
  requestWaiterInfo: 'السماح للعميل بطلب النادل عن طريق المنو',
  requestCheckout: 'طلب الفاتورة عن طريق المنيو',
  requestCheckoutInfo: 'السماح للعميل بطلب الفاتورة عن طريق المنو',
  tGoOrders: 'اسمح للعميل بطلبات  سفري',
  toGoInfo:
    'اذا تم تفعيل هذي الخاصية، بإمكان العميل طلب أصناف سفري. مثال: العميل يطلب طلبات محليه (بيتزا وكولا) ويطلب ايضاً حلى سفري',
  selectDeliveryProviderDesc:
    'مزودون خدمة التوصيل سيقومون بتوصيل الطلبات الى عملائك. سيتم الربط مع مزودين الخدمة بشكل متكامل لتسهيل للك الية الطلب',
  onDemand: 'على حسب الاحتياج',
  seeAvailableIntegration: 'عرض مزودين الخدمة للربط',
  selectDeliveryProvider: 'اختر مزود الخدمة',
  noOnDemandDelivery: 'الرجاء الربط مع مزود خدمة توصيل اولاً',
  onDemandInfo: 'معلومات طلبات التوصيل',
  openTab: 'فاتورة مفتوحة',
  openTabDesc:
    'اسمح للعملاء بفتح فاتورة واضافة عدد من الطلبات قبل عملية الدفع. هذه الخاصية تنطبق على المطاعم الفاخرة',
  openTabOpen: 'فاتورة مفتوحة',
  openTabTooltip: 'السماح للعميل بتنفيذ اكثر من طلب قبل عملية الدفع',
  openTabClose:
    'فاتورة مغلقة (العميل سيقوم بدفع قيمة الطلب الأول قبل تنفيذ اي طلب اخر)',
  general: 'العام',
  settings: 'الإعدادت',
  orderTypes: 'نوع الطلبات',
  orders: 'طلبات',
  office: 'مكتب',
  room: 'الغرفة',
  theatre: 'صالة سينما أو مسرح',
  other: 'اخر',
  address: 'عنوان على الخريطة',
  stores: 'المتاجر',
  settingsUpdated: 'تم تحديث الأعدادات',
  settingsNotUpdated: 'لايمكن تحديث الأعدادات في الوقت الحالي',
  menu: 'القائمة',
  translations: 'لغات الترجمة',
  locationTypeTitle: 'كيفية تسجيل مقر العميل',
  locationTypeDesc: 'نوع المعلومات اللتي تحتاجها من العميل لتوصيل الطلب',
  payments: 'طريقة الدفع',
  delivery: 'التوصيل',
  subHeading: 'لاتنسى الضغط على حفظ بعد تغير الإعدادات ',
  pickup: 'الاستلام',
  cash: 'كاش',
  dineIn: 'محلي',
  fleetHeading: 'إدارة المناديب',
  noFleetHeading: 'من دون إدارة المناديب',
  onlinePayment: 'مدفوعات الكترونية',
  inStorePickup: 'الاستلام داخل المنشأة',
  driveThru: 'الاستلام من السيارة',
  driveThruDesc:
    'العميل يقوم بتنبيه المنشأه عند الوصول لاستلام الطلب من السيارة',
  payAtStore: 'الدفع في المنشأة',
  pickupDesc: 'العميل يستلم الطلب داخل المنشأة',
  deliveryDesc: 'إدارة المناديب وتتبعهم على الخريطة',
  dineInDesc: 'العميل يطلب طلب محلي في المنشأة',
  minimumDeliveryOrder: 'الحد الأدنى لطلبات التوصيل',
  minimumDeliveryOrderDesc:
    'لابد من العميل شراء محتويات بالحد الأدنى لتقديم خدمة التوصيل',
  deliveryFee: 'رسوم التوصيل',
  deliveryFeeDesc: 'رسوم التوصيل مضافة الى قيمة الطلب',
  fleetManagement: 'مناديب موظفون أو متعاقدين',
  fleetManagementDesc:
    'هل المناديب موظفون في المنشأة (Captive) أو متعاقدين (Contractors)؟',
  deliveryRadius: 'نطاق التوصيل',
  deliveryRadiusDesc: 'استقبل طلبات توصيل في النطاق المحدد',
  tax: 'الضريبة',
  taxDesc: 'هل الضريبة مضافة الى الأسعار؟',
  taxIncludedInPrices: 'الاسعار تشمل الضريبة',
  useSameTimings: 'عمم الأوقات أدناه على جميع المتاجر',
  inHouseFleet: 'مناديب موظفون (Captive)',
  thirdPartyFleet: 'مناديب متعاقدين (Contractors)',
  test: 'تجربة',
  ordersForPaidMembers: 'خدمة الطلبات متوفرة باشتراك شهري',
  currency: 'العملة',
  add: 'اضف',
  otherLocationDesc: 'العميل يقوم بإدخال معلومات الموقع بشكل نصي',
  theatreDesc: 'العميل يقوم بإدخال رقم الكرسي',
  seatNo: 'رقم الكرسي',
  officeNo: 'رقم المكتب',
  roomNo: 'رقم الغرفة',
  officeDesc: 'العميل يقوم بإدخال رقم المكتب',
  roomDesc: 'العميل يقوم بإدخال رقم الغرفة في فندق او مكان مماثل',
  addressDesc: 'العميل يقوم بإدخال الموقع عن طريق الخريطة',
  enterTax: 'ادخل القيمة الضريبية',
  cardPayment: 'شبكة (عن طريق جهاز المندوب)',
  fleetManagementInfo:
    'سنقوم بإدارة مناديبك بشكل تلقائي. سيتم تعين الطلبات لمناديبك بشكل تلقائي عن طريق تطبيق خاص للمناديب ',
  noFleetManagementInfo:
    'انت مسؤول عن إدارة مناديبك. بإمكانك تعيين المندوب لكل طلب وسنقوم بإرسال رساله لتنبيه المندوب بالطلب',
  km: 'كيلو',
  mi: 'ميل',
  locale_currency: 'العملة والأعدادات المحلية',
  translations_currency: 'العملة واللغات',
};
