import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { queryClient } from 'src/App';
import { PrimaryButton } from 'src/common/components/Btn/PrimaryButton';
import { Modal } from 'src/common/components/Modal';
import { ReactQueryKey } from 'src/common/enums';
import { useMakeLink } from 'src/links-qrcodes/hooks/useMakeLink';
import { useFetchAccount } from 'src/user/hooks/useFetchAccount';

interface Props {
  onClose?: () => void;
  open: boolean;
}

export const UpdateMainLinkModal = ({ onClose, open }: Props) => {
  const [linkValue, setLinkValue] = useState('');

  const { merchant } = useFetchAccount();

  const onSuccess = () => {
    onClose?.();
    queryClient.invalidateQueries([ReactQueryKey.FetchLinksV2, merchant?.id]);
  };

  const {
    createLink,
    link,
    loading: creatingLink,
    error,
  } = useMakeLink(undefined, onSuccess);

  useEffect(() => {
    if (link) {
      setLinkValue(link?.id);
    }
  }, [link]);

  const { t } = useTranslation();

  return (
    <Modal isOpen={open} onClose={onClose}>
      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div className="sm:flex  sm:items-start">
          <div className="mt-3 text-center sm:mt-0 sm:text-left">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {t('onlineMenu:updateMainLink')}
            </h3>

            <div dir="ltr" className="pt-6 mb-3 sm:col-span-2 sm:mt-0">
              <div className="flex max-w-lg rounded-md shadow-sm">
                <span className="inline-flex items-center rounded-l-md  border border-r-0  border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                  {link?.origin}/
                </span>
                <input
                  type="text"
                  value={linkValue}
                  onBeforeInput={e =>
                    /* @ts-ignore */
                    !allowedCharacters.includes(e.data.toLowerCase()) &&
                    e.preventDefault()
                  }
                  onChange={e => setLinkValue(e.target.value)}
                  autoComplete="username"
                  className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-400 sm:text-sm sm:leading-6 focus-visible:shadow-[0px_0px_0px_4px] focus-visible:shadow-green-100"
                />
              </div>
              {error && (
                <div className="text-red-500 font-semibold mt-2">
                  {t('common:alreadyTaken')}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-50 px-4 py-3 flex gap-2 justify-end">
        <button
          onClick={onClose}
          type="button"
          className="flex items-center justify-center gap-2 px-4 py-2 bg-white hover:bg-gray-50 text-sm font-semibold border rounded">
          {t('common:cancel')}
        </button>
        <PrimaryButton
          isLoading={creatingLink}
          onClick={() => createLink(linkValue)}
          disabled={creatingLink || !linkValue}>
          {t('common:update')}
        </PrimaryButton>
      </div>
    </Modal>
  );
};
