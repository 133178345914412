import { useTranslation } from 'react-i18next';
import { Images } from 'src/common/assets/images';
import { PrimaryButton } from 'src/common/components/Btn/PrimaryButton';
import { useFetchLinksV2 } from 'src/links-qrcodes/hooks/useFetchLinksV2';

export const MainLink = () => {
  const { data, isLoading } = useFetchLinksV2();

  const { t } = useTranslation();

  const link = data?.main?.[0];

  return (
    <div className="flex border border-t-0 rounded-b pb-4">
      <div className="w-0 sm:w-[76px]" />
      <div className="overflow-hidden flex-1 mx-2 sm:mx-4">
        {isLoading && (
          <div className="animate-pulse w-full border-t">
            <div className="px-2 sm:px-4 py-4 border-b bg-white w-full">
              <div className="gap-2 flex ">
                <div className="rounded bg-gray-100 w-6 h-4" />
                <div className="rounded bg-gray-100 w-full h-4" />
                <div className="rounded bg-gray-100 w-6 h-4" />
                <div className="rounded bg-gray-100 w-6 h-4" />
                <div className="rounded bg-gray-100 w-6 h-4" />
                <div className="rounded bg-gray-100 w-6 h-4" />
              </div>
            </div>
          </div>
        )}
        {link && (
          <div className="flex flex-col-reverse sm:flex-row gap-4 sm:gap-6 items-center sm:justify-center">
            <img
              src={Images.mainLinkPreview}
              alt="main link preview"
              className="w-[20rem] sm:w-[20rem]"
            />
            <div className="flex flex-col gap-2 sm:gap-4">
              <p className="text-xl font-semibold">{t('links-qr:checkout')}</p>
              <h4 className="text-3xl font-semibold">
                {t('links-qr:allInOneMerchant')}
              </h4>
              <p className="text-xl font-semibold">
                {t('links-qr:experience')}
              </p>
              <button
                onClick={() =>
                  window.open(link.url, '_blank', 'noopener noreferrer')
                }
                type="button"
                className="self-start">
                <PrimaryButton className="px-8 text-lg font-semibold">
                  {t('links-qr:tryIt')}
                </PrimaryButton>
              </button>
              <span className="text-lg">
                {t('links-qr:interested')}{' '}
                <a
                  href={process.env.REACT_APP_SUPPORT_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-green-500 underline">
                  {t('common:contactUs')}
                </a>
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
