export const en = {
  devices: 'POS Devices',
  posLogin: 'POS Logins',
  posLogins: 'POS Logins',
  device: 'POS Device',
  addDevice: 'Add POS Device',
  selectStores: 'Select Stores',
  connectDevice: 'Connect Device',
  allStores: 'All Stores',
  test: 'Test',
  testAgain: 'Test again',
  finish: 'Finish',
  next: 'Next',
  online: 'Online',
  offline: 'Offline',
  back: 'Back',
  cancel: 'Cancel',
  notificationSent: 'Successfully sent a notification!',
  addAnotherDevice: 'Add another device?',
  downloadAndInstall: '1. Download & install Foodbit POS Mobile App',
  clickHere: 'Click Here',
  shareLink: 'Share Link',
  oneTimeCode: 'One Time Code',
  loginUsingCredentials: '2. Login using the following credentials:',
  username: 'Username',
  password: 'Password',
  receiveTestOrder: '3. Click "Test" to receive a test order',
  receiveOrdersNow:
    ' Now, you can receive orders on your device. Please name your device',
  deviceName: 'Device Name',
  deviceNamePlaceholder: 'Name your device',
  loggedInSuccess: 'Logged in successfully! 🎉 You can Test your device now!',
  fromDevices: 'from the devices?',
  deleteMsg:
    'will be deleted from your devices. This means the device will be permanently removed from the system and you will not be able to login or use it to manage your orders.',
  subHeadingSelectStores:
    'Which stores will the device be receiving orders for?',
  subHeadingConnectDevice: 'Follow the steps to connect the device',
  subHeadingNameDevice: 'Name your device',
  headerSubtitleAddDevice: 'Receive online orders on a new device',
  multipleStoreSelectionWarning:
    'It is not recommended to receive orders for multiple stores on the same device. Please create a new device for each store.',
  loginWait: 'Waiting for you to login!',
  devicesSubtitle: 'Receive online orders on iOS or Android devices',
  devicesMembersOnly:
    'To setup devices and start receiving online orders, please upgrade your membership',
  edit: 'Edit',
  headerSubtitleEditDevice: 'Edit your device, add or remove stores',
  headerSubtitleEditDeviceStores: 'Add or remove stores from your POS',
  connectStore: 'Connect Store',
  testDeviceDesc: 'Test by receiving a notification on your device',
  deviceConnectedDesc: 'Device Connected successfully!',
  deviceOfflineMsg:
    'Your device seems to be offline. Make sure your device is on and connected to the internet.',
  tryConnectAgain: 'Try to connect again!',
  deviceOS: 'Device OS',
  model: 'Model',
  make: 'Make',
  cancelAddDevice:
    'You sure you want to cancel before completing the steps to add your device?',
  emptyStateDevice: 'Add a POS device to receive and manage online orders',
  noDevicesConnected: 'No Devices Connected',
  connectDeviceText: ' To connect a device',
  downloadApp: ' 1. Download the app',
  login: ' 2. Login using',
  credentials: 'these Credentials',
  onlineTip: 'If enabled, orders will be sent to the device',
  devicesUpgradeMembership:
    'Upgrade today and you can start receiving online orders on any iOS or Android device',
  connectedStores: 'Connected Stores',
  connectedDevices: 'Connected Devices',
  linkedDevice: 'Linked Device',
  linkedDevices: 'Linked Devices',
  selectionRequired: 'Selection Required',
  oneStoreRequired: 'Please select atleast one store',
  yourOTP: 'Your one time password is',
  copied: 'Copied!',
  copy: 'Copy',
};

export const ar: typeof en = {
  copied: 'تم النسخ',
  copy: 'انسخ',
  yourOTP: 'رمز الدخول: ',
  selectionRequired: 'الاختيارات مطلوبة',
  oneStoreRequired: 'الرجاء اختيار متجر واحد كحد ادنى',
  linkedDevice: 'المنصة المربوطة',
  linkedDevices: 'المنصات المربوطة',
  devices: 'المنصات',
  posLogins: 'المنصات',
  noDevicesConnected: 'لا يوجد منصات متصلة',
  posLogin: 'معلومات دخول للمنصة',
  emptyStateDevice:
    'معلومات دخول المنصات لتمكينك من استلام وإدراة الطلبات على جهاز الايفون أو الايباد أو الاندرويد',
  device: 'جهاز',
  addDevice: 'اضف منصة',
  oneTimeCode: 'كود مبسط لتسجيل الدخول',
  allStores: 'جميع المتاجر',
  selectStores: 'اختر المتاجر',
  connectDevice: 'اربط المنصة',
  test: 'طلب تجريبي',
  testAgain: 'اعادة ارسال طلب تجريبي',
  finish: 'انهاء',
  next: 'التالي',
  back: 'السابق',
  cancel: 'الغاء',
  online: 'متصل',
  offline: 'غير متصل',
  notificationSent: 'تم ارسال التنيه بنجاح',
  addAnotherDevice: 'اضف منصة اخرى',
  downloadAndInstall: "1. حمل تطبيق 'منصة فودبت'",
  clickHere: 'اضغط هنا',
  shareLink: 'شارك الرابط',
  loginUsingCredentials: '2. ادخل على المنصة باستخدام معلومات الدخول التالية',
  username: 'اليوزرنيم',
  password: 'الباسورد',
  receiveTestOrder: '3. ارسل طلب تجريبي للمنصة',
  receiveOrdersNow: 'الاّن بإمكانك استلام طلبات على المنصة. اختر اسم للمنصة',
  deviceName: 'اسم المنصة',
  loggedInSuccess: 'تم الدخول بنجاح 🎉. بإمكانك ارسال طلب تجريبي الان',
  fromDevices: 'من المنصات؟',
  deleteMsg:
    'سيتم حذف المنصة نهائياً ولن يمكنك استقبال اي طلبات على هذه المنصة بعد الحذف',
  subHeadingSelectStores: 'استقبل طلبات من المتاجر المختارة',
  subHeadingConnectDevice: 'اتبع الخطوات لربط المنصة',
  subHeadingNameDevice: 'اختر اسم للمنصة',
  headerSubtitleAddDevice: 'استقبل طلبات على منصة جديدة',
  multipleStoreSelectionWarning:
    'لاننصح بربط اكثر من متجر على نفس المنصة. ننصح بإضافة منصة جديدة لكل متجر.',
  loginWait: 'ننتظر منك تسجيل الدخول في المنصة',
  devicesSubtitle: 'استقبل طلبات على اجهزة اندرويد أو اي او اس iOS',
  devicesMembersOnly: 'لاستلام طلبات عبر المنصة، الرجاء طلب اشتراك شهري',
  edit: 'تحديث',
  headerSubtitleEditDevice: 'تحديث المنصة',
  headerSubtitleEditDeviceStores: 'قم بإضافة أو حذف الربط بالمتاجر',
  connectStore: 'ربط متجر',
  testDeviceDesc: 'تجربة الربط بإرسال طلب تجريبي للمنصة',
  deviceConnectedDesc: 'تم الربط بنجاح',
  deviceOfflineMsg: 'المنصة غير متصلة في الوقت الحالي',
  tryConnectAgain: 'اتصل مره اخرى',
  deviceNamePlaceholder: 'اسم المنصة',
  deviceOS: 'ايفون أو اندرويد',
  model: 'نوع الجهاز',
  make: 'ماركة الجهاز',
  cancelAddDevice: 'هل تريد الألغاء قبل اكمل عملية اضافة المنصة؟',
  connectDeviceText: ' لربط جهاز',
  downloadApp: ' 1. حمل التطبيق',
  login: ' 2. سجل الدخلو',
  credentials: 'بمعلومات الدخول',
  onlineTip: 'تفعيل أو ايقاف ارسال الطلبات للمنصة',
  devicesUpgradeMembership:
    'قم بترقية الحساب والتمكن من إستلام طلبات على اي جهاز ايفون او اندرويد',
  connectedDevices: 'المنصات المربوطة',
  connectedStores: 'المتاجر المربوطة',
};
