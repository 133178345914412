import { useMutation } from 'react-query';
import Api from 'src/api/Api';
import { queryClient } from 'src/App';
import { ReactQueryKey } from 'src/common/enums';

export const usePatchAccount = () => {
  return useMutation(Api.patchAccount, {
    onSuccess: () => {
      queryClient.invalidateQueries(ReactQueryKey.Account);
    },
  });
};
