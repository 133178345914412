import { Combobox, Dialog, Transition } from '@headlessui/react';
import { LinkIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import {
  ExclamationTriangleIcon,
  LifebuoyIcon,
} from '@heroicons/react/24/outline';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/utils/Links';

const projects = [
  {
    id: 1,
    name: 'Stores',
    url: ROUTES.STORES,
  },
  {
    id: 2,
    name: 'Customers',
    url: ROUTES.CUSTOMERS,
  },
  {
    id: 3,
    name: 'Items',
    url: ROUTES.ITEMS,
  },
  {
    id: 4,
    name: 'Categories',
    url: ROUTES.CATEGORIES,
  },
  {
    id: 5,
    name: 'Orders',
    url: ROUTES.ORDERS,
  },
  {
    id: 6,
    name: 'Options',
    url: ROUTES.OPTIONS,
  },
  {
    id: 7,
    name: 'Menus',
    url: ROUTES.MENUS,
  },
  {
    id: 8,
    name: 'Option Sets',
    url: ROUTES.OPTION_SETS,
  },
];

const users = [
  {
    id: 1,
    name: 'Leslie Alexander',
    url: '#',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  // More users...
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const Search = () => {
  const [open, setOpen] = useState(false);
  const [rawQuery, setRawQuery] = useState('');

  const navigate = useNavigate();

  const handleChange = item => {
    navigate(item.url);
    setOpen(prev => !prev);
  };

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (
        e.key.toLowerCase() === 'k' &&
        (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey)
      ) {
        setOpen(true);
        e.preventDefault();
      }
    };

    document.addEventListener('keydown', listener);

    return () => document.removeEventListener('keydown', listener);
  }, []);

  const query = rawQuery.toLowerCase().replace(/^[#>]/, '');

  const filteredProjects =
    rawQuery === '#'
      ? projects
      : query === '' || rawQuery.startsWith('>')
      ? []
      : projects.filter(project => project.name.toLowerCase().includes(query));

  const filteredUsers =
    rawQuery === '>'
      ? users
      : query === '' || rawQuery.startsWith('#')
      ? []
      : users.filter(user => user.name.toLowerCase().includes(query));

  return (
    <Transition.Root
      show={open}
      as={Fragment}
      afterLeave={() => setRawQuery('')}
      appear>
      <Dialog as="div" className="relative z-[11]" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95">
            <Dialog.Panel className="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <Combobox value={rawQuery} onChange={handleChange}>
                <div className="relative">
                  <MagnifyingGlassIcon
                    className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <Combobox.Input
                    className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
                    placeholder="Search..."
                    onChange={event => setRawQuery(event.target.value)}
                  />
                </div>

                {(filteredProjects.length > 0 || filteredUsers.length > 0) && (
                  <Combobox.Options
                    static
                    className="max-h-80  scroll-py-10 scroll-pb-2  space-y-4 overflow-y-auto p-4 pb-2">
                    {filteredProjects.length > 0 && (
                      <li>
                        <h2 className="text-xs font-semibold text-gray-900">
                          Navigations
                        </h2>
                        <ul className="-mx-4 mt-2 text-sm text-gray-700">
                          {filteredProjects.map(project => (
                            <Combobox.Option
                              key={project.id}
                              value={project}
                              className={({ active }) =>
                                classNames(
                                  'flex cursor-default select-none items-center px-4 py-2',
                                  active && 'bg-indigo-600 text-white',
                                )
                              }>
                              {({ active }) => (
                                <>
                                  <LinkIcon
                                    className={classNames(
                                      'h-6 w-6 flex-none',
                                      active ? 'text-white' : 'text-gray-600',
                                    )}
                                    aria-hidden="true"
                                  />
                                  <span className="ml-3 flex-auto truncate">
                                    {project.name}
                                  </span>
                                </>
                              )}
                            </Combobox.Option>
                          ))}
                        </ul>
                      </li>
                    )}
                    {filteredUsers.length > 0 && (
                      <li>
                        <h2 className="text-xs font-semibold text-gray-900">
                          Users
                        </h2>
                        <ul className="-mx-4 mt-2 text-sm text-gray-700">
                          {filteredUsers.map(user => (
                            <Combobox.Option
                              key={user.id}
                              value={user}
                              className={({ active }) =>
                                classNames(
                                  'flex cursor-default select-none items-center px-4 py-2',
                                  active && 'bg-indigo-600 text-white',
                                )
                              }>
                              <img
                                src={user.imageUrl}
                                alt=""
                                className="h-6 w-6 flex-none rounded-full"
                              />
                              <span className="ml-3 flex-auto truncate">
                                {user.name}
                              </span>
                            </Combobox.Option>
                          ))}
                        </ul>
                      </li>
                    )}
                  </Combobox.Options>
                )}

                {rawQuery === '?' && (
                  <div className="py-14 px-6 text-center text-sm sm:px-14">
                    <LifebuoyIcon
                      className="mx-auto h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                    <p className="mt-4 font-semibold text-gray-900">
                      Help with searching
                    </p>
                    <p className="mt-2 text-gray-500">
                      Use this tool to quickly search for users and projects
                      across our entire platform. You can also use the search
                      modifiers found in the footer below to limit the results
                      to just users or projects.
                    </p>
                  </div>
                )}

                {query !== '' &&
                  rawQuery !== '?' &&
                  filteredProjects.length === 0 &&
                  filteredUsers.length === 0 && (
                    <div className="py-14 px-6 text-center text-sm sm:px-14">
                      <ExclamationTriangleIcon
                        className="mx-auto h-6 w-6 text-gray-400"
                        aria-hidden="true"
                      />
                      <p className="mt-4 font-semibold text-gray-900">
                        No results found
                      </p>
                      <p className="mt-2 text-gray-500">
                        We couldn’t find anything with that term. Please try
                        again.
                      </p>
                    </div>
                  )}

                <div className="flex flex-wrap items-center bg-gray-50 py-2.5 px-4 text-xs text-gray-700">
                  Type{' '}
                  <kbd
                    className={classNames(
                      'mx-1 flex h-5 w-5 items-center justify-center rounded border bg-white font-semibold sm:mx-2',
                      rawQuery.startsWith('#')
                        ? 'border-indigo-600 text-indigo-600'
                        : 'border-gray-400 text-gray-900',
                    )}>
                    #
                  </kbd>{' '}
                  <span className="sm:hidden">for navigation,</span>
                  <span className="hidden sm:inline">
                    to access navigation,
                  </span>
                  <kbd
                    className={classNames(
                      'mx-1 flex h-5 w-5 items-center justify-center rounded border bg-white font-semibold sm:mx-2',
                      rawQuery.startsWith('>')
                        ? 'border-indigo-600 text-indigo-600'
                        : 'border-gray-400 text-gray-900',
                    )}>
                    &gt;
                  </kbd>{' '}
                  for users, and{' '}
                  <kbd
                    className={classNames(
                      'mx-1 flex h-5 w-5 items-center justify-center rounded border bg-white font-semibold sm:mx-2',
                      rawQuery === '?'
                        ? 'border-indigo-600 text-indigo-600'
                        : 'border-gray-400 text-gray-900',
                    )}>
                    ?
                  </kbd>{' '}
                  for help.
                </div>
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
