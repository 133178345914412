export const en = {
  coupons: 'Coupons',
  custLoyalty: 'Customer Loyalty',
  addCoupon: 'Add Coupon',
  couponName: 'Coupon Name',
  discountType: 'Discount Type',
  percentage: 'Percentage',
  flatRate: 'Flat Rate',
  couponNamePlaceholder: 'Ramadan',
  discountAmount: 'Discount Amount',
  discountPercentage: 'Discount Percentage',
  typesInUse: 'Types in Use',
  promoCode: 'Promo code',
  promoCodeIsRequired: 'Promo code is required',
  maxRedemptionAllowed: 'Max Redemption Allowed',
  discount: 'Discount',
  orderTypes: 'Order Types',
  numberOfRedeems: 'Coupons used',
  couponCreated: 'Coupon Created Successfully',
  deleteCouponMessag:
    'Are you sure you want to delete {{couponName}} coupon? This action cannot be undone.',
  couponDeleted: 'Coupon Deleted Successfully',
  couponUpdated: 'Coupon Updated Successfully',
  active: 'Active',
  inActive: 'Inactive',
  enabledMessage:
    'If turned off, customers will not be able to redeem this coupon',
  typesInUseMessage: 'Types of orders on which this coupon can be applied',
  promoCodeMessage:
    'A unique code to be provided to customers to redeem this coupon. (Must be unique and not match the code of another coupon)',
  redemptionMessage: 'Number of times this coupon can be redeemed.',
  numberOfRedeemsMessage: 'Number of times this coupon has been redeemed',
  promoCodeExists:
    'You already have created a coupon with promo code "{{code}}". Please use a different promo code',
  coupon: 'Coupon',
  maxRedeemsRequired: 'Maximum number of redeems is required',
  discountValueRequired: 'Discount value is required',
  addCouponDescription:
    'Offer a fixed or percentage discount for redemption for customers that provide a coupon code.',
  maxPercentage: 'Discount percentage cannot be greater than 100',
};

export const ar: typeof en = {
  maxPercentage: 'لايمكن ان تكون قيمة الخصم اكثر من ١٠٠',
  addCouponDescription:
    'اعرض خصم نسبة أو مبلغ معين للعميل عنده استخدانه هذا الكوبون',
  discountValueRequired: 'قيمة الخصم مطلوب',
  maxRedeemsRequired: 'الحد الأقصى لعدد المرات لاستخدام هذا الكوبون',
  coupon: 'الكوبون',
  promoCodeExists: '"{{code}}"',
  numberOfRedeemsMessage: 'عدد المرات اللتي تم فيها استخدام هذا الكوبون',
  redemptionMessage: 'الحد الأقصى لعدد المرات المسموح بها استخدام هذا الكوبون ',
  promoCodeMessage: 'الكود الخاص اللذي يتم توفيره لاستخدام هذا الكوبون',
  typesInUseMessage: 'نوع الطلبات المسوح بها مع هذا الكوبون',
  enabledMessage: 'اذا تم تفعيله، لايمكن استخدام هذا الكوبون',
  active: 'مفعل',
  inActive: 'غير مفعل',
  couponUpdated: 'تم تحديث الكوبون',
  couponDeleted: 'تم حذف الكوبون',
  deleteCouponMessag: 'هل متأكد من حذف هذا الكوبون: {{couponName}}',
  couponCreated: 'تم انِشأ الكوبون بنجاح',
  discount: 'خصم',
  numberOfRedeems: 'عدد مرات الأستخدام',
  orderTypes: 'نوع الطلبات',
  maxRedemptionAllowed: 'الحد الأقصى لعدد مرات الأستخدام',
  promoCode: 'الكود',
  promoCodeIsRequired: 'الكود مطلوب',
  typesInUse: 'نوع الأستخدام',
  discountAmount: 'قيمة التخفيض',
  discountPercentage: 'نسبة التخفيض',
  couponNamePlaceholder: 'رمضان',
  discountType: 'نوع التخفيض',
  flatRate: 'قيمة',
  percentage: 'نسبة',
  couponName: 'اسم الكوبون',
  coupons: 'الكوبونات',
  custLoyalty: 'ولاء العملاء',
  addCoupon: 'اضف كوبون',
};
