import { useEffect, useState } from 'react';
import { Language } from 'src/translations/uiTypes';
import {
  convertApiLanguagesToUILanguages,
  convertApiLanguageToUILanguage,
} from 'src/translations/utils/languageUtils';
import { useFetchAccount } from 'src/user/hooks/useFetchAccount';

export const useTranslationLanguages = () => {
  const [activeLanguages, setActiveLanguages] = useState<Language[]>([]);
  const [disabledLanguages, setDisabledLanguages] = useState<Language[]>([]);
  const [primaryLanguage, setPrimaryLanguage] = useState<Language>();

  const { merchant } = useFetchAccount();

  useEffect(() => {
    setActiveLanguages(
      convertApiLanguagesToUILanguages(merchant?.languages?.supported ?? []),
    );

    setDisabledLanguages(
      convertApiLanguagesToUILanguages(merchant?.languages?.disabled ?? []),
    );

    setPrimaryLanguage(
      convertApiLanguageToUILanguage(merchant?.languages?.primary),
    );
  }, [
    merchant?.languages?.supported,
    merchant?.languages?.disabled,
    merchant?.languages?.primary,
  ]);

  return {
    activeLanguages,
    setActiveLanguages,
    disabledLanguages,
    setDisabledLanguages,
    primaryLanguage,
    setPrimaryLanguage,
  };
};
