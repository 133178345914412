import React, { MouseEventHandler, useState } from 'react';

import Collapsed from './Collapsed';
import Expanded from './Expanded';

const Accordion = ({ children, initial = '' }) => {
  const [open, setOpen] = useState(initial);

  const handleOpen: MouseEventHandler<HTMLDivElement> = e => {
    const id = e.currentTarget.getAttribute('data-id');
    id === open ? setOpen('') : setOpen(id);
  };

  return (
    <>
      {React.Children.map(children, child => {
        const id = child.props.children[0].props.id;

        const collapsed = React.cloneElement(child.props.children[0], {
          handleOpen,
          open,
        });

        const expanded = child.props.children[1];

        return (
          <>
            {collapsed}
            {id === open && expanded}
          </>
        );
      })}
    </>
  );
};

Accordion.Collapsed = Collapsed;
Accordion.Expanded = Expanded;
Accordion.Item = ({ children }) => children;

export default Accordion;
