/* eslint-disable @typescript-eslint/no-explicit-any */

import axios from 'axios';
import ApiError from 'src/api/ApiError';
import { CheckDetails } from 'src/checks/apiTypes';
import { Include } from 'src/common/enums';
import { Coupon } from 'src/customerLoyalty/coupons/types/API';
import { Discount } from 'src/customerLoyalty/discounts/types/API';
import { Customer, CustomerAPI, Customers } from 'src/customers/apiTypes';
import {
  CreateLink,
  Device,
  DeviceTest,
  Link,
  OperatorCredentials,
  OperatorDetails,
  OperatorStoresUpdateDetails,
} from 'src/devices/apiTypes';
import { Driver } from 'src/drivers/types/API';
import { CustomerFeedbacksData, FeedbackScore } from 'src/feedback/apiTypes';
import { Insights } from 'src/insights/apiTypes';
import { Integration } from 'src/integrations/apiTypes';
import { UploadType } from 'src/menu/apiEnums';
import {
  FoodLabelApi,
  ImageUrl,
  Menu,
  MenuCategory,
  MenuItem,
  MenuItemOption,
  MenuItemOptions,
  PreSignedUrl,
  UpdateMenuItemPayload,
} from 'src/menu/apiTypes';
import { ReportTag } from 'src/menu/menu-item/components/report-labels/types/Report';
import { Contact, Shortcuts } from 'src/online-menu/apiTypes';
import { AssignDriver, Courier, Order, OrdersData } from 'src/orders/apiTypes';
import { AcceptOrderEntry, RejectOrderEntry } from 'src/orders/uiTypes';
import { StoreSettings, UpdateSettings } from 'src/settings/apiTypes';
import { PreSignupDetails, SignupDetails } from 'src/signup/apiTypes';
import { Label, Store, UpdateStoreSettings } from 'src/stores/apiTypes';
import { CreateStoreDetails } from 'src/stores/uiTypes';
import { CheckLocation, TablesChecks } from 'src/tables/apiTypes';
import { LinksV1, LinksV2, Merchant } from 'src/translations/apiTypes';
import {
  Account,
  App,
  Token,
  User,
  UserRegistration,
  Version,
} from 'src/user/apiTypes';
import DateHelper from 'src/utils/DateHelper';
import MXCookies from 'src/utils/MXCookies';
import StringsHelper from 'src/utils/StringsHelper';

interface PresignedImage {
  image: ImageUrl;
  originalUrl: string;
  preSignedUrl: string;
  uploadId: string;
}

const SessionId = StringsHelper.generateGuid();
axios.defaults.baseURL = process.env.REACT_APP_API_URL!;
axios.defaults.headers.common['X-Foodbit-SessionId'] = SessionId;

export type MakePartialExceptOne<T, K extends keyof T> = Partial<T> &
  Pick<T, K>;

export class Api {
  public API_URL = process.env.REACT_APP_API_URL!;
  public API_CLIENT_KEY = process.env.REACT_APP_API_CLIENT_KEY;
  public API_SECRET_KEY = process.env.REACT_APP_API_SECRET_KEY;
  public API_EXT_KEY = process.env.REACT_APP_API_EXT_KEY;

  /**
   * Generate a random id as a sessionId every time you create an instance of this class
   * @type {string}
   */
  public sessionId: string = StringsHelper.generateGuid();

  /**
   * The date when the access_token will expire and will no longer be valid.
   *
   * @type {null}
   */
  private tokenExpirationDate: Opt<Date> = undefined;

  private token: Opt<Token> = {
    access_token: '',
    token_type: '',
    refresh_token: '',
    expires_in: 0,
  };

  /**
   * True when the token is being refreshed.
   * @type {boolean}
   */
  private refreshing = false;

  /**
   * True if there is a token and it has expired and false otherwise.
   *
   * @returns {boolean|*}
   */
  public async isTokenExpired(): Promise<boolean> {
    if (this.tokenExpirationDate == null) {
      this.tokenExpirationDate = await MXCookies.getTokenExpirationDate();
    }
    const isExpired =
      this.token != null &&
      this.tokenExpirationDate != null &&
      DateHelper.isDateExpired(this.tokenExpirationDate);
    return isExpired;
  }

  /**
   * Load access token if it's stored in MXCookies.
   *
   * @returns {Promise<*>}
   */
  public loadAccessToken(): Token {
    if (this.token == null || this.token.access_token === '') {
      this.token = MXCookies.getAccessToken();
    }
    if (!this.token?.access_token) {
      throw Error('token is not present');
    }

    axios.defaults.headers.common.authorization = `Bearer ${this.token.access_token}`;

    return this.token;
  }

  /**
   * Get the access token if it's stored in MXCookies.
   *
   * @returns The access token or null {Promise<*>}
   */
  public async getAccessToken(): Promise<Opt<Token>> {
    let token;
    try {
      token = this.loadAccessToken();
    } catch (e) {
      console.error(e.message);
    }
    return token;
  }

  /**
   * Refreshes the token and stores it once refreshed.
   *
   * @returns {Promise<void>}
   */
  public async refreshToken(): Promise<any> {
    this.refreshing = true;

    this.loadAccessToken();

    try {
      const response = await axios({
        method: 'post',
        url: '/oauth/token',
        params: {
          refresh_token: (this.token && this.token.refresh_token) || null,
          grant_type: 'refresh_token',
        },
      });
      if (response.status === 200) {
        await this.saveAccessToken(response.data);
      }

      return response.data;
    } catch (error) {
      throw new ApiError(error);
    }
  }

  /**
   * Save the new access token.
   *
   * @param token new access token
   */
  public async saveAccessToken(token: Token) {
    this.token = token;
    await MXCookies.setAccessToken(this.token);
    this.tokenExpirationDate = DateHelper.addSecondsToToday(
      this.token.expires_in,
    );
    MXCookies.setTokenExpirationDate(this.tokenExpirationDate);
  }

  /**
   * Login user using username and password. The login will store the access_token into MXCookies.
   *
   * @param username
   * @param password
   * @returns {Promise<void>}
   */
  public async loginUser(
    username: string,
    password: string,
  ): Promise<Opt<Token>> {
    try {
      const headers = {
        Authorization: `Basic ${StringsHelper.encodeToB64(
          `${this.API_CLIENT_KEY}:${this.API_SECRET_KEY}`,
        )}`,
      };

      const response = await axios({
        headers,
        method: 'post',
        url: `/oauth/token?username=${encodeURIComponent(
          username,
        )}&password=${encodeURIComponent(password)}&grant_type=password`,
      });
      // Store the token
      if (response) {
        this.saveAccessToken(response.data);
        return response.data;
      }
      return undefined;
    } catch (error) {
      throw new ApiError(error);
    }
  }

  /**
   * Register a new user
   *
   * @param registration: User object
   * @returns {Promise<void>}
   */
  public async registerUser(registration: UserRegistration): Promise<User> {
    try {
      const headers = {
        Authorization: `Basic ${StringsHelper.encodeToB64(
          `${this.API_CLIENT_KEY}:${this.API_SECRET_KEY}`,
        )}`,
      };

      const response = await axios({
        headers,
        method: 'post',
        url: '/users',
        data: registration,
      });
      return response.data;
    } catch (error) {
      throw new ApiError(error);
    }
  }

  /**
   * Fetch All Stores
   *
   * @param key: query string
   * @param merchantId : string
   * @returns {Promise<Store[]>}
   */
  fetchAllStores = async (merchantId: string): Promise<Store[]> => {
    this.loadAccessToken();
    const apiCall = axios({
      method: 'get',
      url: `/merchants/${merchantId}/stores`,
    });

    return this.executeApiCall(0, 'fetchAllStores', true, apiCall);
  };

  /**
   * Fetch Store by Store ID
   *  The function fetches store details of the specified store id
   * @param (storeId: string) `id` of the store you want to fetch
   * @returns a promise with Store object {Promise<Store>}
   */

  fetchStoreById = async (storeId: string): Promise<Store> => {
    this.loadAccessToken();
    const apiCall = axios({
      method: 'get',
      url: `/v1/stores/${storeId}`,
      params: {
        include: 'CHECK_LOCATIONS_COUNT',
      },
    });
    return this.executeApiCall(0, 'fetchStoreById', true, apiCall);
  };

  /**
   * Saves new Menu in the system.
   *
   * @param data The complete menu data

   * @returns {Promise<Menu>}
   */
  createMenu = async (data: Partial<Menu>): Promise<Menu> => {
    this.loadAccessToken();
    const apiCall = axios({
      method: 'post',
      url: '/menus',
      data,
    });

    return this.executeApiCall(0, 'createMenu', true, apiCall);
  };

  httpPostIntegration = async ({ url }: { url: string }) => {
    this.loadAccessToken();
    const apiCall = axios({
      method: 'post',
      url,
    });

    return this.executeApiCall(0, 'httpPostIntegration', true, apiCall);
  };

  updateAppShortcuts = async (data: any) => {
    this.loadAccessToken();
    const apiCall = axios({
      method: 'PATCH',
      url: `/shortcuts/${data.id}`,
      data,
    });

    return this.executeApiCall(0, 'update-shortcuts', true, apiCall);
  };

  preInstallIntegration = async ({
    merchantId,
    integrationId,
  }: {
    merchantId: string;
    integrationId: string;
  }): Promise<Integration> => {
    this.loadAccessToken();
    const apiCall = axios({
      method: 'get',
      url: `/merchants/${merchantId}/integrations/${integrationId}/pre-install`,
    });

    return this.executeApiCall(0, 'preInstallIntegration', true, apiCall);
  };

  installIntegration = async ({
    merchantId,
    integrationId,
    data,
  }: {
    merchantId: string;
    integrationId: string;
    data?: any;
  }) => {
    this.loadAccessToken();
    const apiCall = axios({
      method: 'post',
      url: `/merchants/${merchantId}/integrations/${integrationId}/install`,
      data,
    });

    return this.executeApiCall(0, 'installIntegration', true, apiCall);
  };

  patchIntegration = async (
    integration: MakePartialExceptOne<Integration, 'id'>,
  ): Promise<Integration> => {
    this.loadAccessToken();
    const apiCall = axios({
      method: 'patch',
      url: `/integrations/${integration.id}`,
      data: integration,
    });

    return this.executeApiCall(0, 'patchIntegration', true, apiCall);
  };

  outOfStockStores = async (data: {
    ids: string[];
    storeIds: string[];
  }): Promise<Menu[]> => {
    this.loadAccessToken();
    const apiCall = axios({
      method: 'post',
      url: '/menus/items/out-of-stock-stores',
      data,
    });

    return this.executeApiCall(0, 'sortMenus', true, apiCall);
  };

  resetAccount = async (merchantId: string) => {
    this.loadAccessToken();
    const apiCall = axios({
      method: 'DELETE',
      url: `merchants/${merchantId}/reset`,
    });

    return this.executeApiCall(0, 'resetAccount', true, apiCall);
  };

  sortMenus = async (data: Menu[]): Promise<Menu[]> => {
    this.loadAccessToken();
    const apiCall = axios({
      method: 'post',
      url: '/menus/sort',
      data,
    });

    return this.executeApiCall(0, 'sortMenus', true, apiCall);
  };

  updateSingleMenu = async (data: Partial<Menu>): Promise<Menu> => {
    this.loadAccessToken();
    const apiCall = axios({
      method: 'patch',
      url: `/menus/${data.id}`,
      data,
    });

    return this.executeApiCall(0, 'updateSingleMenu', true, apiCall);
  };

  /**
   * Saves new Menu in the system.
   * @param data tempPassword returned from the email and the newPassword enter
   * by the user
   */

  resetPassword = async (data: {
    tempId: string | null;
    newPassword: string;
  }) => {
    const apiCall = axios({
      method: 'post',
      url: '/users/password/reset',
      data,
    });

    return this.executeApiCall(0, 'resetPassword', true, apiCall);
  };

  /**
   * Save New Store in the system.
   *
   * @param data The complete store data

   * @returns {Promise<Store>}
   */
  createStore = async (data: CreateStoreDetails): Promise<Store> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: '/stores',
      data,
    });

    return this.executeApiCall(0, 'createStore', true, apiCall);
  };

  /**
   * Update Existing Store in the system.
   *
   * @param data The complete store data

   * @returns Updated Store Object {Promise<void>}
   */
  updateStore = async (data: Store): Promise<Store> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'patch',
      url: `/v1/stores/${data.id}`,
      data,
    });

    return this.executeApiCall(0, 'updateStore', true, apiCall);
  };

  /**
   * Save Store settings in the system.
   *
   * @param storeId The store id
   * @param data The StoreSettings
   * @returns {Promise<StoreSettings>}
   */
  createStoreSettings = async ({
    storeId,
    settings: data,
  }: UpdateStoreSettings): Promise<StoreSettings> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: `/v1/stores/${storeId}/settings`,
      data,
    });

    return this.executeApiCall(0, 'createStoreSettings', true, apiCall);
  };

  /**
   * Update Existing Store Settings in the system.
   *
   * @param StoreSettings object with updated data
   * @returns Updated Store Object {Promise<StoreSettings>}
   */
  updateStoreSettings = async ({
    storeId,
    settings: data,
  }: UpdateStoreSettings): Promise<StoreSettings> => {
    this.loadAccessToken();
    const apiCall = axios({
      method: 'patch',
      url: `/v1/stores/${storeId}`,
      data,
    });

    return this.executeApiCall(0, 'updateStoreSettings', true, apiCall);
  };

  /**
   * Update the isReceivingOrders value for the store
   *
   * @param id The Store identifier
   * @param isReceivingOrders True if receiving is true and false if not
   * @returns {Promise<Array>}
   */
  updateIsReceivingOrders = async ({
    id,
    isReceivingOrders,
  }: {
    id: string;
    isReceivingOrders: boolean;
  }): Promise<boolean> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      params: {
        isReceivingOrders,
      },
      url: `/v1/stores/${id}/receive-orders`,
    });

    return this.executeApiCall(0, 'updateIsReceivingOrders', true, apiCall);
  };

  /**
   * Update array of existing stores settings in the system.
   *
   * @param storeSettingsArr Array object with updated data

   * @returns Updated Store Object {Promise<StoreSettings[]>}
   */
  updateStoreSettingsArray = async (
    storeSettingsArr: UpdateStoreSettings[],
  ): Promise<StoreSettings[]> => {
    return Promise.all(
      storeSettingsArr.map(storeSettings =>
        this.updateStoreSettings(storeSettings),
      ),
    );
  };

  /**
   * Delete Store
   * This function takes a store ID and delete the Store from the DB.
   *
   * @param id The Store Location identifier
   * @returns {Promise<Array>}
   */
  deleteStore = async (id: string): Promise<void> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'delete',
      url: `/stores/${id}`,
    });

    return this.executeApiCall(0, 'deleteLocation', true, apiCall);
  };

  /**
   * Fetch All Operators
   * This function fetches all the Devices for the given user
   *
   * @param key: The react query key
   * @param merchantId : string
   * @returns {Promise<Device[]>}
   */

  fetchAllOperatorsDevices = async (
    merchantId: string,
  ): Promise<OperatorDetails[]> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/merchants/${merchantId}/operators`,
    });

    return this.executeApiCall(0, 'fetchAllOperatorsDevices', true, apiCall);
  };

  /**
   * Test a Device by sending an order.
   *
   * @param data object with device id, user id, and stores ids
   * @returns {Promise<void>}
   */
  testDevice = async (data: DeviceTest): Promise<void> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: '/orders/test',
      params: {
        deviceId: data.deviceId,
        operatorId: data.operatorId,
        storeIds: data.storeIds.join(','),
      },
    });

    return this.executeApiCall(0, 'testDevice', true, apiCall);
  };

  /**
   * Generate OperatorDetails credentials for new Device
   *
   * @returns {Promise<DeviceCredentials>}
   */
  generateOperatorCredentials = async (): Promise<OperatorCredentials> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: '/operators/',
    });

    return this.executeApiCall(0, 'generateOperatorCredentials', true, apiCall);
  };

  generateOneTimeCode = async (
    userId: string,
  ): Promise<{
    code: string;
  }> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: '/operators/loginCode',
      params: {
        userId,
      },
    });

    return this.executeApiCall(0, 'generateOneTimeCode', true, apiCall);
  };

  fetchScores = (
    key: string,
    storeIds: string[],
    startDate: number,
    endDate: number,
    scores?: string,
    merchantId?: string,
  ): Promise<FeedbackScore> => {
    this.loadAccessToken();

    const url = '/feedback/score?'.concat(
      `${
        ![undefined, null].includes(merchantId)
          ? `merchantId=${merchantId}`
          : `storeIds=${storeIds.join(',')}`
      }&include=${Include.Store},${
        Include.CheckNumber
      }&startDate=${startDate}${`${
        scores ? `&filterByScores=${scores}` : ''
      }`}${endDate ? `&endDate=${endDate}` : ''}`,
    );

    const apiCall = axios({
      method: 'get',
      url,
    });

    return this.executeApiCall(0, 'fetchScores', true, apiCall);
  };

  fetchFeedbacks = (
    key: string,
    storeIds: string[],
    startDate: number,
    endDate: number,
    scores?: string,
    merchantId?: string,
    startKey?: string | null,
  ): Promise<CustomerFeedbacksData> => {
    this.loadAccessToken();

    const encodedStartKey =
      startKey !== undefined
        ? encodeURIComponent(JSON.stringify(startKey).slice(1, -1))
        : '';

    const url = '/feedback?'.concat(
      `${
        ![undefined, null].includes(merchantId)
          ? `merchantId=${merchantId}`
          : `storeIds=${storeIds.join(',')}`
      }&include=${Include.Store},${Include.Customer},${Include.CheckNumber},${
        Include.OrderNumber
      }&startDate=${startDate}${`${
        scores ? `&filterByScores=${scores}` : ''
      }`}${
        endDate ? `&endDate=${endDate}` : ''
      }&limit=50&startKey=${encodedStartKey}`,
    );

    const apiCall = axios({
      method: 'get',
      url,
    });

    return this.executeApiCall(0, 'fetchFeedbacks', true, apiCall);
  };

  fetchCheckDetails = (
    key: string,
    checkId: string,
    include: Include[],
  ): Promise<CheckDetails> => {
    this.loadAccessToken();

    const url = `/checks/${checkId}?include=${include.join(',')}`;

    const apiCall = axios({
      method: 'get',
      url,
    });

    return this.executeApiCall(0, 'fetchCheckDetails', true, apiCall);
  };

  /**
   * Assign stores to operator
   *
   * @param storeIds[] object with storeIds
   * @returns {Promise<OperatorCredentials>}
   */

  assignStoresToOperator = async ({
    id,
    storeIds: operatorStoreIds,
  }: OperatorStoresUpdateDetails): Promise<OperatorCredentials> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: `/operators/${id}/stores`,
      params: {
        storeIds: operatorStoreIds.join(','),
      },
    });

    return this.executeApiCall(0, 'saveOperatorStores', true, apiCall);
  };

  /**
   * Remove or un-assign stores to operator
   *
   * @param storeIds[] object with storeIds
   * @returns {Promise<OperatorCredentials>}
   */

  unAssignStoresToOperator = async ({
    id,
    storeIds: operatorStoreIds,
  }: OperatorStoresUpdateDetails): Promise<OperatorCredentials> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'delete',
      url: `/operators/${id}/stores`,
      params: {
        storeIds: operatorStoreIds.join(','),
      },
    });

    return this.executeApiCall(0, 'unAssignStoresToOperator', true, apiCall);
  };

  /**
   * Fetch All Operators
   * This function fetches all the Devices for the given user
   *
   * @param id`: string
   * @param key: The react query key
   * @returns {Promise<OperatorDetails[]>}
   */

  fetchOperatorsDetailsById = async (
    key: string,
    id: string,
  ): Promise<OperatorDetails> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/operators/${id}/details`,
    });

    return this.executeApiCall(0, key, true, apiCall);
  };

  /**
   * Create Link
   *
   * @returns {Promise<Link[]>}
   */
  createLink = async (data: CreateLink): Promise<Link> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      data,
      url: `/links`,
    });

    return this.executeApiCall(0, 'createLink', true, apiCall);
  };

  makeLink = data => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      data,
      url: `/v1/links`,
    });

    return this.executeApiCall(0, 'makeLink', true, apiCall);
  };

  /**
   * Fetch all links
   *
   * @param key: The react query key
   * @param merchantId : string
   * @returns {Promise<Link[]>}
   */
  fetchLinks = async (key: string, merchantId: string): Promise<Link[]> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/merchants/${merchantId}/links`,
    });

    return this.executeApiCall(0, key, true, apiCall);
  };

  fetchLinksByStore = async (storeId: string): Promise<LinksV1[]> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/v1/stores/${storeId}/links`,
    });

    return this.executeApiCall(0, 'fetchLinksByStore', true, apiCall);
  };

  fetchLinksByStoreV2 = async (storeId: string): Promise<LinksV2[]> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/v2/stores/${storeId}/links`,
      params: {
        types: 'DINE_IN,ONLINE_ORDERING',
      },
    });

    return this.executeApiCall(0, 'fetchLinksByStore', true, apiCall);
  };

  /**
   * Fetch specific Operator list of Stores
   *
   * @param key: The react query key
   * @param operatorId: string object
   * @returns {Promise<Store[]>}
   */
  fetchOperatorStores = async (
    key: string,
    operatorId: string,
  ): Promise<Store[]> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/operators/${operatorId}/stores`,
    });

    return this.executeApiCall(0, key, true, apiCall);
  };

  /**
   * Fetch specific OperatorDetails list of devices
   *
   * @param key: The react query key
   * @param operatorId: string object
   * @returns {Promise<DeviceDetailsFormValues>}
   */
  fetchOperatorDevices = async (
    key: string,
    operatorId: string,
  ): Promise<Device[]> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/operators/${operatorId}/devices`,
    });

    return this.executeApiCall(0, key, true, apiCall);
  };

  /**
   * Update OperatorDetails Information with device name
   *
   * @param data The Operator data object

   * @returns {Promise<Device>}
   */
  updateOperator = async (data: User): Promise<OperatorDetails> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'put',
      url: '/operators',
      data,
    });

    return this.executeApiCall(0, 'updateOperator', true, apiCall);
  };

  updateDevices = async (data: OperatorDetails): Promise<OperatorDetails> => {
    this.loadAccessToken();
    const apiCall = axios({
      method: 'patch',
      url: `/operators/${data.user.id}`,
      data,
    });

    return this.executeApiCall(0, 'updateOperator', true, apiCall);
  };

  /**
   * Delete OperatorDetails
   *
   * @param operatorId : string

   * @returns {Promise<Status>}
   */
  deleteOperator = async (operatorId: string): Promise<void> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'delete',
      url: `/operators/${operatorId}`,
    });

    return this.executeApiCall(0, 'deleteOperator', true, apiCall);
  };

  /**
   * Fetch Pre Signup details
   * This function fetches all the PreSignup Details for the given token
   * @param {id} string
   * @returns {Promise<PreSignupDetails>}
   */

  fetchPreSignupDetails = async (token: string): Promise<PreSignupDetails> => {
    const headers = {
      Authorization: `Basic ${StringsHelper.encodeToB64(
        `${this.API_CLIENT_KEY}:${this.API_SECRET_KEY}`,
      )}`,
    };
    const apiCall = axios({
      headers,
      method: 'get',
      url: `/pre-signups/${token}`,
    });

    return this.executeApiCall(0, 'fetchPreSignupDetails', true, apiCall);
  };

  /**
   * Save New User in the system.
   *
   * @param data Details
   *
   * @returns {Promise<User>}
   */
  saveUser = async (data: SignupDetails): Promise<User> => {
    const headers = {
      Authorization: `Basic ${StringsHelper.encodeToB64(
        `${this.API_CLIENT_KEY}:${this.API_SECRET_KEY}`,
      )}`,
    };
    const apiCall = axios({
      headers,
      method: 'post',
      url: '/accounts',
      data,
    });

    return this.executeApiCall(0, 'saveUser', true, apiCall);
  };

  /**
   * Fetch all menu item options
   *
   * @param menuId: The menu identifier
   * @returns {Promise<void>}
   */
  fetchMenuItemOptions = async (
    merchantId: string,
  ): Promise<MenuItemOptions[]> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/merchants/${merchantId}/menu-options`,
    });
    return this.executeApiCall(0, 'fetchMenuItemOptions', true, apiCall);
  };

  fetchAllOptions = async (merchantId: string): Promise<MenuItemOption[]> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/merchants/${merchantId}/options`,
      params: {
        include: 'OPTION_SETS_COUNT',
      },
    });
    return this.executeApiCall(0, 'fetchAllOptions', true, apiCall);
  };

  fetchSingleOptionItem = async (optionId: string): Promise<MenuItemOption> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/v2/menus/options/${optionId}`,
      params: {
        include: 'OPTION_SETS,STORES',
      },
    });
    return this.executeApiCall(0, 'fetchAllOptions', true, apiCall);
  };

  /**
   * Fetch the menu item by menu item id and category id
   *
   * @param categoryId: The category identifier
   * @param menuItemId: Menu Item identifier
   * @returns {Promise<void>}
   */
  fetchMenuItem = async (menuItemId: string): Promise<MenuItem> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/menus/item/${menuItemId}`,
      params: {
        include: 'VARIATIONS,STORES,CATEGORIES,OPTION_SETS,OPTIONS',
      },
    });
    return this.executeApiCall(0, 'fetchMenuItem', true, apiCall);
  };

  fetchAllIntegrations = async (
    key: string,
    lng: string,
    country: string,
    merchantId: string,
  ): Promise<Integration[]> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/integrations`,
      params: {
        lng,
        country,
        merchantId,
      },
    });
    return this.executeApiCall(0, 'fetchAllIntegrations', true, apiCall);
  };

  fetchIntegrationDetails = async (
    integrationId: string,
    merchantId: string,
  ): Promise<Integration> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/integrations/${integrationId}`,
      params: {
        merchantId,
      },
    });
    return this.executeApiCall(0, 'fetchIntegrationDetails', true, apiCall);
  };

  fetchIntegrationType = async (
    merchantId: string,
    type: string,
  ): Promise<Integration[]> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/merchants/${merchantId}/integrations`,
      params: {
        type,
      },
    });
    return this.executeApiCall(0, 'fetchIntegrationType', true, apiCall);
  };

  /**
   * Fetch the categories by menu id
   *
   * @param id: Menu identifier
   * @returns {Promise<void>}
   */
  fetchCategories = async (id: Opt<string>): Promise<MenuCategory[]> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/merchants/${id}/menu-categories`,
      params: {
        include: 'ITEMS_COUNT,MENUS_COUNT',
      },
    });

    return this.executeApiCall(0, 'fetchCategoriesMenuById', true, apiCall);
  };

  /**
   * Fetch the categories by menu id
   *
   * @param id: Menu identifier
   * @returns {Promise<void>}
   */
  fetchSettings = (settingsId: string) => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/settings/${settingsId}`,
    });

    return this.executeApiCall(0, 'fetchSettings', true, apiCall);
  };

  updateSettings = async ({
    storeId,
    data,
  }: UpdateSettings): Promise<StoreSettings> => {
    this.loadAccessToken();
    const apiCall = axios({
      method: 'patch',
      url: `/v1/stores/${storeId}/settings`,
      params: {
        updateDineInLink: data.updateDineInSettings,
      },
      data,
    });

    return this.executeApiCall(0, 'updateSettings', true, apiCall);
  };

  updateMerchantPatch = async ({
    merchantId,
    data,
  }: {
    merchantId: string;
    data: Partial<Merchant>;
  }) => {
    this.loadAccessToken();
    const apiCall = axios({
      method: 'patch',
      url: `/merchants/${merchantId}`,
      data,
    });

    return this.executeApiCall(0, 'updateMerchant', true, apiCall);
  };

  updateMerchantName = async ({
    merchantId,
    name,
  }: {
    merchantId: string;
    name: string;
  }) => {
    this.loadAccessToken();
    const apiCall = axios({
      method: 'post',
      url: `/accounts/${merchantId}/name`,
      data: { name },
    });

    return this.executeApiCall(0, 'updateMerchant', true, apiCall);
  };

  /**
   * Fetch All the menus
   *
   * @param key: The request query key
   * @param merchantId : string
   * @returns {Promise<void>}
   */
  fetchAllMenus = async (key: string, merchantId: string): Promise<Menu[]> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/v1/merchants/${merchantId}/menus`,
      params: {
        include: 'CATEGORIES,CATEGORIES_COUNT,STORES_COUNT',
      },
    });

    return this.executeApiCall(0, key, true, apiCall);
  };

  fetchAppContacts = async (
    key: string,
    merchantId: string,
  ): Promise<Contact[]> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/v1/merchants/${merchantId}/contacts`,
    });

    return this.executeApiCall(0, key, true, apiCall);
  };

  createSocial = async (data): Promise<Contact[]> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: `/contacts`,
      data,
    });

    return this.executeApiCall(0, 'contacts', true, apiCall);
  };

  updateApp = async ({ data, appId }): Promise<Contact[]> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'PATCH',
      url: `/apps/${appId}`,
      data,
    });
    return this.executeApiCall(0, 'updateApp', true, apiCall);
  };

  fetchAppShortcuts = async (
    key: string,
    merchantId: string,
  ): Promise<Shortcuts[]> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `merchants/${merchantId}/shortcuts`,
    });

    return this.executeApiCall(0, key, true, apiCall);
  };

  /**
   * Fetch All the menus
   *
   * @param key: The request query key
   * @param merchantId : string
   * @returns {Promise<void>}
   */
  fetchAllMenuItems = async (
    key: string,
    merchantId: string,
  ): Promise<MenuItem[]> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/v1/merchants/${merchantId}/menu-items`,
      params: {
        include: 'CATEGORIES,OPTION_SETS',
      },
    });

    return this.executeApiCall(0, key, true, apiCall);
  };

  fetchMenuDetails = async (menuId: string): Promise<Menu> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/menus/${menuId}`,
      params: {
        include: 'HIDDEN_ITEMS,STORES',
      },
    });
    return this.executeApiCall(0, 'fetchMenuDetails', true, apiCall);
  };

  fetchMenuDetailsV2 = async (menuId: string): Promise<Menu> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/v2/menus/${menuId}`,
      params: {
        include: 'STORES,CATEGORIES',
      },
    });
    return this.executeApiCall(0, 'fetchMenuDetails', true, apiCall);
  };

  /**
   * Fetch the menu by its id
   *
   * @param id: Menu identifier
   * @returns {Promise<void>}
   */
  public async fetchMenu(id: string): Promise<Menu> {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/menus/${id}`,
    });

    return this.executeApiCall(0, 'fetchMenu', true, apiCall);
  }

  public fetchCouriers = async (merchantId: string) => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/merchants/${merchantId}/couriers`,
    });

    return this.executeApiCall(0, 'fetchCouriers', true, apiCall);
  };

  public fetchAllergies = async (): Promise<FoodLabelApi> => {
    const apiCall = axios({
      method: 'get',
      url: `/menus/allergies`,
    });

    return this.executeApiCall(0, 'fetchAllergies', true, apiCall);
  };

  public fetchCountries = async () => {
    const apiCall = axios({
      method: 'get',
      url: `/statics/countries`,
    });

    return this.executeApiCall(0, 'fetchCountries', true, apiCall);
  };

  public fetchTimezones = async () => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/statics/timezones`,
    });

    return this.executeApiCall(0, 'fetchTimezones', true, apiCall);
  };

  public deleteBatchOptions = async (data: any) => {
    const apiCall = axios({
      method: 'delete',
      url: `/v2/menus/options`,
      data,
    });
    return this.executeApiCall(0, 'deleteBatchOptions', true, apiCall);
  };

  checkAvailableLink = async (
    link: string,
  ): Promise<{
    isAvailable: boolean;
  }> => {
    const apiCall = axios({
      method: 'get',
      url: `links/${link}/available`,
    });
    return this.executeApiCall(0, 'availableLink', true, apiCall);
  };

  deleteLink = async (linkId: string) => {
    const apiCall = axios({
      method: 'delete',
      url: `/links/${linkId}`,
    });
    return this.executeApiCall(0, 'deleteLink', true, apiCall);
  };

  public refundOrder = async refundDetails => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: `/payments/${refundDetails.id}/refund`,
      params: {
        amount: refundDetails.amount,
      },
    });

    return this.executeApiCall(0, 'refundOrder', true, apiCall);
  };

  /**
   * Send the user a short code
   *
   * @param user: User object
   * @returns {Promise<void>}
   */
  public async sendShortCode(user: User): Promise<any> {
    try {
      const response = await axios({
        method: 'post',
        url: '/users/sendCode',
        data: user,
      });
      return response.data;
    } catch (error) {
      throw new ApiError(error);
    }
  }

  /**
   * Save a new upload
   *
   * @param upload: Upload object
   * @returns {Promise<void>}
   */
  public async saveUpload(url: string): Promise<any> {
    const apiCall = axios({
      method: 'post',
      url: `/uploads?url=${url}`,
    });
    return this.executeApiCall(0, 'saveUpload', true, apiCall);
  }

  /**
   * Upload an image by requesting a PUT url first to directly upload to it.
   *
   * @param file The image file uri path.
   * @param imageName The image file name.
   * @param type The upload type (i.e. USER_PHOTO, PLACE_PHOTO).
   * @returns {Promise<Upload>}
   */
  public async uploadImage(
    file: File,
    imageName: string,
    type: UploadType,
  ): Promise<PreSignedUrl> {
    const preApiCall = axios({
      method: 'get',
      url: '/uploads/presigned',
      params: {
        name: imageName,
        type,
        v2: 'true',
      },
    });
    const preResponse = await this.executeApiCall(
      0,
      'preSignedUrl',
      true,
      preApiCall,
    );

    return new Promise((resolve: any, reject: any) => {
      const { preSignedUrl, image, originalUrl } = preResponse;
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', preSignedUrl);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            // resolve(preResponse.second);
            resolve({
              image,
              originalUrl,
            });
          } else {
            reject(new Error('Error while sending the image to S3'));
          }
        }
      };
      xhr.setRequestHeader('Content-Type', 'image/jpeg');
      xhr.setRequestHeader('X-Safary-SessionId', this.sessionId);
      xhr.setRequestHeader('x-amz-acl', 'public-read');

      xhr.send(file);
    });
  }

  /**
   * Delete MenuX Category.
   *
   * @param id The Menu Category identifier
   * @returns {Promise<Array>}
   */
  deleteCategory = async (id: string): Promise<any> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'delete',
      url: `/menus/categories/${id}`,
    });

    return this.executeApiCall(0, 'deleteCategory', true, apiCall);
  };

  /**
   * Delete MenuX Item.
   *
   * @param id The Menu Item identifier
   * @returns {Promise<Array>}
   */
  deleteMenuItem = async (id: string): Promise<any> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'delete',
      url: `/menus/menuItem/${id}`,
    });

    return this.executeApiCall(0, 'deleteMenuItem', true, apiCall);
  };

  deleteCheck = async ({
    locationId,
    storeId,
  }: {
    locationId: string;
    storeId: string;
  }): Promise<any> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'delete',
      url: `/checks/locations/${locationId}`,
      params: {
        storeId,
      },
    });

    return this.executeApiCall(0, 'deleteCheck', true, apiCall);
  };

  deleteMenu = async (id: string): Promise<any> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'delete',
      url: `/menus/${id}`,
    });

    return this.executeApiCall(0, 'deleteMenu', true, apiCall);
  };

  /**
   * Updates a menu.
   *
   * @param data The menu to update
   * @returns {Promise<Array>}
   */
  updateMenu = async (data: Menu): Promise<Menu> => {
    this.loadAccessToken();
    const apiCall = axios({
      method: 'put',
      url: '/menus',
      data,
    });

    return this.executeApiCall(0, 'updateMenuItem', true, apiCall);
  };

  /**
   * Update array of existing menus in the system.
   *
   * @param menusArray Array object with updated data

   * @returns Updated Store Object {Promise<StoreSettings[]>}
   */
  updateMenusArray = async (menusArr: Menu[]): Promise<Menu[]> => {
    return Promise.all(menusArr.map(menu => this.updateMenu(menu)));
  };

  /**
   * Updates MenuX Item.
   *
   * @param item The Menu Item
   * @returns {Promise<Array>}
   */
  updateMenuItem = async (item: MenuItem): Promise<MenuItem> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'patch',
      url: `/menus/item/${item?.id}`,
      data: item,
    });

    return this.executeApiCall(0, 'updateMenuItem', true, apiCall);
  };

  updateMenuItemVariations = async ({
    id,
    variations,
  }: {
    id: string;
    variations: any;
  }) => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: `/variations`,
      data: variations,
    });

    return this.executeApiCall(0, 'updateMenuItemVariations', true, apiCall);
  };

  deleteVariation = async (id: string) => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'delete',
      url: `/variations/${id}`,
    });

    return this.executeApiCall(0, 'deleteVariation', true, apiCall);
  };

  exportOrders = async (item: string[]): Promise<Order[]> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: `/v1/orders/export`,
      data: item,
    });

    return this.executeApiCall(0, 'exportOrders', true, apiCall);
  };

  createReportTag = data => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: `/reports/tags`,
      data,
    });

    return this.executeApiCall(0, 'createReportTag', true, apiCall);
  };

  createTable = (table: Partial<CheckLocation>) => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: `/checks/locations`,
      data: table,
    });

    return this.executeApiCall(0, 'createTable', true, apiCall);
  };

  checkUserValues = values => {
    const apiCall = axios({
      method: 'post',
      url: `/accounts/taken`,
      data: values,
    });

    return this.executeApiCall(0, 'checkUserValues', true, apiCall);
  };

  updateTable = ({
    checkId,
    table,
  }: {
    table: Partial<CheckLocation>;
    checkId: string;
  }) => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'patch',
      url: `/checks/locations/${checkId}`,
      data: table,
    });

    return this.executeApiCall(0, 'updateTable', true, apiCall);
  };

  fetchReportLabels = (merchantId: string): Promise<ReportTag[]> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/merchants/${merchantId}/report-tags`,
    });

    return this.executeApiCall(0, 'fetchReportLabels', true, apiCall);
  };

  fetchTablesByStoreId = (storeId: string): Promise<TablesChecks> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/checks/locations`,
      params: { storeId },
    });

    return this.executeApiCall(0, 'fetchTableByStoreId', true, apiCall);
  };

  exportCustomers = async ({
    id,
    item,
  }: {
    id: string;
    item: string[];
  }): Promise<Customer[]> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: `/v1/merchants/${id}/customers/export`,
      data: item,
    });

    return this.executeApiCall(0, 'exportCustomers', true, apiCall);
  };

  /**
   * Deletes MenuX Item Options.
   *
   * @param options The Menu Item Options
   * @returns {Promise<Array>}
   */
  public async deleteMenuItemOptions(
    options: MenuItemOptions[],
  ): Promise<MenuItem> {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'delete',
      url: '/menus/menuItem/options',
      data: options,
    });

    return this.executeApiCall(0, 'deleteMenuItemOptions', true, apiCall);
  }

  /**
   * Updates MenuX Item Options.
   *
   * @param options The Menu Item Options
   * @returns {Promise<Array>}
   */
  public async updateMenuItemOptions(
    options: MenuItemOptions[],
  ): Promise<MenuItem> {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'put',
      url: '/menus/menuItem/options',
      data: options,
    });

    return this.executeApiCall(0, 'updateMenuItemOptions', true, apiCall);
  }

  public updateSingleMenuItemOption = async ({
    optionId,
    menuItemOption,
  }: UpdateMenuItemPayload): Promise<MenuItemOptions> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'patch',
      url: `v2/menus/option-set/${optionId}`,
      data: menuItemOption,
    });
    return this.executeApiCall(0, 'updateSingleMenuItemOption', true, apiCall);
  };

  public deleteMenuItemOption = async (
    optionId: string,
  ): Promise<MenuItemOptions> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'delete',
      url: `/menus/options/${optionId}`,
    });
    return this.executeApiCall(0, 'updateSingleMenuItemOption', true, apiCall);
  };

  public addMenuItemOptions = async (
    menuItemOption: MenuItemOptions[],
  ): Promise<MenuItemOptions[]> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: '/v2/menus/option-set',
      data: menuItemOption,
    });
    return this.executeApiCall(0, 'addSingleMenuItemOption', true, apiCall);
  };

  public addMenuItemOption = async (
    menuItemOption: MenuItemOption[],
  ): Promise<MenuItemOption[]> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: '/v2/menus/options',
      data: menuItemOption,
    });
    return this.executeApiCall(0, 'addMenuItemOption', true, apiCall);
  };

  public editMenuItemOption = async ({
    optionId,
    data,
  }: {
    optionId: string;
    data: MenuItemOption;
  }): Promise<MenuItemOption> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'patch',
      url: `/v2/menus/options/${optionId}`,
      data,
    });
    return this.executeApiCall(0, 'editMenuItemOption', true, apiCall);
  };

  public foodicsIntegration = async ({
    code,
    type,
    merchantId,
  }: {
    code: string;
    merchantId: string;
    type: string;
  }): Promise<{
    success: boolean;
  }> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: `/merchants/${merchantId}/integrations/auth`,
      data: { code, type },
    });
    return this.executeApiCall(0, 'foodicsIntegration', true, apiCall);
  };

  fetchMenuItemOption = async (
    key: string,
    optionId: string,
  ): Promise<MenuItemOptions> => {
    this.loadAccessToken();
    const apiCall = axios({
      method: 'get',
      url: `/menus/options/${optionId}`,
    });
    return this.executeApiCall(0, 'fetchMenuItemOption', true, apiCall);
  };

  /**
   * Save MenuX Item.
   *
   * @param item The Menu Item
   * @returns {Promise<Array>}
   */
  createMenuItem = async (item: {
    item: MenuItem | undefined;
  }): Promise<MenuItem> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: '/v2/menus/item',
      data: item,
    });

    return this.executeApiCall(0, 'createMenuItem', true, apiCall);
  };

  /**
   * Updates a Category.
   *
   * @param data The category
   * @returns {Promise<Array>}
   */
  updateCategory = async (data: MenuCategory): Promise<MenuCategory> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'patch',
      url: `/menus/category/${data.id}`,
      data,
    });

    return this.executeApiCall(0, 'updateCategory', true, apiCall);
  };

  /**
   * Saves a Category.
   *
   * @param data The category
   * @returns {Promise<Array>}
   */
  createCategory = async (
    data: MenuCategory,
  ): Promise<MenuCategory[] | MenuCategory> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: '/menus/category',
      data,
    });

    return this.executeApiCall(0, 'createCategory', true, apiCall);
  };

  fetchCategoryById = async (id: string): Promise<MenuCategory> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/menus/category/${id}`,
      params: {
        include: 'MENUS,ITEMS',
      },
    });

    return this.executeApiCall(0, 'fetchCategoryById', true, apiCall);
  };

  fetchCategoryByIdV1 = async (id: string): Promise<MenuCategory> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/menus/v1/category/${id}`,
      params: {
        include: 'MENUS,ITEMS',
      },
    });

    return this.executeApiCall(0, 'fetchCategoryById', true, apiCall);
  };

  /**
   * Saves List of Categories.
   *
   * @param data The category
   * @returns {Promise<Array>}
   */
  createCategories = async (data: MenuCategory[]): Promise<MenuCategory[]> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: '/menus/categories',
      data,
    });

    return this.executeApiCall(0, 'createCategory', true, apiCall);
  };

  /**
   * Save MenuX Item Options.
   *
   * @param itemId The Menu Item Id
   * @param options The Menu Item Options
   * @returns {Promise<Array>}
   */
  saveMenuItemOptions = async ({
    itemId,
    options,
  }: {
    itemId: string;
    options: MenuItemOptions[];
  }): Promise<MenuItemOptions[]> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: `/menus/menuItem/${itemId}/options`,
      data: options,
    });

    return await this.executeApiCall(0, 'saveMenuItemOptions', true, apiCall);
  };

  fetchOrderStats = async (
    merchantId: string,
    startDate: string,
    endDate: string,
    unit: string,
  ): Promise<Insights> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/merchants/${merchantId}/orders/stats`,
      params: { startDate, endDate, UNIT: unit },
    });

    return this.executeApiCall(0, 'fetchOrders', true, apiCall);
  };

  /**
   * Fetch All Orders
   * This function fetches all the order for the given user
   *
   * @param key: string
   * @param merchantId: string
   * @param startDate: string
   * @param endDate: string
   * @param limit: number
   * @param startKey: string (Optional)
   *
   * @returns {Promise<OrderList>}
   */

  fetchOrders = async (
    key: string,
    merchantId: string,
    startDate: string,
    endDate: string,
    limit: number,
    sort: string,
    selectedStore: string,
    startKey?: string | unknown,
    include?: Include[],
  ): Promise<OrdersData> => {
    this.loadAccessToken();

    const storeId = selectedStore !== 'allStores' ? selectedStore : null;

    const params: any = { startDate, endDate, limit, sort, startKey, storeId };

    if (include) {
      params.include = include.join(',');
    }

    const apiCall = axios({
      method: 'get',
      url: `/merchants/${merchantId}/orders`,
      params,
    });

    return this.executeApiCall(0, 'fetchOrders', true, apiCall);
  };

  /**
   * Search Orders
   * This function search and return order if it exists in the complete DB
   *
   * @param key The reacy query key
   * @param orderNumber:string
   * @returns {Promise<void>}
   */

  searchOrder = async (key: string, orderNumber: string): Promise<Order> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/orders/search`,
      params: { orderNumber },
    });

    return this.executeApiCall(0, key, true, apiCall);
  };

  fetchAccountOrders = async (
    key: string,
    startDate: string,
    endDate: string,
    limit: number,
    sort: string,
  ) => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/accounts/self/orders`,
      params: { startDate, endDate, limit, sort },
    });

    return this.executeApiCall(0, key, true, apiCall);
  };

  fetchAccountUsage = async (key: string, month: number, year: number) => {
    this.loadAccessToken();
    const apiCall = axios({
      method: 'get',
      url: `/accounts/self/usage`,
      params: { month, year },
    });

    return this.executeApiCall(0, key, true, apiCall);
  };

  fetchMerchantApp = async (id: string): Promise<App> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/merchants/${id}/app`,
    });

    return this.executeApiCall(0, 'fetchMerchantApp', true, apiCall);
  };

  /* Fetch Order Details
   * This function fetches the order details
   * @param {orderId}
   * @returns {Promise<Order>}
   */

  fetchOrderById = async (id: string): Promise<Order> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/v1/orders/${id}`,
    });

    return this.executeApiCall(0, 'fetchOrderById', true, apiCall);
  };

  /**
   * Complete the order
   *
   * @param orderId string

   * @returns {Promise<void>}
   */

  completeOrder = async (orderId: string): Promise<void> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: `/orders/${orderId}/complete`,
    });

    return this.executeApiCall(0, 'completeOrder', true, apiCall);
  };

  assignDriver = async ({
    orderId,
    courierId,
  }: AssignDriver): Promise<Courier> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: `/v1/orders/${orderId}/assign-courier`,
      params: { courierId },
    });

    return this.executeApiCall(0, 'assignDriver', true, apiCall);
  };

  /**
   * Accept the order
   *
   * @param acceptOrderEntry AcceptOrderEntry
   *
   * @returns {Promise<void>}
   */

  acceptOrder = async ({
    orderId,
    estimatedTime,
  }: AcceptOrderEntry): Promise<void> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: `/orders/${orderId}/accept`,
      params: { estimatedTime },
    });

    return this.executeApiCall(0, 'acceptOrder', true, apiCall);
  };

  updateOrderStatus = async ({ orderId, orderStatus }): Promise<void> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: `/v1/orders/${orderId}/status`,
      params: {
        status: orderStatus,
      },
    });

    return this.executeApiCall(0, 'updateOrderStatus', true, apiCall);
  };

  inProgress = async (orderId: string): Promise<void> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: `/v1/orders/${orderId}/in-delivery`,
    });

    return this.executeApiCall(0, 'acceptOrder', true, apiCall);
  };

  /**
   * Reject the order
   *
   * @param rejectOrderEntry RejectOrderEntry obj
   *
   * @returns {Promise<void>}
   */

  rejectOrder = async ({
    orderId,
    reason,
  }: RejectOrderEntry): Promise<void> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: `/orders/${orderId}/reject`,
      params: { reason },
    });

    return this.executeApiCall(0, 'rejectOrder', true, apiCall);
  };

  /**
   * fetch Admin as a Account object
   *
   * @returns {Promise<Account>}
   */

  fetchAccount = async (): Promise<Account> => {
    this.loadAccessToken();
    const apiCall = axios({
      method: 'get',
      url: '/v1/admins/self/account',
    });
    return this.executeApiCall(0, 'fetchAdmin', true, apiCall);
  };

  fetchQuickSightEmbed = async (dashboardId: string): Promise<any> => {
    delete axios.defaults.headers.common['X-Safary-SessionId'];
    delete axios.defaults.headers.common['X-Foodbit-SessionId'];

    axios.defaults.headers.common['x-api-key'] =
      process.env.REACT_APP_API_EXT_KEY;
    const quickInsightEmail = process.env.REACT_APP_QUICKINSIGHT_EMAIL;
    const apiCall = axios({
      method: 'get',
      url: `${process.env.REACT_APP_APPLE_PAY}/quicksight-url`,
      params: {
        sessionName: quickInsightEmail,
        dashboardId,
      },
    });
    return this.executeApiCall(0, 'fetchQuickSightEmbed', true, apiCall);
  };

  checkQuicksightAuth = async (code: string): Promise<any> => {
    const apiCall = axios({
      method: 'get',
      url: `/merchants/quicksight`,
      params: {
        code,
      },
    });
    return this.executeApiCall(0, 'checkQuicksightAuth', true, apiCall);
  };

  fetchVersion = async (): Promise<Version> => {
    this.loadAccessToken();
    delete axios.defaults.headers.common['X-Safary-SessionId'];
    delete axios.defaults.headers.common['X-Foodbit-SessionId'];
    const appVersion = process.env.REACT_APP_VERSION;
    const apiCall = axios({
      method: 'get',
      url: `${process.env.REACT_APP_APPLE_PAY}/pos-app-min-version?version=${appVersion}&os=dashboard`,
    });
    return this.executeApiCall(0, 'fetchVersion', true, apiCall);
  };

  /**
   * Updates a merchant
   *
   * @param data The merchant to update
   * @returns {Promise<Merchant[]>}
   */
  updateMerchant = async (data: Merchant): Promise<Merchant> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'put',
      url: '/merchants',
      data,
    });

    return this.executeApiCall(0, 'updateMerchant', true, apiCall);
  };

  /**
   * Get All merchants
   *
   * @returns {Promise<Merchant[]>}
   */
  fetchMerchants = async (): Promise<Merchant[]> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: '/admin/self/merchants',
    });

    return this.executeApiCall(0, 'fetchMerchants', true, apiCall);
  };

  /**
   * Fetch All Customers
   * This function fetches all the customers for the given merchant
   *
   * @param key: string
   * @param merchantId: string
   * @param sort: Sort
   * @param sortBy: SortByKey
   * @param selectedStore: string
   * @param phoneNumber: string
   * @param startKey: string (Optional)
   *
   * @returns {Promise<Customers>}
   */

  fetchCustomers = async (
    key: string,
    merchantId: string,
    sort: string,
    sortBy: string,
    selectedStore: string,
    phoneNumber: string,
    startKey?: string | unknown,
  ): Promise<Customers> => {
    this.loadAccessToken();

    const storeId = selectedStore !== 'allStores' ? selectedStore : null;

    const phoneNumberValue = !phoneNumber ? null : phoneNumber;

    const apiCall = axios({
      method: 'get',
      url: this.API_URL.concat(`/merchants/${merchantId}/customers`),

      params: {
        limit: 50,
        sort,
        startKey,
        sortBy,
        storeId,
        phoneNumber: phoneNumberValue,
      },
    });

    return this.executeApiCall(0, 'fetchCustomers', true, apiCall);
  };

  /**
   * Fetch Customer Details By ID
   *
   * @param id`: string
   * @returns {Promise<Customer>}
   */

  fetchCustomerById = async (
    customerId: string,
    merchantId: string,
  ): Promise<CustomerAPI> => {
    await this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: this.API_URL.concat(`/merchants/${merchantId}/customer-details`),
      params: { guestIds: customerId },
    });

    return this.executeApiCall(0, 'fetchCustomerById', true, apiCall);
  };

  getPresignedImage = (
    name: string,
    type: UploadType,
  ): Promise<PresignedImage> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: '/uploads/presigned',
      params: { name, type },
    });

    return this.executeApiCall(0, 'getPresignedImage', true, apiCall);
  };

  /**
   * Fetch Specific Customer Orders
   * This function fetches all the order of customer for the given guestIds
   *
   * @param key: string
   * @param merchantId: string
   * @param startDate: string
   * @param endDate: string
   * @param limit: number
   * @param startKey: string (Optional)
   *
   * @returns {Promise<OrdersData>}
   */

  fetchCustomerOrders = async (
    key: string,
    merchantId: string,
    startDate: string,
    endDate: string,
    limit: number,
    sort: string,
    guestIds: string[],
    startKey?: string,
  ): Promise<OrdersData> => {
    this.loadAccessToken();

    const encodedStartKey =
      startKey !== undefined
        ? encodeURIComponent(JSON.stringify(startKey).slice(1, -1))
        : '';

    const apiCall = axios({
      method: 'get',
      url: this.API_URL.concat(
        `/merchants/${merchantId}/customers/orders?startKey=${encodedStartKey}`,
      ),
      params: {
        startDate,
        endDate,
        limit,
        sort,
        guestIds: guestIds.join(','),
      },
    });

    return this.executeApiCall(0, 'fetchCustomerOrders', true, apiCall);
  };

  fetchOutOfStockStoresByItem = async (itemId: string): Promise<Store[]> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/menus/items/${itemId}/out-of-stock-stores`,
    });

    return this.executeApiCall(0, 'fetchOutOfStockStoresByItem', true, apiCall);
  };

  createCoupon = async (payload: Coupon): Promise<Coupon> => {
    this.loadAccessToken();

    const apiCall = axios({ method: 'post', url: '/coupons', data: payload });

    return this.executeApiCall(0, 'createCoupon', true, apiCall);
  };

  fetchCoupons = async (merchantId: string): Promise<Coupon[]> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/merchants/${merchantId}/coupons`,
    });

    return this.executeApiCall(0, 'fetchCoupons', true, apiCall);
  };

  patchCoupon = async (payload: {
    id: string;
    coupon: Partial<Coupon>;
  }): Promise<Coupon> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'patch',
      url: `/coupons/${payload.id}`,
      data: payload.coupon,
    });

    return this.executeApiCall(0, 'patchCoupon', true, apiCall);
  };

  deleteCoupon = async (couponId: string): Promise<unknown> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'delete',
      url: `/coupons/${couponId}`,
    });

    return this.executeApiCall(0, 'deleteCoupon', true, apiCall);
  };

  createDiscount = async (payload: Discount): Promise<Discount> => {
    this.loadAccessToken();

    const apiCall = axios({ method: 'post', url: '/discounts', data: payload });

    return this.executeApiCall(0, 'createDiscount', true, apiCall);
  };

  fetchDiscounts = async (merchantId: string): Promise<Discount[]> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/merchants/${merchantId}/discounts`,
    });

    return this.executeApiCall(0, 'fetchDiscounts', true, apiCall);
  };

  patchDiscount = async (payload: {
    id: string;
    discount: Partial<Discount>;
  }): Promise<Discount> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'patch',
      url: `/discounts/${payload.id}`,
      data: payload.discount,
    });

    return this.executeApiCall(0, 'patchDiscount', true, apiCall);
  };

  deleteDiscount = async (discountId: string): Promise<unknown> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'delete',
      url: `/discounts/${discountId}`,
    });

    return this.executeApiCall(0, 'deleteDiscount', true, apiCall);
  };

  googleTranslate = async (payload: {
    content: string[];
    target: string;
  }): Promise<{
    translations?: {
      translatedText: string;
      detectedSourceLanguage: string;
    }[];
    error?: string;
  }> => {
    delete axios.defaults.headers.common['X-Safary-SessionId'];
    delete axios.defaults.headers.common['X-Foodbit-SessionId'];

    axios.defaults.headers.common['x-api-key'] =
      process.env.REACT_APP_API_EXT_KEY;

    const apiCall = axios({
      method: 'post',
      url: `${process.env.REACT_APP_APPLE_PAY}/translate`,
      data: payload,
    });

    return this.executeApiCall(0, 'googleTranslate', true, apiCall);
  };

  generateAIText = async (payload: {
    content: string;
    lang: string;
    cuisineType: string;
  }): Promise<
    | {
        message: {
          role: string;
          content: string;
        };
        finish_reason: string;
        index: number;
      }[]
    | {
        error: string;
      }
  > => {
    delete axios.defaults.headers.common['X-Safary-SessionId'];
    delete axios.defaults.headers.common['X-Foodbit-SessionId'];

    axios.defaults.headers.common['x-api-key'] =
      process.env.REACT_APP_API_EXT_KEY;

    const apiCall = axios({
      method: 'post',
      url: `${process.env.REACT_APP_APPLE_PAY}/openai-taglines`,
      data: payload,
    });

    return this.executeApiCall(0, 'generateAIText', false, apiCall);
  };

  createDriver = async (payload: {
    drivers: Driver[];
    merchantId: string;
  }): Promise<Driver> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'post',
      url: `/merchants/${payload.merchantId}/couriers`,
      data: payload.drivers,
    });

    return this.executeApiCall(0, 'createDriver', true, apiCall);
  };

  fetchDrivers = async (merchantId: string): Promise<Driver[]> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/merchants/${merchantId}/couriers`,
    });

    return this.executeApiCall(0, 'fetchDrivers', true, apiCall);
  };

  patchDriver = async (payload: {
    driver: MakePartialExceptOne<Driver, 'id'>;
    merchantId: string;
  }): Promise<Driver> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'patch',
      url: `/merchants/${payload.merchantId}/couriers/${payload.driver.id}`,
      data: payload.driver,
    });

    return this.executeApiCall(0, 'patchDriver', true, apiCall);
  };

  deleteDriver = async (payload: {
    merchantId: string;
    driverId: string;
  }): Promise<Driver> => {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'delete',
      url: `/merchants/${payload.merchantId}/couriers/${payload.driverId}`,
    });

    return this.executeApiCall(0, 'deleteDriver', true, apiCall);
  };

  fetchLinksV2 = async (
    merchantId: string,
  ): Promise<{
    main: LinksV2[];
    menu: LinksV2[];
    onlineOrdering: LinksV2[];
    dineIn: LinksV2[];
  }> => {
    this.loadAccessToken();

    const params = {
      include: [
        Include.Menu,
        Include.Main,
        Include.DineIn,
        Include.OnlineOrdering,
      ].join(','),
    };

    const apiCall = axios({ url: `/v2/merchants/${merchantId}/links`, params });

    return this.executeApiCall(0, 'fetchLinksV2', true, apiCall);
  };

  patchAccount = async (account: {
    name: string;
    businessName: Label;
    emailAddress: string;
    phoneNumber: string;
    id: string;
  }): Promise<unknown> => {
    this.loadAccessToken();

    const { id, ...data } = account;

    const apiCall = axios({
      url: `/accounts/${id}`,
      method: 'patch',
      data,
    });

    return this.executeApiCall(0, 'patchAccount', true, apiCall);
  };

  /**
   * Get the user form the backend
   *
   * @param id The user identifier.
   * @return {Promise<Object>}
   */
  public async fetchUser(email: string): Promise<User> {
    this.loadAccessToken();

    const apiCall = axios({
      method: 'get',
      url: `/users/${email}`,
    });

    return this.executeApiCall(0, 'fetchUser', true, apiCall);
  }

  public async forgotPassword(email: string) {
    const apiCall = axios({
      method: 'get',
      url: `/users/password/reset?emailAddress=${email}`,
    });

    return this.executeApiCall(0, 'forgotPassword', true, apiCall);
  }

  /**
   * Reset password an order
   *
   * @param phoneNumber The phone number.
   * @return {Promise<Order>}
   */
  public async requestResetPassword(phoneNumber: string): Promise<any> {
    const apiCall = axios({
      method: 'get',
      url: '/users/password/reset',
      params: {
        phoneNumber,
      },
    });

    return this.executeApiCall(0, 'requestResetPassword', true, apiCall);
  }

  /**
   * Execute a given call. Before checking the call, check if the token has
   * expired and try to refresh it. Queues up the calls if the token is expired
   * until the token is refreshed.
   *
   * @param numberOfRetries The number of retries we have made so far for the @call
   * @param name Name of the api call.
   * @param needsAuth If true, the function will attach the access_token and refreshes it if needed.
   * @param call The api call.
   * @returns {Promise<void>}
   */
  public async executeApiCall(
    numberOfRetries: number,
    name: string,
    needsAuth: boolean,
    call: Promise<any>,
  ): Promise<any> {
    if (numberOfRetries >= 5) {
      throw Error('Reached max retries');
    }

    if (needsAuth && (await this.isTokenExpired())) {
      if (!this.refreshing) {
        await this.refreshToken();
      } else {
        // Wait for 2 seconds and try again
        await sleep(2000);
        return this.executeApiCall(numberOfRetries + 1, name, needsAuth, call);
      }
    }

    try {
      const response = await call;
      if (response.data) {
        return response.data;
      }
      return;
    } catch (error) {
      throw new ApiError(error);
    }
  }

  public isUserLoggedIn() {
    try {
      this.loadAccessToken();
      return {
        isLoggedIn: this.token?.access_token ? true : false,
        token: this.token,
      };
    } catch (e) {
      return { isLoggedIn: false, token: null };
    }
  }

  /**
   * Logs out the user
   *
   */
  public async logout() {
    await MXCookies.reset();
  }
}

export default new Api();
// export { MXApi as TestApi };
export const sleep = async (ms: number) =>
  new Promise(resolve => setTimeout(resolve, ms));
