import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import LoginPage from 'src/auth/components/LoginPage';
import { useAuthStore } from 'src/auth/hocs/useAuth';
import { useCurrentLanguage } from 'src/i18n/hooks/useCurrentLanguage';
import { DesktopSideMenu } from 'src/layout/Sidemenu/Desktop';
import { MobileSideMenu } from 'src/layout/Sidemenu/Mobile';
import { Topbar } from 'src/layout/Topbar';
import { isRTL } from 'src/translations/utils/languageUtils';

export const PageLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const { language } = useCurrentLanguage();
  const { isLoggedIn } = useAuthStore();

  const rtl = isRTL(language);

  const onClose = () => {
    setSidebarOpen(false);
  };

  const onOpen = () => {
    setSidebarOpen(true);
  };

  if (!isLoggedIn) {
    return <LoginPage />;
  }

  return (
    <div dir={rtl ? 'rtl' : 'ltr'}>
      <MobileSideMenu open={sidebarOpen} onClose={onClose} />

      <DesktopSideMenu />
      <div className="flex flex-1 flex-col rtl:md:pr-64 ltr:md:pl-64">
        <main className="flex-1">
          <Topbar handleOpenSidebar={onOpen} />
          <Outlet />
        </main>
      </div>
    </div>
  );
};
