import { Link, useLocation } from 'react-router-dom';
import { SideMenuLink } from 'src/utils/Links';
import StringsHelper from 'src/utils/StringsHelper';

export const SideMenuItem = ({
  item,
  closeSideMenu,
}: {
  item: SideMenuLink;
  closeSideMenu?: () => void;
}) => {
  const { pathname } = useLocation();

  const isActive = pathname === item.href;
  return (
    <div>
      <Link
        to={item.href}
        onClick={closeSideMenu}
        className={StringsHelper.classNames(
          isActive
            ? 'bg-gray-100 text-green-400'
            : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
          'group w-full flex items-center  py-2 text-sm font-medium rounded-md',
        )}>
        <item.icon
          className={StringsHelper.classNames(
            isActive
              ? 'text-green-500'
              : 'text-gray-400 group-hover:text-gray-500',
            'mx-2 flex-shrink-0 h-6 w-6',
          )}
          aria-hidden="true"
        />

        <span>{item.name}</span>
      </Link>
    </div>
  );
};
