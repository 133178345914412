import { ArrowLeftOnRectangleIcon } from '@heroicons/react/20/solid';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { CircularProgress } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Images } from 'src/common/assets/images';
import { useCurrentLanguage } from 'src/i18n/hooks/useCurrentLanguage';
import { useLogin } from 'src/menu/hooks/useLogin';
import { LanguageSelection } from 'src/signup/components/LanguageSelection';
import { isRTL } from 'src/translations/utils/languageUtils';

const LoginPage = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const { login, loading, success } = useLogin();

  const { control, handleSubmit } = useForm();

  const { pathname, search } = useLocation();

  const { language } = useCurrentLanguage();

  const isRtl = language ? isRTL(language) : false;

  const navigate = useNavigate();

  const { t } = useTranslation('signup');

  useEffect(() => {
    if (success) {
      if (pathname && search) {
        navigate(`${pathname}${search}`);
      } else if (pathname) {
        navigate(pathname);
      } else navigate('/');
    }
  }, [pathname, navigate, search, success]);

  const onSubmit = async (data: { username: string; password: string }) => {
    const { username, password } = data;
    await login(username, password);
  };

  return (
    <div
      className="relative  flex items-center justify-center overflow-hidden h-screen isolate pt-10 pb-20 sm:py-24"
      dir={isRtl ? 'rtl' : 'ltr'}>
      <div className="absolute top-[5%] rtl:left-[4%] ltr:right-[4%]">
        <LanguageSelection />
      </div>
      <div className="absolute inset-0 -z-10 text-slate-900/[0.07] [mask-image:linear-gradient(to_bottom_left,white,transparent,transparent)]">
        <svg
          className="absolute inset-0 h-full w-full"
          xmlns="http://www.w3.org/2000/svg">
          <defs>
            <pattern
              id="grid-bg"
              width="32"
              height="32"
              patternUnits="userSpaceOnUse"
              x="100%"
              patternTransform="translate(0 -1)">
              <path d="M0 32V.5H32" fill="none" stroke="currentColor" />
            </pattern>
          </defs>
          <rect width="100%" height="100%" fill="url(#grid-bg)" />
        </svg>
      </div>
      <div className="absolute dur inset-x-0 top-[-20rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]">
        <svg
          className="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]"
          viewBox="0 0 1155 678"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fill="url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)"
            fillOpacity=".3"
            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
          />
          <defs>
            <linearGradient
              id="45de2b6b-92d5-4d68-a6a0-9b9b2abad533"
              x1="1155.49"
              x2="-78.208"
              y1=".177"
              y2="474.645"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#3de9b6" />
              <stop offset={1} stopColor="#f44237" />
            </linearGradient>
          </defs>
        </svg>
        <svg
          className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]"
          viewBox="0 0 1155 678"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fill="url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)"
            fillOpacity=".3"
            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
          />
          <defs>
            <linearGradient
              id="45de2b6b-92d5-4d68-a6a0-9b9b2abad533"
              x1="1155.49"
              x2="-78.208"
              y1=".177"
              y2="474.645"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#3de9b6" />
              <stop offset={1} stopColor="#f44237" />
            </linearGradient>
          </defs>
        </svg>
      </div>

      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <img
              className="mx-auto h-12 w-auto"
              src={language === 'ar' ? Images.logo_ar : Images.logo}
              alt="Your Company"
            />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
              {t('signinToAccount')}
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              {t('or')}{' '}
              <Link
                to="/signup"
                className="text-green-500 hover:text-green-500">
                {t('becomeNewMember')}
              </Link>
            </p>
          </div>
          <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  {t('emailUsername')}
                </label>

                <Controller
                  rules={{
                    required: true,
                  }}
                  name="username"
                  control={control}
                  as={
                    <input
                      id="email-address"
                      name="text"
                      type="text"
                      dir="ltr"
                      autoComplete="email"
                      required
                      className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm"
                      placeholder={t('emailUsername')}
                    />
                  }
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  {t('password')}
                </label>

                <div className="relative">
                  <Controller
                    rules={{ required: true }}
                    name="password"
                    control={control}
                    as={
                      <input
                        id="password"
                        name="password"
                        type={isPasswordVisible ? 'text' : 'password'}
                        autoComplete="current-password"
                        required
                        className="block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm"
                        placeholder={t('password')}
                      />
                    }
                  />
                  <button
                    className="absolute top-3 right-2"
                    type="button"
                    onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
                    {isPasswordVisible ? (
                      <EyeIcon className="w-4 h-4 text-gray-600" />
                    ) : (
                      <EyeSlashIcon className="w-4 h-4 text-gray-600" />
                    )}
                  </button>
                </div>
              </div>
            </div>

            <div>
              <button
                disabled={loading}
                type="submit"
                className="group relative flex w-full justify-center rounded-md border border-transparent bg-green-500 py-2 px-4 text-sm font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2">
                {loading && (
                  <CircularProgress
                    color="inherit"
                    className="!mx-2 my-0.5"
                    size={14}
                  />
                )}
                {t('signin')}
                <span className="absolute inset-y-0 ltr:right-0 rtl:left-0 flex items-center ltr:pr-3 rtl:pl-3">
                  <ArrowLeftOnRectangleIcon
                    className="h-5 w-5 text-green-200 group-hover:text-green-300 ltr:rotate-180"
                    aria-hidden="true"
                  />
                </span>
              </button>
            </div>
            <div className="flex z-[10000] items-center justify-center">
              <Link
                to="/forgot-password"
                className=" text-gray-500 text-sm focus:outline-none focus:ring-2 focus:ring-green-500 hover:text-green-500 focus:ring-offset-1">
                {t('forgotPassword')}
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
