export const en = {
  translations: 'Translations',
  languageDialogHeadline:
    'What languages do you want your menu and store info to be in?',
  addTranslation: 'Add Language',
  chooseLanguage: 'Choose a language',
  primary: 'Primary',
  makePrimary: 'Make Primary',
  addEdit: 'Add / Edit',
  menuItemCategoryMessage: 'Please Select Menu Item Category',
  menuItemPriceMessage: 'Please Enter Menu Item Price',
  dashboardLngDescription: 'Preferred Language for the dashboard.',
  dashboardLanguage: 'Dashboard Language',
  supported: 'Supported',
  deleteTranslationMessage:
    'By deleting this language you will remove the language translations from all stores and menus.',
  languages: 'Languages',
  translate: 'Translate',
  from: 'From:',
  into: 'Into:',
  itemName: 'Item name',
  selectALang: 'Please select a language',
  selectTarget: 'Please select a target language',
  oneLanguage: `You support one langauge only.\nClick button below to add more languages.`,
  addLanguage: 'Add language',
  manageSupported: 'Manage Supported Languages',
  removeLanguage: 'Are you sure you want to remove {{language}}?',
  valueRequired: 'A value for {{language}} language is required.',
  translation: 'Translation',
  translateTo: 'Translate to:',
  useGoogleTranslate: 'Use Google Translate',
  addContentInSource: 'Please enter some content in default input to translate',
  aiDescription: 'AI Description',
};

export const ar: typeof en = {
  aiDescription: 'النص عن طريق الذكاء الأصطناعي',
  addContentInSource: 'الرجاء ادخال نص لترجمته',
  useGoogleTranslate: 'ترجمة عن طريق قوقل',
  translateTo: 'ترجم الى',
  translation: 'الترجمة',
  valueRequired: '{{language}}',
  removeLanguage: '{{language}}?',
  manageSupported: 'تغير اللغات المدعومة',
  addLanguage: 'اضف لغة',
  oneLanguage: 'حالياً تدعم لغة واحدة، اضغط الزر أدناه لإضافة لغات جديدة',
  from: 'من',
  into: 'الى',
  itemName: 'اسم الصنف',
  selectALang: 'اختر اللغة',
  selectTarget: 'اللغة المترجم لها',
  translate: 'ترجم',
  languages: 'اللغات',
  translations: 'الترجمة',
  addEdit: 'اضافة/تعديل',
  dashboardLanguage: 'لغة لوحت التحكم',
  dashboardLngDescription: 'اللغة المفضلة للوحة التحكم',
  supported: 'مدعوم',
  menuItemPriceMessage: 'الرجاء ادخال السعر',
  menuItemCategoryMessage: 'الرجاء اختيار القسم',
  languageDialogHeadline:
    'ما هي اللغات التي تريد أن تكون معلومات القائمة والمتجر بها؟',
  addTranslation: 'اضف لغة ترجمة',
  chooseLanguage: 'اختر اللغة',
  primary: 'اللغة الأساسية',
  makePrimary: 'اختيار كاللغة أساسية',
  deleteTranslationMessage: 'احذف اللغة',
};
