import { Languages } from 'src/translations/consts';
import { Language } from 'src/translations/uiTypes';

export const isRTL = (lang: string) =>
  lang === 'ar' ||
  lang === 'he' ||
  lang === 'ur' ||
  lang === 'dv' ||
  lang === 'ku' ||
  lang === 'fa';

const supportedDashboardLanguages = [
  'en',
  'ar',
  'hi',
  'zh',
  'es',
  'fr',
  'bn',
  'pt',
  'ru',
  'id',
  'tr',
  'ja',
  'de',
];

export const SupportedLanguages = Languages.filter(lang =>
  supportedDashboardLanguages.includes(lang.code),
);

export const convertApiLanguageToUILanguage = (language: string): Language =>
  SupportedLanguages.find(lang => language === lang.code);

export const convertApiLanguagesToUILanguages = (
  languages: string[],
): Language[] =>
  languages
    .map(item => convertApiLanguageToUILanguage(item))
    .flat()
    .filter(Boolean);
