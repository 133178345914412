export const en = {
  qr_codes: 'QR Codes',
  links: 'Links',
  selectLinkDescription:
    'Each link has a unique experience based on the type of ordering',
  selectLink: 'Select Link',
  linksQr: 'Links & QR codes',
  viewMenuMessage: 'View your digital menu',
  contactUsQrCode: 'Contact us to get your QR Code',
  clickToDownload: 'Click To Download',
  link: 'Link',
  addLink: 'Add Link',
  stores: 'Stores',
  url: 'URL',
  selectExperience: 'Select Experience',
  linkExpDesc: 'The customer experience you want to achieve with this link',
  linksSubtitle: 'View your stores and digital menu via links',
  qrCode: 'QR code',
  viewMenu: 'Visit Site',
  QRCodeFor: 'GET QR CODE',
  copyLink: 'Copy Link',
  subHeadingStores: 'Select the stores that will be available via this link',
  subHeadingTable: 'Enter the Table No.',
  subHeadingUrl: 'Enter the URL and Name',
  table: 'Table',
  nameLink: 'Name of Link',
  tableNo: 'Table Number',
  tableNoPlaceholder: 'Enter Table Number',
  noLinksPlaceHolder:
    'Create your first store and your link will be created automatically',
  noLinks: 'No Links',
  download: 'Download',
  allInOneMerchant: 'Linktree-Like Link',
  allInOneMerchantDesc:
    'Showcase all your services (i.e Delivery Pickup, Table Ordering) in one single link (Similar to Linktree but for restaurants)',
  tableOrdering: 'Table Ordering',
  tableOrderingDesc: 'We have generated individual links for each store',
  onlineOrdering: 'Delivery & Pickup',
  onlineOrderingDesc:
    'Link for each store to online ordering (Delivery & Pickup)',
  menuOnly: 'Menu Only (without ordering)',
  menuOnlyDesc: 'View a link to a menu without ordering',
  checkout: 'Checkout',
  experience: 'Experience',
  tryIt: 'Try it',
  interested: 'Interested?',
};

export const ar: typeof en = {
  interested: 'هل تريد التجربة؟',
  tryIt: 'جرب الخدمة',
  experience: 'التجربة',
  checkout: 'جرب',
  allInOneMerchantDesc: 'هذا الرابط يشمل ',
  menuOnly: 'قائمة الطعام (من دون طلبات)',
  menuOnlyDesc: 'اعرض الرابط للمنيو من دون طلبات',
  onlineOrdering: 'طلبات استلام أو توصيل',
  onlineOrderingDesc: 'رابط للطلبات لكل متجر للطلبات الاونلاين (توصيل واستلام)',
  tableOrdering: 'طلبات الطاولة',
  tableOrderingDesc: 'رابط لكل متجر للطلبات المحلية (من الطاولة أو الجلسة)',
  allInOneMerchant:
    'رابط يشمل روابط لجميع الخدمات اللي تقدمها (طلبات توصيل، طلبات طاولة)',
  qr_codes: 'كيو ار كود',
  subHeadingStores: 'اختر المتاجر الذي تريد ربطها  مع هذا الرابط',
  selectLink: 'اختر الرابط',
  selectLinkDescription:
    'كل رابط له تجربة خاصة بناءا على نوعية الطلب (محلي أو خارجي (استلام أو توصيل))',
  tableNo: 'رقم الطاولة',
  nameLink: 'اسم الرابط',
  tableNoPlaceholder: 'ادخل رقم الطاولة',
  subHeadingTable: 'ادخل اسم الرابط',
  subHeadingUrl: 'ادخل الرابط واسم الرابط',
  clickToDownload: 'اضغط هنا للتحميل',
  links: 'روابط',
  table: 'طاولة',
  stores: 'المتاجر',
  url: 'الرابط',
  link: 'الرابط',
  selectExperience: 'اختر نوع التجربة',
  linkExpDesc: 'تجربة العميل التي تهدف لها من خلال هذا الرابط',
  addLink: 'اضف رابط',
  linksQr: 'روابط و كيواركود',
  viewMenuMessage: 'اعرض المنيو الالكتروني لمنشأتك',
  contactUsQrCode: 'تواصل معنا لحصول على الكود لمنشأتك',
  linksSubtitle: 'تصفح متجرك والمنيو الالكتروني عبر الروابط',
  qrCode: 'كيو ار كود',
  viewMenu: 'تصفح الموقع',
  QRCodeFor: 'احصل على الكيو ار كود',
  copyLink: 'انسخ',
  noLinksPlaceHolder: 'قم بإنشأ متجرك والرابط سيظهر هنا',
  noLinks: 'لايوجد روابط',
  download: 'تحميل',
};
