import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { Images } from 'src/common/assets/images';
import { useCurrentLanguage } from 'src/i18n/hooks/useCurrentLanguage';
import { isRTL } from 'src/translations/utils/languageUtils';

import { useNavigationLinks } from './hooks/useNavigationLinks';
import { SideMenuItem } from './SideMenuItem';
import { SideMenuItemChildren } from './SideMenuItemChildren';

const VERSION = process.env.REACT_APP_VERSION;

export const MobileSideMenu = ({ open, onClose }) => {
  const { language } = useCurrentLanguage();

  const rtl = isRTL(language);

  const NAVIGATIONS = useNavigationLinks();
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-40 md:hidden" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-40 flex">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom={rtl ? 'translate-x-full' : '-translate-x-full'}
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo={rtl ? 'translate-x-full' : '-translate-x-full'}>
            <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 ">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <div
                  className={`absolute top-0 pt-2 rtl:left-0 ${
                    rtl ? 'left-0 -ml-12' : ' right-0 -mr-12'
                  }`}>
                  <button
                    type="button"
                    className="ltr:ml-1 rtl:mr-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={onClose}>
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex flex-shrink-0 items-center px-4">
                <img className="h-8 w-auto" src={Images.logo} alt="Workflow" />
              </div>
              <div className="mt-5 h-0 flex-1 overflow-y-auto flex flex-col">
                <nav
                  className="flex-1 space-y-1 bg-white px-2"
                  aria-label="Sidebar">
                  {NAVIGATIONS.map(item =>
                    !item.children ? (
                      <SideMenuItem
                        key={item.name}
                        item={item}
                        closeSideMenu={onClose}
                      />
                    ) : (
                      <SideMenuItemChildren
                        key={item.name}
                        item={item}
                        closeSideMenu={onClose}
                      />
                    ),
                  )}
                </nav>
                <span className="p-4 text-center">V{VERSION}</span>
              </div>
            </Dialog.Panel>
          </Transition.Child>
          <div className="w-14 flex-shrink-0" aria-hidden="true" />
        </div>
      </Dialog>
    </Transition.Root>
  );
};
