export const en = {
  user: 'User',
  users: 'Users',
  admin: 'Admin',
  enabled: 'Enabled',
  accountUpdated: 'Your Account is Upgraded',
  accountUpdateMessage:
    'Your account is upgraded to Premium. Enjoy online ordering for 30 days 😁.',
  ok: 'okay',
  quicksight: 'Foodbit Insight',
  quicksightDescription: 'Please enter your password to view the analytics. ',
  noSearchQuery: 'No Option Found With the Search Query',
  search: 'Search',
  notSupportedIntegration: 'Sorry! Integrations Not Available',
  storesSelected: 'Stores Selected',
  addStores: 'Add Stores',
  addStore: 'Add Store',
  stockStatus: 'Stock Status',
  cancel: 'Cancel',
  january: 'January',
  february: 'February',
  addTranslations: 'Add Translations',
  reachOutToUs: 'Reach out to us',
  login: 'Login',
  editTranslations: 'Edit Translations',
  march: 'March',
  april: 'April',
  may: 'May',
  june: 'June',
  july: 'July',
  august: 'August',
  hours: 'Hours',
  september: 'September',
  october: 'October',
  november: 'November',
  december: 'December',
  confirm: 'Confirm',
  menu: 'Menu',
  menus: 'Menus',
  name: 'Name',
  about: 'About',
  phoneNumber: 'Phone Number',
  email: 'Email',
  emailAddress: 'Email Address',
  close: 'Close',
  sr: 'SAR',
  update: 'Update',
  remove: 'Remove',
  submit: 'Submit',
  clear: 'Clear',
  open: 'Open',
  closed: 'Closed',
  changeLanguage: 'Change Language',
  delete: 'Delete',
  edit: 'Edit',
  done: 'Done',
  save: 'Save',
  genericSuccessTitle: 'Success',
  savedSuccess: 'Saved Successfully',
  errorSave: 'Unable To Save',
  confirmDelete: 'Are you sure you want to remove this item',
  upgrade: 'Upgrade',
  upgradeMemberShip: 'Upgrade Membership',
  freeMembership: 'Free Membership',
  paidMembership: 'Paid Membership',
  logout: 'Logout',
  empty: 'Nothing yet!',
  logoutConfirmTitle: 'Confirm Logout',
  logoutConfirmMessage: 'You sure you want to logout?',
  yes: 'Yes',
  alert: 'Alert',
  contactSupport: 'Contact Support',
  havingIssues: 'Having issues?',
  comingSoon: 'Coming Soon',
  contactUs: 'Contact Us',
  invalidInput: 'Input is invalid',
  submitError: 'Error in submitting your form',
  deletedSuccessfully: 'Deleted Successfully!',
  isDefaultMessage: 'If enabled, option will be preselected.',
  backToDashboard: 'Back To Dashboard',
  updatedSuccessfully: 'Updated Successfully!',
  download: 'Download',
  copied: 'Copied',
  opensAt: 'Opens at',
  closesAt: 'Closes at',
  day: 'Day',
  availability: 'Availability',
  username: 'Username',
  noDineInLink: ' No Dine in link.',
  userType: 'User Type',
  password: 'Password',
  disable: 'Disable',
  disabled: 'Disabled',
  enable: 'Enable',
  privacyPolicy: 'Privacy Policy',
  termsOfService: 'Terms of Service',
  thankYou: 'Thank You',
  back: 'Back',
  next: 'Next',
  backToHome: 'Go Home',
  rememberPassword: 'Remember password?',
  emailRequired: 'Please Enter Your Email',
  isDefault: 'Default',
  nothingAddedYet: "It seems like you haven't added anything Yet!",
  allSelected: 'Nothing To Select.',
  previouslySaved: 'Previously Saved',
  addCategory: 'Add Category',
  versionUpdate: 'Version Update',
  isSuccessIntegration: 'The integration was successful',
  isIntegrationError: 'Sorry, the integration was unsuccessful',
  versionUpdateDesc:
    'Your current version is outdated. You can update to use the new version now!',
  skip: 'Skip',
  forPaidMembersOnly: 'For Premium Members Only',
  notFoundMessageOne:
    ' Oops, sorry this page is having a bit of a struggle loading.',
  notFoundMessageTwo: 'Thanks for your patience while we sort out this mix up.',
  goBackHome: 'Back to home',
  supportIntegration: 'if you need us to support an integration',
  exportLimit: '100 Items Allowed',
  selectedItems: 'Selected Items',
  pleaseEnter: 'Please Enter',
  OTP: ' Generate One Time Login Code',
  notifications: 'Notifications',
  copy: 'Copy',
  dineIn: 'Dine In',
  onlineOrdering: 'Online Ordering',
  dineInDesc:
    'Customized experience for customers that are dining in your establishment',
  onlineOrderDesc:
    'Customized experience for customers that are ordering delivery or pickup',
  foodics: 'Foodics',
  viewingAsAdmin: 'Viewing As Admin',
  whatsNew: 'Whats New',
  dineInDescription: 'view your dine in ordering menu',
  onlineOrderingDescription: ' view your online ordering menu',
  onlineMenu: 'Online Menu',
  installing: 'Installing... ',
  deleteTable: 'Delete Table',
  deleteTableDesc: 'Are you sure you want to delete this table?',
  tableNo: 'Table No.',
  tableSize: 'Table Size (# of People)',
  tableNoPlaceholder: 'i.e 2, 12',
  tableNoRequired: 'Table number is required',
  tableSizePlaceholder: 'table for 2, 4',
  tableCreatedSuccess: 'Table Created Successfully!',
  tableUpdateSuccess: 'Table Updated Successfully!',
  noTable: 'No tables yet.',
  noTableDesc: 'Create a table to generate a QR Code for it',
  tableError: 'Unable To Create Table',
  createTable: 'Create a Table',
  updateTable: 'Update a Table',
  createTableDesc: 'Each table will have a unique number in each store',
  tableUpdateError: 'Unable To Update Table',
  genericErrorTitle: 'Error',
  genericError: 'Sorry, Something Went Wrong',
  takeLongTime:
    'This may take up to 10 minutes, you can continue playing around in the dashboard until then.',
  loadingIntegration: 'Please do not refresh. This might take few minutes.',
  downloadQrCode: 'Download Qr Code',
  alreadyTaken: 'URL is already taken',
  linkPlaceholder: 'Mc-Donalds / KFC',
  successLink: 'Successfully Created!',
  twoDigitNumber: 'Table Number should be 2 digits',
  enableDineInLink: 'Enable DineIn to see the link',
  deleteLink: 'Are you sure you want to remove this Link?',
  deleteLinkTitle: 'Delete Link',
  insights: 'Insights',
  app: 'App',
  configure: 'Configure',
  emptyReservationPlaceholder: 'No reservations yet. Add one to get started!',
  outOfStockStoresDescription: 'Stores where this item is out of stock',
  link: 'Link',
  notLinked: 'Not Linked',
  linkedCategories: 'Linked Categories',
  linkedStores: 'Linked Stores',
  linkedItems: 'Linked Items',
  linkedMenus: 'Linked Menus',
  linkedOptionSets: 'Linked Option Sets',
  visibleHidden: 'Visible/Hidden',
  visible: 'Visible',
  inStock: 'In Stock',
  outOfStock: 'Out of Stock',
  taxable: 'Taxable',
  linkedOptions: 'Linked Option Items',
  selection: 'Selection',
  visibleDesc: 'Whether this menu item will be visible',
  makeInStock: 'Make In Stock',
  assignReportTag: 'Assign Report Tag',
  outOfStockStores: 'Out of Stock',
  assignReportTagDesc: 'Assign a report tag to this item',
  taxableDesc:
    'Whether to apply tax on this item/option or not. To modify the tax, please go to the Edit Store Page → "Tax" tab',
  mobileOrders: 'Mobile Orders',
  onlineOrders: 'Online Orders',
  openClosed: 'Open/Closed',
  supportedOrderTypes: 'Supported Order Types',
  inclusive: 'Inclusive',
  exclusive: 'Exclusive',
  onlineLink: 'Pickup & Delivery Order Link',
  onlineLinkDesc: 'In Store/Curbside Pickup & Delivery',
  dineInLink: 'Dine In Order Link',
  dineInLinkDesc: 'Customer scans QR code to place order',
  mobileOrdersDesc: 'Disable/Enable all types of orders',
  taxIncludedInPrice: 'Whether tax is included in the price or not',
  dineInType: 'Dine In Type',
  deliveryType: 'Delivery Type',
  pickupTypes: 'Pickup Types',
  dineInDisabled: 'Dine In orders are currently disabled',
  deliveryDisabled: 'Delivery orders are currently disabled',
  pickupDisabled: 'Pickup orders are currently disabled',
  card: 'Card',
  cash: 'Cash',
  'theme&Appearance': 'Theme & Appearance',
  welcomeScreen: 'Store Welcome Screen',
  preview: 'Preview',
  alreadyMember: 'Already a member?',
  filters: 'Filters',
  clearAll: 'Clear all',
  appBackgroundDescription:
    'The welcome screen will show when customers scan the QR Code for the store. To view, go to "Stores" → Store Overview → Scan one of the Qr Codes',
  'n/a': 'N/A',
  noResults: 'No results found',
  noResultsMessage:
    'We couldn’t find anything with that term. Please try again.',
  generate: 'Generate',
  allergies: 'Allergies',
  nut: 'Nut',
  gluten: 'Gluten',
  pepper: 'Pepper',
  egg: 'Egg',
  mustard: 'Mustard',
  fish: 'Fish',
  sesame: 'Sesame',
  dairy: 'Dairy',
  legumes: 'Legumes',
  export: 'Export',
  exporting: 'Exporting...',
  table: 'Table',
  people: 'People',
  tableExists: 'This store already have a table with the number',
  hidden: 'Hidden',
  lastUpdated: 'Last updated',
  smartDescriptions: 'Smart Descriptions',
  removeEntityConfirmation:
    'Are you sure you want to unlink "{{entityName}}" from "{{parentName}}"?',
  removeEntityWithoutParent:
    'Are you sure you want to unlink "{{entityName}}"?',
  removeEntityConfirmationReverted:
    'Are you sure you want to unlink "{{parentName}}" from "{{entityName}}"?',
  phoneNumberRequired: 'A valid phone number is required',
  markAvailable:
    'Are you sure you want to mark {{itemName}} available for {{storeName}} store?',
  addDescription: 'Add Description',
  noSmartDescriptionAdded: `You haven’t added any smart descriptions to this item yet. Get
  started by adding smart descriptions to improve conversions.`,
  smartDescription: 'Smart Description',
  smartDescriptionSubHeading:
    '  Adding Smart descriptions helps us to improve conversions.',
  create: 'Create',
  support: 'Support',
  honey: 'Honey',
  aiTextGenerator: 'AI Text Generator',
  ingredientsDescription: 'Ingredients/Description',
  ingredientsRequired: 'You must enter ingredients for the item',
  ingredientsPlaceholder: 'Enter ingredients/description of the item',
  cuisineType: 'Cuisine Type',
  generatedText: 'Generated Text:',
  italian: 'Italian',
  foodLabels: 'Food Labels',
  labels: 'Food Labels & Allergies',
  mustHaveOneOrderType: 'You must have at least one order type selected',
  ingredientsSubtitle: 'Include all ingredients for optimal results',
  allergiesDescription:
    'Fine-tune your menu for an allergy-friendly customer experience. Remember, any cross-contamination should be disclosed to customers with allergies.',
  foodLabelDescription:
    'Customize your menu to assist customers with gluten-free, vegetarian, and/or vegan diets.',
  optionCreated: 'Option Created Successfully',
  menuCreated: 'Menu Created Successfully',
  descriptionDeletedSuccessfully: 'Description deleted successfully',
  hidePrices: 'Hide Prices from the menu',
  disableFeedback: 'Disable Feedback',
  hideCashierView: 'Hide Cashier View in the POS app',
  makeCustomerAnonymous: 'Make the customer anonymous when ordering',
  requireEmail: 'Require the customer email when ordering',
  resetAccount: 'Reset Account',
  resetAccountMessage:
    'Are you sure you want to reset your account? Resetting your account will delete everything you have in your account. This action cannot be undone.',
  totalSales: 'Total Sales',
  totalOrders: 'Total Orders',
  totalDineIns: 'Total DineIns',
  maxSales: 'Max Sales',
  minSales: 'Min Sales',
  averageSales: 'Average Sales',
  newCustomers: 'New Customers',
  totalCustomers: 'Total Customers',
  repeatCustomers: 'Repeat Customers',
  averageCustomerValue: 'Average Customer Value',
  maxCustomerValue: 'Max Customer Value',
  minCustomerValue: 'Min Customer Value',
  showAtStoreMessage:
    'Are you sure you want to show {{itemName}} at {{storeName}}?',
  removeLanguage: 'Are you sure you want to remove {{language}}?',
  valueRequired: 'A value for {{language}} language is required.',
  cashier: 'Cashier',
};

export const ar: typeof en = {
  cashier: '',
  valueRequired: '{{language}}',
  removeLanguage: '{{language}}?',
  showAtStoreMessage: '{{itemName}} {{storeName}}',
  averageCustomerValue: '',
  averageSales: '',
  maxCustomerValue: '',
  maxSales: '',
  minCustomerValue: '',
  minSales: '',
  newCustomers: '',
  repeatCustomers: '',
  totalCustomers: '',
  totalDineIns: '',
  totalOrders: '',
  totalSales: '',
  resetAccount: 'احذف جميع بيانات الحساب',
  resetAccountMessage:
    'هل متأكد من حذف جميع بيانات الحساب؟ اذا لديك بيانات في فودكس مثل المنتجات، سيتم حذف بياناتك الحاليه في فودبت وتحميل بيانات فودكس.',
  disableFeedback: 'ايقاف خاصية الملاحظات',
  hideCashierView: 'اخفي الكاشير في تطبيق الطلبات',
  hidePrices: 'اخفي الأسعار',
  makeCustomerAnonymous: 'اجعل العميل مجهول',
  requireEmail: 'اطلب الايميل',
  descriptionDeletedSuccessfully: 'تم الأنشأ بنجاح',
  menuCreated: 'تم انشأ قائمة الطعام',
  optionCreated: 'تم انشأ الخيار',
  deleteTableDesc: 'هل انت متأكد من حذف هذا الجدول؟',
  allergiesDescription:
    'قم باختيار الحساسيات للصنف هذا للسماح بعملائك بمعرفة مكونات الصنف',
  foodLabelDescription:
    'قم بتعين علامات واضحة للصنف مثل (خالي من الحبوب Gluten Free) للسماح للعملاء بالبحث عن الأصناف التي تملك هذي العلامة. مثال: بإمكان العميل بالضغط على زر "خالي من الحبوب" وجميع الأصناف الخالية من الحبوب ستظهر بشكل سهل للتصفح',
  labels: 'الحساسيات والعلامات',
  foodLabels: 'علامات نوع الأكل',
  ingredientsSubtitle: 'اضف جميع محتويات الصنف لنتائج افضل',
  mustHaveOneOrderType: 'لابد من اختيار نوع طلب واحد على الأقل',
  removeEntityWithoutParent: 'هل انت متأكد من الغاء ربط "{{entityName}}؟',
  italian: 'ايطالي',
  generatedText: 'انشئ النص',
  cuisineType: 'نوع المنشأة',
  ingredientsPlaceholder: 'ادخل المحتويات وتفاصيل الصنف',
  ingredientsRequired: 'لابد من ادخال المحتويات',
  ingredientsDescription: 'المحتويات/التفاصيل',
  aiTextGenerator: 'انشأ نص عن طريق الذكائ الأصطناعي',
  support: 'تواصل معنا',
  create: 'انِشأ',
  smartDescription: 'الوصف الذكي',
  noSmartDescriptionAdded: 'لم يتم اضافة وصف ذكي لهذا الصنف',
  smartDescriptionSubHeading:
    'النظام سيفوم بأختيار افضل وصف بناء على اداء كل وصف',
  addDescription: 'اضافة وصف',
  removeEntityConfirmationReverted:
    'هل انت متأكد من إزالة ربط "{{parentName}}" من "{{entityName}}" ؟',
  markAvailable:
    'هل انت متأكد من تغير {{itemName}} الى متواجد في متجر {{storeName}} ؟',
  phoneNumberRequired: 'رقم الجوال مطلوب',
  removeEntityConfirmation:
    'هل انت متأكد من إزالة ربط "{{entityName}}" من "{{parentName}}" ؟',
  smartDescriptions: 'الوصف الذكي',
  lastUpdated: 'اخر تاريخ تم فيه التحديث',
  hidden: 'مخفي',
  tableExists: 'يوجد طاولة بنفس الرقم في هذا المتجر المتجر',
  people: 'الأشخاص',
  table: 'الطاولة',
  exporting: 'جاري التحميل',
  export: 'تحميل',
  generate: 'اصدر',
  allergies: 'مسببات الحساسية',
  nut: 'مكسرات',
  gluten: 'حبوب',
  pepper: 'حار',
  egg: 'بيض',
  mustard: 'خردل',
  fish: 'سمك',
  sesame: 'سمسم',
  dairy: 'حليب',
  legumes: 'بقوليات',
  outOfStockStoresDescription: 'المتاجر اللتي لاتحتوي على هذا الصنف في المخزون',
  inclusive: 'شامل',
  clearAll: 'حذف الجميع ',
  filters: 'خاصيات البحث',
  alreadyMember: 'لديك حساب؟',
  search: 'بحث',
  isDefaultMessage: 'عند تفعيل الخاصية، سيتم اختيار هذا الخيار بشكل تلقائي',
  dineInType: 'نوع الطلبات المحلية',
  'theme&Appearance': 'الشكل والثيم',
  preview: 'عرض',
  welcomeScreen: 'صفحة الترحيب للمتجر',
  card: 'الكرت',
  cash: 'كاش',
  pickupTypes: 'نوع الأستلام',
  deliveryDisabled: 'طلبات التوصبل غير مفعلة',
  pickupDisabled: 'طلبات الأستلام غير مفعلة',
  deliveryType: 'نوع التوصيل',
  onlineLink: 'رابط طلبات التوصيل والأستلام',
  dineInDisabled: 'الطلبات المحلية غير مفعلة',
  dineInLink: 'رابط الطلبات المحلية',
  dineInLinkDesc: 'العميل يمسح الكود لتنفيذ طلبات محلية',
  onlineLinkDesc: 'طلبات استلام داخل المنشأة او درايف ثرو',
  exclusive: 'غير مشمول',
  mobileOrdersDesc: 'تفعيل خاصية الطلبات الاون لاين',
  taxIncludedInPrice: 'الضريبة مشمولة في السعر',
  ok: 'علم',
  notLinked: 'غير مربوط',
  taxable: 'ضريبي',
  openClosed: 'مفتوح / مغلق',
  supportedOrderTypes: 'نوع الطلبات',
  mobileOrders: 'طلبات الاون لاين',
  onlineOrders: 'طلبات الاون لاين',
  outOfStockStores: 'غير موجود في المخزون',
  taxableDesc:
    'هل يوجد ضريبة لهذا الصنف أو الخيار. اذا اردت تحديث قيمة الضريبة، بإمكانك الذهاب الى صفحة تحديث المتجر ← اضغط على قسم "الضريبة"',
  linkedOptions: 'الخيارات المرتبطة',
  visibleDesc: 'سيكون هذا العنصر القائمة ظاهر',
  linkedCategories: 'الأقسام المرتبطة',
  linkedStores: 'المتاجر المرتبطة',
  linkedItems: 'الأصناف المرتبطة',
  linkedMenus: 'قوائم الطعام المرتبطة',
  linkedOptionSets: 'مجموعات الخيارات المرتبطة',
  visibleHidden: 'ظاهر / مخفي',
  visible: 'ظاهر',
  app: 'التطبيق',
  inStock: 'في المخزون',
  outOfStock: 'نفذت الكمية',
  selection: 'اختيار',
  makeInStock: 'جعل في المخزون',
  assignReportTag: 'تعيين علامة التقرير',
  assignReportTagDesc: 'قم بتعيين علامة تقرير لهذا العنصر',
  enabled: 'مفعل',
  addStore: 'إضافة متجر',
  stockStatus: 'وضع المخزون',
  configure: 'إعدادات',
  link: 'الرابط',
  emptyReservationPlaceholder: 'لايوجد اي رابط للحجوزات. اضف رابط الان',
  insights: 'تحليل البيانات',
  accountUpdateMessage:
    'تم ترقية حسابك. استمتع بخدمة الطلبات لمدة ٣٠ يوم مجاناً.',
  accountUpdated: 'تم ترقية حسابك',
  users: 'المستخدمين',
  noDineInLink: 'لا يوجد رابط للطلبات المحلية',
  deleteLink: 'تأكيد عملية حذف الرابط؟',
  quicksight: 'فودبت لتحليل البيانات',
  quicksightDescription: 'الرجاء ادخال كلمة السر',
  deleteLinkTitle: 'احذف الرابط',
  back: 'الخلف',
  next: 'التالي',
  twoDigitNumber: 'رقم الطاولة يحتوي على خانتين فقط',
  enableDineInLink: 'قم بتفعيل الطلبات المحلية لعرض الرابط',
  installing: 'جاري الربط',
  successLink: 'تم الأنشأ بنجاح',
  alreadyTaken: 'تم استخدام الرابط من قبل',
  downloadQrCode: 'تحميل الكود',
  linkPlaceholder: 'ماكدونالدز , برجر كنج',
  noTable: 'لايوجد طاولات',
  noTableDesc: 'قم بإنشأ طاولة لأنشأ كيو ار كو برقم الطاولة',
  updateTable: 'تحديث الطاولة',
  createTable: 'انشأ طاولة',
  createTableDesc: 'كل طاولة سيكون لها رقم خاص في كل منشأة (فرع)',
  deleteTable: 'احذف الطاولة',
  tableUpdateError: 'حصل خطأ في عملية التحديث',
  genericErrorTitle: 'عفوا',
  genericError: 'عفوا، حصل خطأ في اخر عملية',
  tableUpdateSuccess: 'نم تحديث الطاولة بنجاح',
  tableCreatedSuccess: 'تم انشأ الطاولة بنجاح',
  tableError: 'عفواً، لايمكن انشأ الطاولة',
  tableNoRequired: 'رقم الطاولة مطلوب لانشأ الطاولة',
  tableNoPlaceholder: '٢ أو ٥',
  tableSizePlaceholder: 'عدد الأشخاص للطاولة',
  tableNo: 'رقم الطاولة',
  tableSize: 'حجم الطاولة',
  onlineOrderingDescription: 'عرض محتويات المنيو الرقمي',
  dineInDescription: 'عرض محتويات المنيو للطلبات المحلية',
  whatsNew: 'الجديد',
  onlineMenu: 'رابط المتجر',
  dineInDesc: 'انشأ تجربة طلبات محلية خاصة لعملائك',
  onlineOrdering: 'الطلبات الخارجية',
  dineIn: 'الطلبات المحلية',
  onlineOrderDesc: 'انشأ تجربة طلبات خارجية (استلام أو توصيل) خاصة لعملائك',
  foodics: 'فودكس',
  loadingIntegration: 'جاري التحميل',
  notifications: 'التنبيهات',
  takeLongTime:
    'احتمال ان العملية ستأخذ بعض الوقت. بإمكانك استخدام الداشبورد والرجوع بعد فترة بسيطة',
  viewingAsAdmin: 'العرض كأدمن',
  copy: 'نسخ',
  OTP: 'اصدار كود مبسط لتسجيل الدخول',
  notFoundMessageOne: 'عفواً، يوجد خطأ في تحميل الصفحة',
  notSupportedIntegration: 'عفواً، هذا الدمج غير موجود',
  pleaseEnter: 'الرجاء ادخال',
  selectedItems: 'عدد الأختيارات',
  exportLimit: 'العدد الأقصى للأختيارات',
  supportIntegration: 'هل تود ان ندعم خاصية او ربط معين؟',
  notFoundMessageTwo: 'نشكرك على انتظاركء في حين تنفيذ هذه العملية',
  reachOutToUs: 'تواصل معنا',
  menu: 'قائمة الطعام',
  menus: 'قوائم الطعام',
  goBackHome: 'الرجوع الي الصفحة الرئيسية',
  versionUpdate: 'تحديث الأصدار',
  isSuccessIntegration: 'تم الربط بنجاح',
  isIntegrationError: 'عفواً، حصل خطأ في عملية الربط',
  errorSave: 'عفواً، لايمكن الحفظ',
  versionUpdateDesc:
    'نسختك الحالية قديمة. يمكنك التحديث لاستخدام الإصدار الجديد الآن!',
  backToDashboard: 'الرجوع الى الصفحة الرئيسية',
  user: 'المستخدم',
  admin: 'المشرف',
  previouslySaved: 'محفوظه من قبل',
  addCategory: 'اضف قسم',
  allSelected: 'لا يوجد اختيارات',
  storesSelected: 'المتاجر المختارة',
  noSearchQuery: 'لايوجد بيانات من البحث',
  nothingAddedYet: 'لم يتم اضافة اي بيانات',
  userType: 'نوع المستخدم',
  addStores: 'اضف متجر',
  cancel: 'إلغاء',
  editTranslations: 'تعديل لغات الترجمة',
  rememberPassword: 'تذكر',
  addTranslations: 'اضف لغات ترجمة',
  login: 'تسجيل الدخول',
  confirm: 'تأكيد',
  name: 'الإسم',
  about: 'نبذة',
  hours: 'الساعات',
  upgradeMemberShip: 'ترقية الحساب',
  january: 'يناير',
  february: 'فبراير',
  march: 'مارس',
  april: 'ابريل',
  may: 'مايو',
  june: 'يونيو',
  july: 'يولو',
  august: 'اغسطس',
  september: 'سبتمبر',
  october: 'اكتوبر',
  november: 'نوفمبر',
  december: 'ديسمبر',
  phoneNumber: 'رقم الجوال',
  email: 'إيميل',
  emailAddress: 'البريد الالكتروني',
  emailRequired: 'ادخال البريد الالكتروني اجباري',
  close: 'إغلاق',
  sr: 'ريال',
  update: 'تحديث',
  remove: 'حذف',
  submit: 'إرسل',
  clear: 'تفريغ',
  open: 'مفتوح',
  closed: 'مغلق',
  changeLanguage: 'غير اللغة',
  delete: 'حذف',
  edit: 'تعديل',
  done: 'انهاء',
  save: 'حفظ',
  genericSuccessTitle: 'تم',
  savedSuccess: 'تم الحفظ بنجاح',
  confirmDelete: 'هل انت متأكد تريد الحذف؟',
  upgrade: 'ترقية الحساب',
  freeMembership: 'اشترك مجاني',
  paidMembership: 'اشتراك مدفوع',
  logout: 'سجل الخروج',
  empty: 'لايوجد محتويات',
  logoutConfirmTitle: 'تأكيد تسجيل الخروج',
  logoutConfirmMessage: 'هل تريد تسجيل الخروج؟',
  yes: 'نعم',
  alert: 'تنبيه',
  contactSupport: 'تواصل مع فريق الدعم الفني',
  havingIssues: 'تواجه مشاكل؟',
  comingSoon: 'قادم قريباً',
  contactUs: 'تواصل معنا',
  invalidInput: 'يوجد غلط في المعلومات',
  submitError: 'غلط في عملية التنفيذ',
  deletedSuccessfully: 'تم الحذف بنجاح',
  updatedSuccessfully: 'تم التحديث بنجاح',
  download: 'تحميل',
  copied: 'تم النسخ',
  username: 'اسم المستخدم',
  password: 'كلمة المرور',
  opensAt: 'يفتح الساعة',
  closesAt: 'يغلق الساعة',
  day: 'اليوم',
  availability: 'مغلق أو مفتوح',
  disable: 'ايقاف',
  disabled: 'موقف',
  enable: 'تمكين',
  thankYou: 'شكراً',
  backToHome: 'العودة للصفحة الأساسية',
  privacyPolicy: 'Privacy Policy',
  termsOfService: 'Terms of Use',
  isDefault: 'الأفتراضي',
  skip: 'تخطي',
  forPaidMembersOnly: 'هذه الخاصية للأعضاء فقط',
  appBackgroundDescription:
    'ستم اظهار صفحة الأستقبال عند مسح الكود الخاص لكل منشأة. للعرض، اذهب الى صفحة المتاجر واضغط على المتجر وقم بمسح الكود',
  'n/a': 'غير متواجد',
  noResults: 'لايوجد نتائج',
  noResultsMessage: 'لايوجد اي بيانات لكلمة البحث. الرجاء المحاولة مرة اخرى',
  honey: 'عسل',
};
