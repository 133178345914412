import { PaymentMethodUI } from 'src/settings/uiTypes';

export interface PaymentTransaction {
  id: string;
  method: PaymentMethodUI;
  onlinePayment: boolean;
}

export interface AcceptOrderEntry {
  orderId: string | undefined;
  estimatedTime: number;
}
export interface RejectOrderEntry {
  orderId: string;
  reason: string;
}

export enum Period {
  CurrentDay = 0,
  PreviousDay = 1,
  Last7Days = 7,
  Last30Days = 30,
  Custom = 4,
}

export enum UNIT {
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  DAY = 'DAY',
}

export enum SortByKey {
  Date = 'date',
  CustomerName = 'guest.name',
  StoreName = 'name',
  OrderType = 'type',
  Total = 'total',
  Status = 'status',
  SubTotal = 'SUBTOTAL',
  DateCustomerOrders = 'DATE',
  Visits = 'VISITS',
  LastSeen = 'LAST_SEEN',
}

export enum Sort {
  Ascending = 'ASC',
  Descending = 'DESC',
}
