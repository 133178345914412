import moment from 'moment-timezone';
import {
  TimeFormat12Hours,
  TimeFormat24Hours,
} from 'src/customerLoyalty/discounts/types/API';
import { Period } from 'src/orders/uiTypes';

interface MonthsName {
  label: string;
  value: string;
}

export default class DateHelper {
  /**
   * Is the given date expired (older then now and not in the future)
   * @param date
   * @returns {boolean}
   */
  public static isDateExpired(date: Date): boolean {
    return date.getTime() < new Date().getTime();
  }

  public static sortByDate = <
    T extends {
      createdAt: Date;
    },
  >(
    array: T[],
  ): T[] =>
    array?.sort(
      (a, b) =>
        new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf(),
    );

  /**
   * Is the given date expired (older then now and not in the future)
   * @param date
   * @returns {boolean}
   */
  public static isDateNDaysInThePast(date: Date, nDays: number): boolean {
    return new Date().getTime() - date.getTime() > nDays * 86400000;
  }

  /**
   * Is now between the given Hours.
   *
   * @param start (i.e. '1300' which is equal to 01:00 PM, )
   * @param end
   * @param timezone (i.e. Los_Angeles/America)
   * @return true if it's in between times and false otherwiese
   */
  static isNowBetweenHours(
    start: Opt<Date>,
    end: Opt<Date>,
    timezone?: string,
  ): boolean {
    if (!timezone) {
      return true;
    }

    // get the current date and time in the given time zone
    const now = moment.tz(timezone);

    // Get the exact open and close times on that date in the given time zone
    const storeOpenTime = moment.tz(start, timezone);
    const storeCloseTime = moment.tz(end, timezone);

    let check;
    if (storeCloseTime.isBefore(storeOpenTime, 'hour')) {
      // Handle ranges that span over midnight
      check = now.isAfter(storeOpenTime) || now.isBefore(storeCloseTime);
    } else {
      // Normal range check using an inclusive start time and exclusive end time
      check = now.isBetween(storeOpenTime, storeCloseTime, null, '[)');
    }

    return check;
  }

  /**
   * Is the given date expired (older then now and not in the future)
   * @param date
   * @param nMinutes The minutes to check if the given date is beyond it
   * @returns {boolean}
   */
  public static isDateNMinutesInThePast(date: Date, nMinutes: number): boolean {
    const diff = new Date().getTime() - date.getTime();
    const value = diff > nMinutes * 60000;
    return value;
  }

  /**
   * Get the date plus given seconds to the future.
   *
   * @param secondsToAdd Amount of seconds to the future.
   * @returns {Date}
   */
  public static addSecondsToToday(secondsToAdd: number): Date {
    return new Date(new Date().getTime() + secondsToAdd * 1000);
  }

  /**
   * Convert the given date (i.e. 23/02/2018) to an American format (i.e. 02/23/2018)
   *
   * @param date The date (i.e. 23/02/2018)
   * @return {*} (i.e. 02/23/2018)
   */
  public static convertRHFormatToAmericaFormat(date: string): string {
    return moment(date, 'DD/MM/YYYY').format('MM/DD/YYYY');
  }

  /**
   * Convert the given date to a string (i.e. 23/02/2018)
   *
   * @param date The date object
   * @return {*} (i.e. 23/02/2018)
   */
  public static convertDateToString(date: Date): string {
    return moment(date).format('DD/MM/YYYY');
  }

  /**
   * Convert the given hour to a string (i.e. 14:30)
   *
   * @param hour The hour string (14:00)
   * @return {*} (i.e. 23/02/2018)
   */
  public static convertHourToDate(time: Opt<string>): Opt<Date> {
    if (!time) {
      return undefined;
    }
    const newTimeSrt = `2014-08-18T${time.substr(0, 2)}:${time.substr(
      2,
      2,
    )}:00`;
    return new Date(newTimeSrt);
  }

  /**
   * Convert the given date to a string (i.e. 14:30)
   *
   * @param hour The date object
   * @return {*} (i.e. 23/02/2018)
   */
  public static convertDateToHourString(hour: Date): string {
    return moment(hour).format('HHmm');
  }
  /**
   * Returns the startDate And Enddate of the given month
   *
   * @param year The year object
   * @param month The month (should be between 1-12)
   */

  public static getMonthDateRange(year: number, month: number) {
    // month in moment is 0 based, so 9 is actually october,
    // subtract 1 to compensate

    const startDate = new Date(
      moment([year, month - 1]).format('YYYY-MM-DD hh:mm'),
    ).getTime();
    const endDate = new Date(
      moment(startDate).endOf('month').format('YYYY-MM-DD hh:mm'),
    ).getTime();

    return { startDate, endDate };
  }

  /**
   * Convert the given string Time in 12 Hours to a string in 24 Hours (i.e. 14:30)
   *
   * @param time The string object
   * @return {string} (i.e. 13:23)
   */

  public static convert12HoursTimeTo24HoursTimeString(
    time: TimeFormat12Hours,
  ): TimeFormat24Hours {
    return moment(time, ['hh:mm A']).format('HHmm') as TimeFormat24Hours;
  }

  public static getMonthIndexFromName = (monthName: string): number => {
    return Number(moment().month(monthName).format('M'));
  };

  /**
   * Convert the given string Time in 24 Hours to a string in 12 Hours (i.e. 04:30 AM)
   *
   * @param time The string object HHmm
   * @return {string} (i.e. 08:00 AM)
   */

  public static convert24HoursTimeTo12HoursTimeString(
    time: TimeFormat24Hours,
  ): TimeFormat12Hours {
    return moment(time, ['HHmm']).format('hh:mm A') as TimeFormat12Hours;
  }

  public static convert24hoursTimeToHoursTimeString(time: string): string {
    return moment(time, 'HH:mm').format('YYYY-MM-DD HH:mm');
  }

  /**__tests__
   * Convert the given days (i.e. 356) to months (i.e. 12)
   *
   * @param days The # of months
   * @return {number} The number of days
   */
  public static convertDaysToMonths(days: number): number {
    return Math.ceil(moment.duration({ days }).asMonths());
  }

  /**
   * Convert the given date (i.e. 23/02/2018) to formatted month name and day (i.e. February, 23)
   *
   * @param date The date (i.e. 23/02/2018)
   * @return {*} (i.e. February, 23)
   */
  public static formatStringToMonthNameAndDay(date: string): string {
    return moment(date, 'DD/MM/YYYY').format('MMMM DD');
  }

  /**
   * Convert the given date (i.e. 23/02/2018) to formatted date and month name  (i.e. 23, Dec )
   *
   * @param date Date Obj The date (i.e. 23/02/2018)
   * @return string (i.e. February, 23)
   */
  public static formatDateToMonthNameAndDay(date: Date | null): string {
    if (date === null) {
      return '';
    }
    return moment(date).format('DD MMM');
  }

  public static getFilterDates(period: Period | string) {
    let startDate = new Date();
    let endDate = new Date();

    switch (period) {
      case Period.CurrentDay:
        startDate = new Date();
        endDate = new Date();
        break;

      case Period.PreviousDay:
        startDate = new Date(moment().subtract(1, 'day').toString());
        endDate = new Date(moment().subtract(1, 'day').toString());
        break;
      case Period.Last7Days:
        startDate = new Date(moment().subtract(7, 'days').toString());
        break;
      case Period.Last30Days:
        startDate = new Date(moment().subtract(30, 'days').toString());
        break;
    }
    return { startDate, endDate };
  }

  /**
   * Formats the given date (i.e. 2018-01-20T08:00:00.000Z) to a readable date (i.e. 04/02/2019)
   *
   * @param date The date (i.e. 2018-01-20T08:00:00.000Z)
   * @return {*} Readable date (i.e. 04/02/2019)
   */
  public static formatDateToReadableDate(date: Date, format?: string): string {
    return moment(date).format(format || 'DD/MM/YYYY');
  }

  public static formatDateOrders(date: Date): string {
    return moment(date).format('MM/DD/YYYY');
  }

  /**
   * Formats the given date (i.e. 2018-01-20T08:00:00.000Z) to a readable date (i.e. 04/02/2019)
   *
   * @param dateString The date (i.e. 2018-01-20T08:00:00.000Z)
   * @return {*} Readable date (i.e. 04/02/2019)
   */
  public static formatDateStringToReadableDate(
    dateString: string,
    format?: string,
  ): string {
    const date = DateHelper.convertZFormatStringDateToDate(dateString);
    return DateHelper.formatDateToReadableDate(date, format);
  }

  /**
   * Formats the given date (i.e. 2018-01-20T08:00:00.000Z) to a readable date (i.e. 00:11)
   *
   * @param date The date (i.e. 2018-01-20T08:00:00.000Z)
   * @return {*} Readable date (i.e. 00:11)
   */
  public static formatDateToReadableTime(date: Date): string {
    return moment(date).format('hh:mm a');
  }

  /**
   * Formats the given date (i.e. 2018-01-20T08:00:00.000Z) to a readable date (i.e. 00:11)
   *
   * @param date The date (i.e. 2018-01-20T08:00:00.000Z)
   * @return {*} Readable date (i.e. 00:11)
   */
  public static formatDateStringToReadableTime(dateString: string): string {
    const date = DateHelper.convertZFormatStringDateToDate(dateString);
    return DateHelper.formatDateToReadableTime(date);
  }

  /**
   * Is the given date A older than the given date B
   *
   * @param dateA
   * @param dateB
   * @return True or False
   */
  public static isDateAOlderThanDateB(dateA: Date, dateB: Date): boolean {
    return dateA.getDate() < dateB.getDate();
  }

  /**
   * Is the given date A older than the given date B
   *
   * @param dateStringA The date (i.e. 2015-08-11T13:00:00.000000Z)
   * @param dateStringB The date (i.e. 2015-08-11T13:00:00.000000Z)
   * @return True or False
   */
  public static isStringDateAOlderThanStringDateB(
    dateStringA: string,
    dateStringB: string,
  ): boolean {
    const dateA = DateHelper.convertZFormatStringDateToDate(dateStringA);
    const dateB = DateHelper.convertZFormatStringDateToDate(dateStringB);
    return dateA.getDate() < dateB.getDate();
  }

  /**
   * Convert the given date (i.e. 23/02/2018) to a javascript date
   *
   * @param date The date (i.e. 23/02/2018)
   * @return {*} (i.e. Javascript date)
   */
  public static convertStringDateToDate(date: string): Date {
    return moment(date, 'DD/MM/YYYY').toDate();
  }

  public static convertDateToOrderFormat(date: Date): string {
    return moment(date).format('DD/MM/YYYY hh:mm A').toString();
  }

  public static convertDateStringToOrderFormat(dateStr: string): string {
    return moment(this.convertZFormatStringDateToDate(dateStr))
      .format('DD MMM YYYY')
      .toString();
  }

  public static convertDateStringToOrderTime(dateStr: string): string {
    return moment(this.convertZFormatStringDateToDate(dateStr))
      .format('hh:mm A')
      .toString();
  }

  /**
   * Convert the given date (i.e. 2015-08-11T13:00:00.000000Z) to a javascript date
   *
   * @param date The date (i.e. 2015-08-11T13:00:00.000000Z)
   * @return {*} (i.e. Javascript date)
   */
  public static convertZFormatStringDateToDate(date: string): Date {
    return moment(date, 'YYYY-MM-DDTHH:mm:ss.SSSSZ').toDate();
  }

  public static getLastMonthsFromCurrentMonths = (
    lastMonths: number,
    monthNames: MonthsName[],
  ) => {
    const monthsInDropdown = [];
    const today = new Date();
    let d;
    let month;
    let year;
    let monthValue;
    for (let i = lastMonths; i > 0; i -= 1) {
      d = new Date(today.getFullYear(), today.getMonth() + 1 - i, 1);
      month = monthNames[d.getMonth()].label;
      monthValue = monthNames[d.getMonth()].value;
      year = d.getFullYear();
      monthsInDropdown.push({
        displayName: `${month} ${year}`,
        year,
        month: d.getMonth() + 1,
        monthValue: `${monthValue} ${year}`,
      });
    }
    return monthsInDropdown;
  };

  /**
   * Convert the given date (i.e. 2015-08-11T13:00:00.000000Z) to a string
   *
   * @param date The date (i.e. javascript date)
   * @return {string} (i.e. 2015-08-11T13:00:00.000000Z)
   */
  public static convertZFormatStringDateToString(date: Date): string {
    return moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
  }

  /**
   * Calculate the age from a given birthday
   *
   * @param birthday
   * @return {number} The birthday
   */
  public static calculateAge(birthday: string): number {
    // birthday is a date
    return moment().diff(moment(birthday, 'DD/MM/YYYY'), 'years');
  }

  /**
   * Take a given minutes and add it to given date time and return a readable time string .
   *
   * @param date The given date
   * @param minutes The minutes to add
   * @return {*} Readable date (i.e. 00:11)
   */
  public static calculateFutureTimeFromMinutes(
    date: Date,
    minutes: number,
  ): string {
    const value = moment(date, 'hh:mm:ss a"')
      .add(minutes, 'm')
      .format('hh:mm a');
    return value;
  }

  /**
   * Get the elapsed time from a given date.
   *
   * @param date The date
   * @return the elapsed time (i.e. 2 Mins ago)
   */
  public static elapsedTime(date: string): string {
    return moment(date, 'YYYY-MM-DDTHH:mm:ss.SSSSZ')
      .startOf('minute')
      .fromNow();
  }

  /**
   * Calculate the hour difference between date A and date B
   *
   * @param dateA: Date
   * @param dateB: Date
   * @return {number} The Hour Difference
   */
  public static calculateHourDifference(dateA: Date, dateB: Date): number {
    return Math.abs(moment(dateA).diff(dateB, 'hours'));
  }

  public static getDateOfPastNDaysBefore(days: number): Date {
    return moment().startOf('D').subtract(days, 'days').toDate();
  }

  /**
   * Checks if dateA is after dateB
   *
   * @param params: { dateA: string; dateB: string; dateAFormat?: string; dateBFormat?: string }
   * @return boolean If dateA is after dateB or not
   */
  public static isAfter({
    dateA,
    dateB,
    dateAFormat = 'yyyy-MM-DD',
    dateBFormat = 'DD/MM/YYYY',
    granularity = 'days',
  }: {
    dateA: string;
    dateB: string;
    dateAFormat?: string;
    dateBFormat?: string;
    granularity?: moment.unitOfTime.StartOf;
  }): boolean {
    return moment(dateA, dateAFormat).isAfter(
      moment(dateB, dateBFormat),
      granularity,
    );
  }

  /**
   * Checks if dateA is before dateB
   *
   * @param params: { dateA: string; dateB: string; dateAFormat?: string; dateBFormat?: string }
   * @return boolean If dateA is after dateB or not
   */
  public static isBefore({
    dateA,
    dateB,
    dateAFormat = 'yyyy-MM-DD',
    dateBFormat = 'DD/MM/YYYY',
    granularity = 'days',
  }: {
    dateA: string;
    dateB: string;
    dateAFormat?: string;
    dateBFormat?: string;
    granularity?: moment.unitOfTime.StartOf;
  }): boolean {
    return moment(dateA, dateAFormat).isBefore(
      moment(dateB, dateBFormat),
      granularity,
    );
  }

  /**
   * Converts date string to given moment format
   *
   * @param params: { date: string | undefined; format: string }
   * @return {string}
   */
  public static toFormat({
    format,
    date,
  }: {
    date?: Date;
    format: string;
  }): string {
    return moment(date).format(format);
  }

  /**
   * Add given duration to date
   *
   * @param params: { date: string | undefined; format: string }
   * @return {string}
   */
  public static add({
    amount,
    unit,
    date,
  }: {
    date?: Date;
    amount?: moment.DurationInputArg1;
    unit?: moment.unitOfTime.DurationConstructor;
  }): moment.Moment {
    return moment(date).add(amount, unit);
  }
}
