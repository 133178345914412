import { useEffect, useState } from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';

// listen history change and store it
export const useHistoryStack = () => {
  const [stack, setStack] = useState([]);
  const { pathname } = useLocation();
  const type = useNavigationType();

  useEffect(() => {
    if (type === 'POP') {
      setStack(prevStack => prevStack.slice(0, prevStack.length - 1));
    } else if (type === 'PUSH') {
      setStack(prevStack => [...prevStack, pathname]);
    } else {
      setStack(prevStack => [
        ...prevStack.slice(0, prevStack.length - 1),
        pathname,
      ]);
    }
  }, [pathname, type]);

  return stack;
};
