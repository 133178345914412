import { Dict } from 'mixpanel-browser';

import { Analytics } from './Analytics';
import { Event } from './Event';
import MixpanelAnalytics from './MixpanelAnalytics';

/*
 * Makes SafaryAnalytics available on both client side and server side
 */
const MixpanelKey = process.env.REACT_APP_MIXPANEL_KEY as string;

class SafaryAnalytics implements Analytics {
  private mixpanelAnalytics: MixpanelAnalytics;

  constructor(mixpanelKey: string) {
    this.mixpanelAnalytics = new MixpanelAnalytics(mixpanelKey);
  }

  public logEvent(event: Event, params: Dict = {}): void {
    this.mixpanelAnalytics.logEvent(event, params);
  }

  public login(userId: string) {
    this.mixpanelAnalytics.login(userId);
  }

  public signup(customerId: string) {
    this.mixpanelAnalytics.signup(customerId);
  }

  public logout(): void {
    this.mixpanelAnalytics.logout();
  }

  public registerSuperProperties(params: Dict): void {
    this.mixpanelAnalytics.registerSuperProperties(params);
  }
}

export default new SafaryAnalytics(MixpanelKey);
