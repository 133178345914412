import { useState } from 'react';
import MXApi from 'src/api/Api';
import { useAuthStore } from 'src/auth/hocs/useAuth';
import { useAlertStore } from 'src/common/hooks/useGlobalAlert';
import { getPreferredLanguage } from 'src/i18n/getPreferredLanguage';
import { useCurrentLanguage } from 'src/i18n/hooks/useCurrentLanguage';

export const useLogin = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);
  const { setCurrentLanguage } = useCurrentLanguage();

  const { setAlert } = useAlertStore();

  const { setLoggedIn, setIsAdmin } = useAuthStore();

  const login = async (username: string, password: string) => {
    try {
      setLoading(true);
      const data = await MXApi.loginUser(username, password);
      data.is_super_admin && setIsAdmin(data.is_super_admin);
      setSuccess(true);
      setCurrentLanguage(getPreferredLanguage());
      setLoggedIn(data.access_token);
    } catch (e) {
      setError(e.message);
      setAlert('error', e.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    login,
    success,
    loading,
    error,
  };
};
