import React from 'react';
import { SocialIconType } from 'src/online-menu/apiTypes';

export const TwitterIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill={'currentColor'}
      d="M.462 18c1.014-.124 2.042.01 3.056-.157.51-.08.976-.252 1.438-.433a16.76 16.76 0 0 1-3.127-2.575.576.576 0 0 1 .404-.971c.41.019.81-.014 1.2-.095a16.12 16.12 0 0 
          1-2.366-4.141c-.162-.41.314-.824.7-.7.223.09.452.152.685.2-.71-1.704-.857-3.423-.462-5.307.09-.434.662-.538.952-.253 2.276 2.237 5.15 3.313 8.225 3.827-.27-2.104 1.224-4.34 3.185-5.074 1.628-.604 3.813-.19 4.907 1.18.733-.461 1.747-.614 2.561-.756.428-.072.843.347.59.77-.295.496-.576 1.024-.885 1.529a12.05 12.05 0 0 1 1.923-.381c.466-.062.747.657.39.942-.757.605-1.509 1.295-2.313 1.847-.024 5.345-1.585 9.83-6.36 12.87C11.187 22.866 3.79 22.437.33 18.977H.324a.352.352 0 0 1-.261-.276.382.382 0 0 1 .138-.543A.407.407 0 0 1 .462 18Z"
    />
  </svg>
);

export const TittokIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M12.53.02C13.84 0 15.14.01 16.44 0c.08 1.53.63 3.09 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97-.57-.26-1.1-.59-1.62-.93-.01 2.92.01 5.84-.02 8.75-.08 1.4-.54 2.79-1.35 3.94-1.31 1.92-3.58 3.17-5.91 3.21-1.43.08-2.86-.31-4.08-1.03-2.02-1.19-3.44-3.37-3.65-5.71-.02-.5-.03-1-.01-1.49.18-1.9 1.12-3.72 2.58-4.96 1.66-1.44 3.98-2.13 6.15-1.72.02 1.48-.04 2.96-.04 4.44-.99-.32-2.15-.23-3.02.37-.63.41-1.11 1.04-1.36 1.75-.21.51-.15 1.07-.14 1.61.24 1.64 1.82 3.02 3.5 2.87 1.12-.01 2.19-.66 2.77-1.61.19-.33.4-.67.41-1.06.1-1.79.06-3.57.07-5.36.01-4.03-.01-8.05.02-12.07z" />
  </svg>
);

export const WhatsappIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    fill="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g>
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fillRule="nonzero"
        d="M7.253 18.494l.724.423A7.953 7.953 0 0 0 12 20a8 8 0 1 0-8-8c0 1.436.377 2.813 1.084 4.024l.422.724-.653 2.401 2.4-.655zM2.004 22l1.352-4.968A9.954 9.954 0 0 1 2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.954 9.954 0 0 1-5.03-1.355L2.004 22zM8.391 7.308c.134-.01.269-.01.403-.004.054.004.108.01.162.016.159.018.334.115.393.249.298.676.588 1.357.868 2.04.062.152.025.347-.093.537a4.38 4.38 0 0 1-.263.372c-.113.145-.356.411-.356.411s-.099.118-.061.265c.014.056.06.137.102.205l.059.095c.256.427.6.86 1.02 1.268.12.116.237.235.363.346.468.413.998.75 1.57 1l.005.002c.085.037.128.057.252.11.062.026.126.049.191.066a.35.35 0 0 0 .367-.13c.724-.877.79-.934.796-.934v.002a.482.482 0 0 1 .378-.127c.06.004.121.015.177.04.531.243 1.4.622 1.4.622l.582.261c.098.047.187.158.19.265.004.067.01.175-.013.373-.032.259-.11.57-.188.733a1.155 1.155 0 0 1-.21.302 2.378 2.378 0 0 1-.33.288 3.71 3.71 0 0 1-.125.09 5.024 5.024 0 0 1-.383.22 1.99 1.99 0 0 1-.833.23c-.185.01-.37.024-.556.014-.008 0-.568-.087-.568-.087a9.448 9.448 0 0 1-3.84-2.046c-.226-.199-.435-.413-.649-.626-.89-.885-1.562-1.84-1.97-2.742A3.47 3.47 0 0 1 6.9 9.62a2.729 2.729 0 0 1 .564-1.68c.073-.094.142-.192.261-.305.127-.12.207-.184.294-.228a.961.961 0 0 1 .371-.1z"
      />
    </g>
  </svg>
);

export const FacebookIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}>
    <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" />
  </svg>
);

export const SnapchatIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#a)">
      <path
        fill="currentColor"
        d="M12.02 23.192h-.288c-1.41 0-2.156-.415-3.026-1.037-.622-.414-1.202-.829-1.866-.953-.373-.041-.704-.083-1.036-.083-.58 0-1.036.083-1.368.166-.207.041-.373.083-.497.083a.375.375 0 0 1-.373-.249 10.987 10.987 0 0 0-.125-.539c-.082-.456-.165-.746-.373-.787-2.28-.332-2.901-.83-3.067-1.16v-.167c0-.124.083-.248.207-.248 3.482-.58 5.057-4.146 5.099-4.27.207-.414.248-.787.124-1.119-.249-.58-1.036-.829-1.534-.995a2.879 2.879 0 0 1-.331-.124c-1.037-.415-1.12-.83-1.078-1.036.083-.373.539-.622.953-.622.125 0 .208 0 .29.041.456.208.871.332 1.244.332.497 0 .705-.207.746-.249 0-.249-.041-.497-.041-.746-.125-1.658-.249-3.69.29-4.85C7.503 1.098 10.819.808 11.813.808h.457c.994 0 4.31.29 5.886 3.772.538 1.16.373 3.233.29 4.85v.083c0 .249-.042.456-.042.663.042.042.207.207.663.249.332 0 .747-.124 1.161-.332.124-.041.249-.083.373-.083.166 0 .29.042.415.083.331.125.58.373.58.622s-.166.622-1.078.953a2.849 2.849 0 0 1-.331.125c-.498.166-1.285.414-1.534.995-.124.29-.083.663.124 1.119.083.165 1.534 3.73 5.016 4.31.124.042.207.125.207.25 0 .04 0 .082-.041.165-.125.332-.705.788-2.985 1.16-.166.042-.249.25-.373.788-.041.166-.083.373-.124.54-.042.165-.166.248-.332.248h-.041c-.125 0-.29-.042-.498-.083a6.415 6.415 0 0 0-1.368-.124c-.331 0-.663.041-.994.082-.664.125-1.244.54-1.866.954-.829.622-1.948.995-3.357.995Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currentColor" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const InstagramIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill="currentColor"
      d="M16.85 0h-9.7A7.157 7.157 0 0 0 0 7.15v9.7A7.157 7.157 0 0 0 7.15 24h9.7A7.157 7.157 0 0 0 24 16.85v-9.7A7.157 7.157 0 0 0 16.85 0Zm4.736 16.85a4.735 4.735 0 0 1-4.736 4.736h-9.7a4.735 4.735 0 0 1-4.736-4.736v-9.7A4.735 4.735 0 0 1 7.15 2.414h9.7a4.735 4.735 0 0 1 4.736 4.736v9.7Z"
    />
    <path
      fill="currentColor"
      d="M12 5.793A6.214 6.214 0 0 0 5.793 12 6.214 6.214 0 0 0 12 18.207 6.214 6.214 0 0 0 18.207 12 6.214 6.214 0 0 0 12 5.793Zm0 10a3.793 3.793 0 1 1 0-7.586 3.793 3.793 0 0 1 0 7.586Zm6.22-8.466a1.487 1.487 0 1 0 0-2.975 1.487 1.487 0 0 0 0 2.975Z"
    />
  </svg>
);

export const EmailIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth={2}>
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
    />
  </svg>
);

export const PhoneIcon = props => (
  <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      data-name="phone-Filled"
      d="M20.489,20.011A4.273,4.273,0,0,1,17.248,21.5h-.116A15.042,15.042,0,0,1,2.5,6.868,4.28,4.28,0,0,1,4,3.494a4.028,4.028,0,0,1,3.349-.931,3.549,3.549,0,0,1,.474.119,1.492,1.492,0,0,1,1,1.191l.67,4.254a1.515,1.515,0,0,1-.541,1.412c-.084.068-.171.132-.262.193a.538.538,0,0,0-.215.666A9.857,9.857,0,0,0,13.6,15.525a.542.542,0,0,0,.671-.222c.047-.068.1-.135.146-.2a1.513,1.513,0,0,1,1.431-.547l4.332.721a1.473,1.473,0,0,1,1.186,1.033c.027.1.052.207.074.332A4.072,4.072,0,0,1,20.489,20.011Z"
    />
  </svg>
);

type Props = {
  icon: SocialIconType;
  [x: string]: any;
};

export const SocialMediaIcon = ({ icon, ...rest }: Props) => {
  switch (icon) {
    case 'INSTAGRAM':
      return <InstagramIcon {...rest} />;
    case 'TIKTOK':
      return <TittokIcon {...rest} />;
    case 'FACEBOOK':
      return <FacebookIcon {...rest} />;
    case 'SNAPCHAT':
      return <SnapchatIcon {...rest} />;
    case 'TWITTER':
      return <TwitterIcon {...rest} />;
    case 'PHONE':
      return <PhoneIcon {...rest} />;

    case 'WHATSAPP':
      return <WhatsappIcon {...rest} />;
    case 'EMAIL':
      return <EmailIcon {...rest} />;
    default:
      return null;
  }
};
