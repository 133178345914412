import { Dialog, Transition } from '@headlessui/react';
import {
  CheckIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import classNames from 'classnames';
import {
  ChangeEventHandler,
  Fragment,
  MouseEventHandler,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentLanguage } from 'src/i18n/hooks/useCurrentLanguage';
import { Label } from 'src/stores/apiTypes';
import { Language } from 'src/translations/uiTypes';

import { useBoolean } from '../CustomDialog';
import { useTranslationLanguages } from './hooks/useTranslationLanguages';
import { useValidateLabel } from './hooks/useValidateLabel';
import { SupportedLanguagesDialog } from './SupportedLanguagesDialog';
import { TranslationInput } from './TranslationInput';

interface Props {
  show: boolean;
  closeDialog(): void;
  label: Label;
  onSave(label: Label): void;
  entityName?: string;
}

export const TranslationDialog = ({
  show,
  closeDialog,
  onSave,
  label: label_,
  entityName,
}: Props) => {
  const [label, setLabel] = useState({});
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const validateLabel = useValidateLabel();
  const {
    setTrue: handleClickOpen,
    setFalse: handleClose,
    value: open,
  } = useBoolean();
  const { t } = useTranslation();
  const { language: currentLanguage } = useCurrentLanguage();

  const dir = currentLanguage === 'ar' ? 'rtl' : 'ltr';

  const { activeLanguages, primaryLanguage } = useTranslationLanguages();
  const supportedLanguages = activeLanguages.filter(
    lng => lng.code !== primaryLanguage?.code,
  );

  const [selectedLanguage, setSelectedLanguage] = useState<
    Language | undefined
  >(supportedLanguages[0]);

  useEffect(() => {
    setLabel(label_ ?? {});
  }, [label_]);

  useEffect(() => {
    setSelectedLanguage(
      activeLanguages.filter(lng => lng.code !== primaryLanguage?.code)[0],
    );
  }, [activeLanguages, primaryLanguage]);

  const onChangeTranslation: ChangeEventHandler<HTMLInputElement> = e => {
    const { name, value } = e.target;

    const language =
      name === primaryLanguage?.code ? primaryLanguage : selectedLanguage;

    setLabel({ ...label, [name]: value });
    if (value.trim()) {
      setErrors({ ...errors, [name]: '' });
    } else {
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: t('translations:valueRequired', { language: language?.name }),
      }));
    }
  };

  const onSubmit: MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    const { invalidLanguages, isValid } = validateLabel(label);

    if (isValid) {
      setErrors({});
      onSave(label);
      closeDialog();

      return;
    }

    const errs = {};
    invalidLanguages.forEach(lang => {
      errs[lang.code] = t('translations:valueRequired', {
        language: lang.name,
      });
    });

    setErrors({ ...errors, ...errs });

    setSelectedLanguage(
      invalidLanguages.filter(lang => lang.code !== primaryLanguage?.code)[0],
    );
  };

  const onGoogleTranslateSuccess = ({
    language,
    translation,
  }: {
    language: Language;
    translation: string;
  }) => {
    setLabel({
      ...label,
      [language.code]: translation,
    });
  };

  const googleTranslationProps = {
    sourceContent: label[primaryLanguage?.code] ?? '',
    onTranslationSuccess: onGoogleTranslateSuccess,
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[11]"
        onClose={closeDialog}
        dir={dir}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-gray-50 px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-2xl sm:p-6">
                <SupportedLanguagesDialog
                  closeDialog={handleClose}
                  show={open}
                />
                <div className="flex flex-row items-center justify-between mb-4 gap-4">
                  <Dialog.Title className="text-xl font-semibold">
                    {t('translations:translate')}
                  </Dialog.Title>
                  <button
                    type="button"
                    className="rounded-md  text-gray-500 hover:text-gray-600 focus:ring-2 focus:ring-green-500"
                    onClick={closeDialog}>
                    <span className="sr-only">Close dialog</span>
                    <XMarkIcon
                      strokeWidth={2.5}
                      className="h-8 w-8"
                      aria-hidden="true"
                    />
                  </button>
                </div>

                <div>
                  <h3 className="border-b font-medium text-base text-left rtl:text-right">
                    {t('common:isDefault')}
                  </h3>
                  <div className="mt-4">
                    <TranslationInput
                      language={primaryLanguage}
                      onChange={onChangeTranslation}
                      value={label[primaryLanguage?.code] ?? ''}
                      error={errors[primaryLanguage?.code]}
                      entityName={entityName}
                    />
                  </div>
                </div>

                <div className="mt-8">
                  <div className="flex items-center justify-between border-b">
                    <p className="font-medium text-base">
                      {t('translations:translateTo')}
                    </p>
                    <button
                      type="button"
                      className="text-green-500"
                      onClick={handleClickOpen}>
                      {t('translations:manageSupported')}
                    </button>
                  </div>
                  <div className="mt-4">
                    {supportedLanguages.length > 0 ? (
                      <>
                        {supportedLanguages.length > 1 && (
                          <div className="flex gap-3 overflow-x-auto no-scrollbar py-1">
                            {supportedLanguages.map(language => (
                              <button
                                onClick={() => setSelectedLanguage(language)}
                                key={language.code}
                                className={classNames(
                                  'px-4 py-2 rounded border shadow flex items-center gap-2',
                                  selectedLanguage?.code === language.code
                                    ? 'border-green-500 bg-green-50'
                                    : 'bg-white border-white',
                                )}>
                                <span>{language.name}</span>
                                {label[language.code] ? (
                                  <CheckIcon className="w-4 h-4 text-green-500" />
                                ) : (
                                  <ExclamationTriangleIcon className="w-4 h-4 text-red-500" />
                                )}
                              </button>
                            ))}
                          </div>
                        )}
                        <TranslationInput
                          language={selectedLanguage}
                          onChange={onChangeTranslation}
                          value={label[selectedLanguage?.code] ?? ''}
                          error={errors[selectedLanguage?.code]}
                          entityName={entityName}
                          googleTranslateProps={googleTranslationProps}
                        />
                      </>
                    ) : (
                      <div className="bg-gray-50 shadow-sm rounded-md py-4 px-6 text-center space-y-2">
                        <pre>{t('translations:oneLanguage')}</pre>
                        <button
                          type="button"
                          onClick={handleClickOpen}
                          className="bg-green-500 px-4 py-2 rounded text-white font-semibold text-sm">
                          {t('translations:addLanguage')}
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-4">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 sm:col-start-2"
                    onClick={onSubmit}>
                    {t('common:save')}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={closeDialog}>
                    {t('common:cancel')}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
