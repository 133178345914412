import classNames from 'classnames';
import React from 'react';

import { Loader } from '../Loader';

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  isLoading?: boolean;
}

export const PrimaryButton: React.FC<Props> = ({
  isLoading,
  children,
  ...props
}) => {
  return (
    <button
      disabled={props.disabled ?? isLoading}
      type={props.type || props.onClick ? 'button' : undefined}
      {...props}
      className={classNames(
        'flex items-center justify-center gap-2 px-4 py-2 bg-green-500 hover:bg-green-600 text-white text-sm font-semibold border border-transparent rounded disabled:cursor-not-allowed disabled:hover:bg-green-500',
        props.className ?? '',
      )}>
      {isLoading && (
        <Loader
          containerClassName="flex-grow-0"
          loaderClassName="w-4 h-4 fill-green-600 text-green-50"
        />
      )}
      {children}
    </button>
  );
};
