import { isEmpty } from 'lodash';
import { t } from 'src/i18n/translate';
import { SupportedLanguage } from 'src/i18n/types';
import { Label } from 'src/stores/apiTypes';

export const labelOrDefault = (
  name: Opt<Label>,
  currentLang: SupportedLanguage,
) => {
  if (!name) {
    return t('customers:unknown');
  }
  if (isEmpty(name)) {
    return t('customers:unknown');
  }

  const currentLanguageValue = name[currentLang];
  const firstLanguageValue = name[Object.keys(name)[0]];

  return currentLanguageValue || firstLanguageValue || t('common:n/a');
};

export const pluralize = (
  count: number,
  noun: string,
  nounPlural: string,
  currentLang: string,
  suffix = 's',
  hasSuffix = false,
) => {
  if (hasSuffix) {
    return `${count} ${suffix}`;
  }
  if (currentLang === SupportedLanguage.English) {
    return `${count} ${noun}${count !== 1 ? suffix : ''}`;
  } else {
    return `${nounPlural}: ${count}`;
  }
};
