import { Menu, Transition } from '@headlessui/react';
import { Bars3BottomLeftIcon, PencilIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { useBoolean } from 'src/common/components/CustomDialog';
import { Header } from 'src/common/components/Header';
import { labelOrDefault } from 'src/common/utils/labelLanguageUtils';
import { useCurrentLanguage } from 'src/i18n/hooks/useCurrentLanguage';
import { t } from 'src/i18n/translate';
import { useHandleLogout } from 'src/sidemenu/hooks/useHandleLogout';
import { useFetchAccount } from 'src/user/hooks/useFetchAccount';
import StringsHelper from 'src/utils/StringsHelper';

import { LanguageSwitcher } from '../LanguageSwitcher';
import { Links } from '../Links';
import { EditAccountModal } from './EditAccountModal';

interface Props {
  handleOpenSidebar: () => void;
}

export const Topbar = ({ handleOpenSidebar }: Props) => {
  const { handleLogout } = useHandleLogout();
  const { merchant, isPaid, user } = useFetchAccount();

  const { businessLogo, businessName } = merchant || {};

  const { language } = useCurrentLanguage();

  const { value: isVisible, toggle } = useBoolean();

  return (
    <div className="sticky top-0 z-[11] flex flex-col flex-shrink-0 bg-white border-b">
      <EditAccountModal isVisible={isVisible} onClose={toggle} />
      <div className="flex border-b">
        <button
          type="button"
          className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500 md:hidden"
          onClick={handleOpenSidebar}>
          <span className="sr-only">Open sidebar</span>
          <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex items-center flex-1 justify-between px-4 py-2 sm:py-4">
          <div className="flex flex-1 items-center justify-center">
            <LanguageSwitcher />
          </div>

          <div className="flex flex-row items-center gap-2 sm:gap-4">
            <Links />
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="flex max-w-xs items-center rounded-md bg-white text-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 hover:scale-105 hover:shadow-lg hover:shadow-gray-300 transition-all">
                  <span className="sr-only">Open user menu</span>
                  {businessLogo ? (
                    <img
                      className="h-10 w-10 rounded-md object-cover"
                      src={businessLogo}
                      alt=""
                    />
                  ) : (
                    <div className="h-10 w-10 rounded-md grid place-items-center text-green-400 font-semibold text-xl bg-gray-100">
                      {StringsHelper.capitalizeFirstLetter(
                        labelOrDefault(businessName, language),
                      ).charAt(0)}
                    </div>
                  )}
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95">
                <Menu.Items className="absolute rtl:left-0 ltr:right-0 z-50 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <Menu.Item>
                    <button
                      type="button"
                      onClick={toggle}
                      className="px-4 py-3 w-full flex items-center justify-between hover:bg-gray-50">
                      <div className="ltr:text-left rtl:text-right">
                        <p className="truncate capitalize text-sm font-semibold text-green-400">
                          {labelOrDefault(businessName, language)}
                        </p>
                        <p className="text-sm text-gray-500">
                          {user?.emailAddress}
                        </p>
                      </div>
                      <PencilIcon className="w-5 h-5 text-green-500" />
                    </button>
                  </Menu.Item>
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={process.env.REACT_APP_PRIVACY_POLICY_URL}
                          className={StringsHelper.classNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'block px-4 py-2 text-sm',
                          )}>
                          {t('common:privacyPolicy')}
                        </a>
                      )}
                    </Menu.Item>

                    <Menu.Item>
                      {({ active }) => (
                        <a
                          rel="noreferrer"
                          className={StringsHelper.classNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'block px-4 py-2 text-sm',
                          )}
                          href={process.env.REACT_APP_TERMS_OF_USE_URL}
                          target="_blank">
                          {t('common:termsOfService')}
                        </a>
                      )}
                    </Menu.Item>
                    {!isPaid && (
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            rel="noreferrer"
                            className={StringsHelper.classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'block px-4 py-2 text-sm',
                            )}
                            href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}?subject=Upgrade Membership`}
                            target="_blank">
                            {t('common:upgrade')}
                          </a>
                        )}
                      </Menu.Item>
                    )}
                  </div>

                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={handleLogout}
                          className={StringsHelper.classNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'block w-full px-4 py-2 text-left text-sm',
                          )}>
                          Sign out
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
      <Header />
    </div>
  );
};
