export const en = {
  customers: 'Customers',
  customerName: 'Customer',
  date: 'Date',
  more: 'More',
  phoneNumber: '+966505491254',
  loadMore: 'Show More',
  totalOrders: 'Orders',
  totalOrderValue: 'Total Sales',
  lastSeen: 'Last Seen',
  noCustomers: 'No Customers',
  customerDetails: 'Customer Details',
  customerOrders: 'Customer Orders',
  customersSubtitle: 'Customers that ordered from your stores',
  unknown: 'Unknown',
  visits: 'Visits',
  firstSeen: 'First Seen',
  firstSeenInfo: 'Customer Was First Seen On',
  orderValueInfo: 'Total value of orders',
  orderTotalInfo: 'Total count of orders',
  visitsInfo:
    'The number of visits the customer has made to your business. For example, if the customer ordered 3 times in one day, the customer visits will count as 1',
  averageFeedback: 'Avg. Feedback',
  csatRatingCount: 'Ratings',
  happyCustomer: 'Very Happy Customer',
  sadCustomer: 'Very Sad Customer',
  csatRatingInfo: 'Number of times customer rated',
  andOneIs: 'and 1 is',
  averageCustomerHelper:
    'The all time Average Feedback the customer has given. The score scale is 1-5 where 5 is',
  noCustomersPlaceHolder:
    'Once you receive online orders, you will start seeing customers here',
  phoneNumRequired: 'A valid phone number is required',
  veryHigh: 'Very High',
  high: 'High',
  medium: 'Medium',
  low: 'Low',
  veryLow: 'Very Low',
  na: 'NA',
};

export const ar: typeof en = {
  phoneNumRequired: 'رقم الجوال مطلوب',
  customers: 'العملاء',
  averageFeedback: 'معدل التقيم',
  csatRatingInfo: 'عدد المرات التي قيم فيها العميل',
  sadCustomer: 'العميل غير سعيد',
  andOneIs: 'و تقيم بمعدل 1 يعني أن',
  csatRatingCount: 'عدد مرات التقيم',
  happyCustomer: 'العميل سعيد',
  averageCustomerHelper:
    'معدل التقيم بشكل عام الذي قدمه العميل لمنشأتك. التقيم يكون من 1 الى 5. تقيم بمعدل 5 يعني أن',
  phoneNumber: 'رقم الجوال',
  customerName: 'اسم العميل',
  orderValueInfo: 'مجموع المدفوعات',
  orderTotalInfo: 'عدد الطلبات',
  visitsInfo:
    'عدد الزيارات من العميل. مثال: اذا العميل طلب ثلاث مرات في يوم واحد، سيحسب للعميل زيارة واحدة فقط',
  firstSeen: 'أول زيارة',
  firstSeenInfo: 'أول طلب أو زيارة للموقع',
  date: 'التاريخ',
  more: 'المزيد',
  visits: 'الزيارات',
  loadMore: 'عرض المزيد',
  totalOrders: 'مجموع الطلبات',
  totalOrderValue: 'مجموع المشتريات',
  lastSeen: 'اخر زيارة',
  noCustomers: 'لايوجد عملاء',
  customerDetails: 'تفاصيل العميل',
  customerOrders: 'طلبات العميل',
  customersSubtitle: 'العملاء الذين طلبو من متاجرك',
  unknown: 'مجهول',
  noCustomersPlaceHolder: 'بعد استلامك للطلبات، سيظهر العملاء هنا',
  veryHigh: 'عالي جدا',
  high: 'عالي',
  medium: 'متوسط',
  low: 'قليل',
  veryLow: 'قليل جدا',
  na: '--',
};
