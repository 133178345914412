import { Dialog, Transition } from '@headlessui/react';
import { TrashIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateMerchant } from 'src/account/hooks/useUpdateMerchant';
import { Item } from 'src/feedback/uiTypes';
import { useCurrentLanguage } from 'src/i18n/hooks/useCurrentLanguage';
import { Language } from 'src/translations/uiTypes';
import {
  convertApiLanguageToUILanguage,
  SupportedLanguages,
} from 'src/translations/utils/languageUtils';
import { useFetchAccount } from 'src/user/hooks/useFetchAccount';

import { useConfirm } from '../Confirm/hooks/useConfirm';
import { Loader } from '../Loader';
import StyledSelect from '../StyledSelect';
import { useTranslationLanguages } from './hooks/useTranslationLanguages';

interface Props {
  show: boolean;
  closeDialog(): void;
}

export const SupportedLanguagesDialog = ({ show, closeDialog }: Props) => {
  const { t } = useTranslation();
  const {
    activeLanguages,
    setActiveLanguages,
    disabledLanguages,
    setDisabledLanguages,
    primaryLanguage,
    setPrimaryLanguage,
  } = useTranslationLanguages();
  const { confirm } = useConfirm();

  const { language: currentLanguage } = useCurrentLanguage();

  const dir = currentLanguage === 'ar' ? 'rtl' : 'ltr';

  const { merchant } = useFetchAccount();
  const { updateMerchant, isUpdatingMerchant } = useUpdateMerchant();

  const options = SupportedLanguages.filter(
    language => !activeLanguages.some(lang => lang.code === language.code),
  ).map(lang => ({
    value: lang.code,
    label: `${lang.name} (${lang.nativeName})`,
  }));

  const toggleDisabledLanguage = (language: Language) => {
    const isDisabled = disabledLanguages.some(
      lang => lang.code === language.code,
    );

    let languages: Language[] = [];

    if (isDisabled) {
      languages = disabledLanguages.filter(lang => lang.code !== language.code);
    } else {
      languages = [
        ...disabledLanguages.filter(lang => lang.code !== language.code),
        language,
      ];
    }

    setDisabledLanguages(languages);
  };

  const onAddLanguage = (language_: { label: string; value: string }) => {
    const lang = language_ as unknown as Item;

    setActiveLanguages([
      ...activeLanguages,
      convertApiLanguageToUILanguage(lang.value),
    ]);
  };

  const onRemoveLanguage = async (language: Language) => {
    try {
      await confirm({
        message: t('translations:removeLanguage', {
          language: `${language.name} (${language.nativeName})`,
        }),
      });
      setActiveLanguages(prevState =>
        prevState.filter(lang => lang.code !== language.code),
      );
    } catch (error) {}
  };

  const onSave = () => {
    merchant.languages.primary = primaryLanguage.code;
    merchant.languages.disabled = disabledLanguages.map(lang => lang.code);
    merchant.languages.supported = activeLanguages.map(lang => lang.code);

    updateMerchant(
      { merchantId: merchant.id, data: merchant },
      { onSuccess: closeDialog },
    );
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[11]"
        dir={dir}
        onClose={closeDialog}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-gray-50 px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                <Dialog.Title className="text-xl font-semibold mb-2 text-left rtl:text-right">
                  {t('translations:languages')}
                </Dialog.Title>
                <p className="mb-4 text-left rtl:text-right">
                  {t('translations:languageDialogHeadline')}
                </p>
                <StyledSelect
                  options={options}
                  value={[]}
                  onChange={onAddLanguage}
                  placeholder={t('translations:chooseLanguage')}
                />
                <ul className="bg-white mt-5 p-4 rounded shadow-sm space-y-2">
                  {activeLanguages.map(language => (
                    <li key={language.code} className="bg-gray-50 p-4 rounded">
                      <div className="flex items-center gap-4">
                        <input
                          type="checkbox"
                          name="Disabled"
                          className="h-4 w-4 rounded border-gray-300 text-green-400 focus:ring-green-400 disabled:text-gray-300"
                          disabled={primaryLanguage.code === language.code}
                          checked={
                            !disabledLanguages.some(
                              lang => lang.code === language.code,
                            )
                          }
                          onChange={() => toggleDisabledLanguage(language)}
                        />
                        <div className="flex-1 flex items-center justify-between">
                          <div className="flex flex-col">
                            <span className="text-left rtl:text-right">
                              {language.name}
                            </span>
                            <>
                              {disabledLanguages.some(
                                lang => lang.code === language.code,
                              ) ? (
                                <span>{t('common:disabled')}</span>
                              ) : primaryLanguage.code === language.code ? (
                                <span>{t('translations:primary')}</span>
                              ) : (
                                <button
                                  className="underline text-green-500"
                                  onClick={() => setPrimaryLanguage(language)}>
                                  <span>{t('translations:makePrimary')}</span>
                                </button>
                              )}
                            </>
                          </div>
                          <button
                            className="rounded-full bg-red-500 w-7 h-7 flex items-center justify-center disabled:cursor-not-allowed"
                            disabled={primaryLanguage.code === language.code}
                            onClick={() => onRemoveLanguage(language)}>
                            <TrashIcon className="w-4 h-4 text-white" />
                          </button>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-4">
                  <button
                    type="submit"
                    disabled={isUpdatingMerchant}
                    className="disabled:cursor-not-allowed inline-flex w-full justify-center rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 sm:col-start-2"
                    onClick={onSave}>
                    {isUpdatingMerchant ? <Loader /> : t('common:save')}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={closeDialog}>
                    {t('common:cancel')}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
