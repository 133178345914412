export default class ApiError extends Error {
  public statusCode: number | undefined;

  public errorMsg: string;

  /**
   * The error message from an AxiosError.
   *
   * @param error The Axios error.
   */
  public constructor(error?: any) {
    super(error);

    const { data } = error.response;

    if (data?.validation_errors) {
      this.errorMsg = this.getValidationErrorMessage(data.validation_errors);
    } else {
      this.errorMsg =
        error.response &&
        error.response.data &&
        error.response.data.error_description
          ? data.error_description
          : 'Something went wrong!';
    }

    this.statusCode = error.response ? error.response.status : null;
    this.message = this.errorMsg;
  }

  /**
   * Get a readable validation error message from a list of validation errors .
   *
   * @param validationErrors
   */
  public getValidationErrorMessage(validationErrors: any[]) {
    let message = '';
    validationErrors.forEach((error: any) => {
      if (error.propertyName && error.message) {
        message = message.concat(`${error.propertyName}: ${error.message}\n`);
      }
    });

    if (message.length > 1) {
      message = message.slice(0, message.length - 1);
    }

    return message;
  }
}
