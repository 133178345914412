// noinspection TypeScriptUnresolvedFunction

import { CssBaseline } from '@material-ui/core';
import { useEffect, useRef } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AppRouter } from 'src/router/Router';

import { Alert } from './common/components/Alert';
import { Confirm } from './common/components/Confirm';
import { useAnalytics } from './mixpanel-analytics/useAnalytics';
import { Support } from './support';
import { withTheme } from './theme/hocs/withTheme';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const App = withTheme(() => {
  const { logEvent } = useAnalytics();

  const client = useRef(queryClient);

  useEffect(() => {
    logEvent('Launch Dashboard');
  }, [logEvent]);

  return (
    <QueryClientProvider client={client?.current}>
      <Support />
      <Alert />
      <Confirm />

      <ReactQueryDevtools initialIsOpen position="bottom-right" />
      <CssBaseline />
      <AppRouter />
    </QueryClientProvider>
  );
});
