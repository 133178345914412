export const en = {
  drivers: 'Drivers',
  addDriver: 'Add Driver',
  driverCreated: 'Driver Created Successfully',
  deleteDriverMessage:
    'Are you sure you want to delete this driver? This action cannot be undone.',
  deleteSuccessMessage: 'Driver deleted successfully',
  driverUpdated: 'Driver Updated Successfully',
  noDriversMessage:
    'No drivers yet, Click "Add Driver" to start creating new drivers',
};

export const ar: typeof en = {
  noDriversMessage: 'لايوجد سائقين. اضفغط على "اضف سائق" لإنشأ سائق جديد',
  driverUpdated: 'تم تحديث السائق بنجاح',
  deleteSuccessMessage: 'تم حذف السائق بنجاح',
  drivers: 'السائقين لطلبات التوصيل',
  addDriver: 'اضف سائق',
  driverCreated: 'تم إضافة السائق',
  deleteDriverMessage: 'هل متأكد من عملية الحذف؟',
};
