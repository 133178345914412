import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment, ReactNode, useRef } from 'react';
import { useCurrentLanguage } from 'src/i18n/hooks/useCurrentLanguage';
import { isRTL } from 'src/translations/utils/languageUtils';

interface Props {
  isOpen: boolean;
  onClose(): void;
  children: ReactNode;
  dialogClassName?: string;
  dialogPanelClassName?: string;
}

export const Modal = ({
  isOpen,
  onClose,
  children,
  dialogClassName,
  dialogPanelClassName,
}: Props) => {
  const cancelButtonRef = useRef(null);
  const { language } = useCurrentLanguage();

  const isRtl = isRTL(language);

  const rtl = isRtl ? 'rtl' : 'ltr';

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        dir={rtl}
        key={rtl}
        as="div"
        className={classNames('relative z-[11]', dialogClassName)}
        initialFocus={cancelButtonRef}
        onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel
                className={classNames(
                  'relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg',
                  dialogPanelClassName,
                )}>
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
