import { useQuery } from 'react-query';
import Api from 'src/api/Api';
import { ReactQueryKey } from 'src/common/enums';
import { useFetchAccount } from 'src/user/hooks/useFetchAccount';

export const useFetchLinksV2 = () => {
  const { merchant, loading } = useFetchAccount();

  const { data, refetch, isLoading } = useQuery(
    [ReactQueryKey.FetchLinksV2, merchant?.id],
    () => Api.fetchLinksV2(merchant?.id),
    { enabled: !!merchant?.id, refetchOnMount: false },
  );

  return { isLoading: loading || isLoading, data, refetch };
};
