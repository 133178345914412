export const en = {
  discounts: 'Discounts',
  addDiscount: 'Add Discount',
  discountName: 'Discount Name',
  availability: 'Availability',
  startDate: 'Start Date',
  startTime: 'Start Time',
  endDate: 'End Date',
  endTime: 'End Time',
  discountApplicable: 'This discount is applicable to',
  allItems: 'All items',
  selectedItems: 'Selected Items',
  date: 'Date',
  time: 'Time',
  setAvailabilityBy: 'Set availability of this discount using:',
  availabilityMessage:
    'The availability of a discount can be set by date or time',
  discountCreated: 'Discount created successfully',
  appliedTo: 'Applied To',
  discountUpdated: 'Discount updated successfully',
  discountDeleted: 'Discount deleted successfully',
  deleteDiscountMessage:
    'Are you sure you want to delete {{discountName}} discount? This action cannot be undone.',
  invalidStartDate: 'Start date must come before end date',
  invalidEndDate: 'End date must come after start date',
  neverExpires: 'Never expires',
  thirtyMinsIncrement: '30-minute increments',
  addDiscountDescription:
    'Apply a fixed or percent discount to all or some menu items for all customers. No coupon code needed for redemption.',
  setDateOrTime: 'You must specify either availability date or time.',
  enabledMessage: "If turned off, This discount won't apply to any item.",
};

export const ar: typeof en = {
  enabledMessage: 'عند الأيقاف، سيتم ايقاف الصنف بشكل كامل',
  setDateOrTime: 'لابد من تعين تاريخ أو وقت للتخفيض',
  addDiscountDescription:
    'حدد تخفيض بمبلغ معين أو نسبة. سيتم تطبيق التخفيض من دون الحاجة الى رمز مثل الكوبون. بإمكانك تطبيق التخفيض على صنف معين أو جميع الأصناف',
  thirtyMinsIncrement: 'الزيادة تتم ب٣٠ دقيقة',
  neverExpires: 'من دون تاريخ انتهاء',
  invalidEndDate: 'خطأ في تاريخ الأنتهاء',
  invalidStartDate: 'خطأ في تاريخ البداية',
  deleteDiscountMessage: 'هل انت متأكد من حذف التخفيض: {{discountName}}',
  discountDeleted: 'تم حذف التخفيض',
  discountUpdated: 'تم تحديث التخفيض',
  appliedTo: 'طبق التخفيض على ',
  discountCreated: 'تم انِشأ التخفيض',
  discountName: 'اسم التخفيض',
  availabilityMessage: 'تاريخ ووقت فعالية التخفيض',
  setAvailabilityBy: 'حدد وقت وتاريخ الفعالية:',
  date: 'تاريخ',
  time: 'وقت',
  allItems: 'جميع الأصناف',
  discountApplicable: 'هذا التخفيض سيطبق على:',
  selectedItems: 'الأصناف المختارة',
  availability: 'فعالية التخفيض',
  endDate: 'تاريخ الأنتهاء',
  endTime: 'وقت الأنتهاء',
  startDate: 'تاريخ البداية',
  startTime: 'وقت البداية',
  addDiscount: 'اضف تخفيض',
  discounts: 'التخفيضات',
};
