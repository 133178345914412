export enum ReactQueryKey {
  OperatorDetailsKey = 'OperatorDetailsKey',
  fetchAllergies = 'fetchAllergies',
  QuickSightUrl = 'QuickSightUrl',
  QuickSightAuth = 'QuickSightAuth',
  AvailableLink = 'AvailableLink',
  Insights = 'Insights',
  FetchStoreDetails = 'FetchStoreDetails',
  FetchLinksByStoreId = 'FetchLinksByStoreId',
  FetchTablesByStoreId = 'FetchTablesByStoreId',
  IntegrationType = 'IntegrationType',
  AllIntegrations = 'AllIntegrations',
  IntegrationDetails = 'IntegrationDetails',
  OperatorStoresKey = 'OperatorStoresKey',
  MenuItem = 'MenuItem',
  OperatorDevicesKey = 'OperatorDevicesKey',
  AllOrdersKey = 'AllOrdersKey',
  AllDevicesKey = 'AllDevicesKey',
  AllStoresKey = 'AllStoresKey',
  AllMenusKey = 'AllMenusKey',
  AllMenuItemsKey = 'AllMenuItemsKey',
  AllMerchantsKey = 'AllMerchantsKey',
  AllLinks = 'AllLinks',
  AllCategories = 'AllCategories',
  AllCustomers = 'AllCustomers',
  Merchant = 'Merchant',
  Myself = 'Myself',
  Account = 'Account',
  Options = 'Options',
  FetchMerchantApp = 'FetchMerchantApp',
  FetchAccountUsage = 'FetchAccountUsage',
  FetchStoreSettings = 'FetchStoreSettings',
  FetchReportLabels = 'FetchReportLabels',
  updateGlobalSettings = 'UpdateGlobalSettings',
  Couriers = 'Couriers',
  Countries = 'Countries',
  Timezones = 'Timezones',
  SingleMenuItem = 'singleMenuItem',
  FetchOutOfStockStoresByItem = 'FetchOutOfStockStoresByItem',
  UpdateVersion = 'UpdateVersion',
  AppShortcuts = 'AppShortcuts',
  AppContacts = 'AppContacts',
  FetchStoreLinksByStoreId = 'FetchStoreLinksByStoreId',
  FetchAllOptions = 'FetchAllOptions',
  FetchSingleOption = 'FetchSingleOption',
  App = 'App',
  FetchFeedbacks = 'FetchFeedbacks',
  FetchScores = 'FetchScores',
  CheckDetails = 'CheckDetails',
  FetchCoupons = 'FetchCoupons',
  FetchDiscounts = 'FetchDiscounts',
  CategoryDetailsV1 = 'CategoryDetailsV1',
  OptionSetDetails = 'OptionSetDetails',
  FetchDrivers = 'FetchDrivers',
  FetchLinksV2 = 'FetchLinksV2',
}

export enum Include {
  Items = 'ITEMS',
  Customer = 'CUSTOMER',
  DineIn = 'DINE_IN',
  Pickup = 'PICKUP',
  Store = 'STORE',
  Payment = 'PAYMENT',
  Check = 'CHECK',
  CheckLocation = 'CHECK_LOCATION',
  Settings = 'SETTINGS',
  Filters = 'FILTERS',
  HiddenItems = 'HIDDEN',
  Inventory = 'INVENTORY',
  Options = 'OPTIONS',
  Orders = 'ORDERS',
  CustomersCount = 'CUSTOMERS_COUNT',
  CheckNumber = 'CHECK_NUMBER',
  OrderNumber = 'ORDER_NUMBER',
  Menu = 'MENU',
  Main = 'MAIN',
  OnlineOrdering = 'ONLINE_ORDERING',
}
