import { lazy } from 'react';
import { RouteProps } from 'react-router-dom';
import { ROUTES } from 'src/utils/Links';

const AddCategoryPage = lazy(
  () => import('src/menu/menu-category/components/AddCategoryPage'),
);
const PaymentPage = lazy(
  () => import('src/payment/components/PaymentPage/PaymentPage'),
);

const StoresPage = lazy(
  () => import('src/stores/components/StoresPage/StoresPage'),
);
const AddStorePage = lazy(
  () => import('src/stores/components/AddStorePage/AddStorePage'),
);
const EditStorePage = lazy(
  () => import('src/stores/components/EditStorePage/EditStorePage'),
);
const DevicesPageContainer = lazy(
  () => import('src/devices/containers/DevicesPageContainer'),
);

const EditDevicePage = lazy(
  () => import('src/devices/components/EditDevicePage/EditDevicePage'),
);

const EditCategoryPage = lazy(
  () =>
    import(
      'src/menu/menu-category/components/edit-category-page/EditCategoryPage'
    ),
);
const ThankYouPaymentPage = lazy(
  () =>
    import('src/payment/components/ThankYouPaymentPage/ThankYouPaymentPage'),
);
const SignupPage = lazy(() => import('src/signup/components/SignupPage'));

const OrdersPageContainer = lazy(
  () => import('src/orders/components/OrdersPage/OrdersPage'),
);

const AccountPage = lazy(() => import('src/account/components/AccountDetails'));

const CustomersPage = lazy(
  () => import('src/customers/components/CustomersPage/CustomersPage'),
);

const CustomerPage = lazy(
  () => import('src/customers/components/CustomerPage/CustomerPage'),
);

const ForgotPasswordPage = lazy(
  () => import('src/forgot-password/ForgotPasswordPage'),
);

const IntegrationsRedirect = lazy(
  () => import('src/IntegrationRedirect/IntegrationRedirect'),
);

const ResetPasswordPage = lazy(
  () => import('src/reset-password/ResetPasswordPage'),
);

const AddMenuItemPage = lazy(
  () =>
    import('src/menu/menu-item/components/add-menuitem-page/AddMenuItemPage'),
);

const EditMenuItemPage = lazy(
  () =>
    import('src/menu/menu-item/components/edit-menuitem-page/EditMenuItemPage'),
);

const AddOptionSetPage = lazy(
  () => import('src/menu/option-sets/components/AddOptionSetPage'),
);

const EditOptionGroupPage = lazy(
  () => import('src/menu/option-sets/components/EditOptionSetPage'),
);
const OptionsGroupPage = lazy(
  () => import('src/menu/option-sets/components/option-sets-page/OptionsPage'),
);

const AddMenuPage = lazy(() => import('src/menu/add-menu/AddMenuPage'));

const EditMenuPage = lazy(() => import('src/menu/edit-menu/EditMenuPage'));

const Menus = lazy(() => import('src/menu/menu-page/Menupage'));

const CategoryPage = lazy(
  () =>
    import('src/menu/menu-category/components/categories-page/CategoriesPage'),
);

const MenuItem = lazy(
  () => import('src/menu/menu-item/components/menu-item-page/MenuItemPage'),
);

const OptionsPage = lazy(() => import('src/menu/options-page/'));

const Quicksight = lazy(() => import('src/quicksight'));

const NotFound = lazy(() => import('src/notFound/NotFound'));

const OnlineMenu = lazy(() => import('src/online-menu'));

const AddOptionPage = lazy(
  () => import('src/menu/options-page/add-option-page'),
);

const EditOptionPage = lazy(
  () => import('src/menu/options-page/edit-option-page'),
);
const FeedbackPageContainer = lazy(
  () => import('src/feedback/components/FeedbacksPage'),
);

const CouponsPage = lazy(
  () => import('src/customerLoyalty/coupons/components/CouponsPage'),
);
const AddCouponPage = lazy(
  () => import('src/customerLoyalty/coupons/components/AddCouponPage'),
);

const DiscountsPage = lazy(() => import('src/customerLoyalty/discounts'));
const AddDiscountPage = lazy(
  () => import('src/customerLoyalty/discounts/components/AddDiscountPage'),
);

export const usePrivateRoutes = (): RouteProps[] => {
  return [
    { path: '', index: true, element: StoresPage },
    { path: ROUTES.CUSTOMERS, element: CustomersPage },
    { path: ROUTES.OPTIONS, element: OptionsPage },
    { path: ROUTES.OPTION_SETS, element: OptionsGroupPage },
    { path: ROUTES.ADD_OPTIONـSET, element: AddOptionSetPage },
    { path: ROUTES.ADD_OPTION, element: AddOptionPage },
    { path: ROUTES.EDIT_OPTION, element: EditOptionPage },
    { path: ROUTES.ITEMS, element: MenuItem },
    { path: ROUTES.CATEGORIES, element: CategoryPage },
    { path: ROUTES.MENUS, element: Menus },
    { path: ROUTES.INSIGHTS, element: Quicksight },
    { path: ROUTES.EDIT_MENU, element: EditMenuPage },
    { path: ROUTES.ADD_MENU, element: AddMenuPage },
    { path: ROUTES.EDIT_OPTIONSET, element: EditOptionGroupPage },
    { path: ROUTES.ONLINE_MENU, element: OnlineMenu },
    { path: ROUTES.CUSTOMER_DETAILS, element: CustomerPage },
    { path: ROUTES.INTEGRATIONS_REDIRECT, element: IntegrationsRedirect },
    { path: ROUTES.ADD_ITEM, element: AddMenuItemPage },
    { path: ROUTES.EDIT_ITEM, element: EditMenuItemPage },
    { path: ROUTES.EDIT_CATEGORY, element: EditCategoryPage },
    { path: ROUTES.ADD_CATEGORY, element: AddCategoryPage },
    { path: ROUTES.ADD_STORE, element: AddStorePage },
    { path: ROUTES.EDIT_STORE, element: EditStorePage },
    { path: ROUTES.DEVICES, element: DevicesPageContainer },
    { path: ROUTES.EDIT_DEVICE, element: EditDevicePage },
    { path: ROUTES.ORDERS, element: OrdersPageContainer },
    { path: ROUTES.SETTINGS, element: AccountPage },
    { path: ROUTES.FEEDBACK, element: FeedbackPageContainer },
    { path: ROUTES.COUPONS, element: CouponsPage },
    { path: ROUTES.ADD_COUPON, element: AddCouponPage },
    { path: ROUTES.DISCOUNTS, element: DiscountsPage },
    { path: ROUTES.ADD_DISCOUNT, element: AddDiscountPage },
  ];
};

export const usePublicRoutes = (): RouteProps[] => {
  return [
    { path: ROUTES.PAYMENT, element: PaymentPage },
    { path: ROUTES.PAYMENT_SUCCESS, element: ThankYouPaymentPage },
    { path: ROUTES.SIGNUP, element: SignupPage },
    { path: ROUTES.FORGOT_PASSWORD, element: ForgotPasswordPage },
    { path: ROUTES.RESET_PASSWORD, element: ResetPasswordPage },
    { path: ROUTES.NOT_FOUND, element: NotFound },
  ];
};
