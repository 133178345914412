import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Modal } from 'src/common/components/Modal';
import { t } from 'src/i18n/translate';
import { useGenerateQrCode } from 'src/links-qrcodes/hooks/useGenerateQrCode';

interface Props {
  open: boolean;
  title: string;
  link: string;
  handleClose: () => void;
}

export const QRDialog = ({ open, title, link, handleClose }: Props) => {
  const QRCode = useGenerateQrCode(link);

  return (
    <Modal onClose={handleClose} isOpen={open}>
      <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
        <button
          onClick={handleClose}
          type="button"
          className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-offset-2">
          <span className="sr-only">Close</span>
          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div className="bg-white  px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div className="mb-3 text-center sm:mt-5">
          <Dialog.Title
            as="h3"
            className="text-lg capitalize font-medium leading-6 text-gray-900">
            {title}
          </Dialog.Title>
        </div>
        <div className="mx-auto flex h-30 w-full items-center justify-center ">
          <a>
            <img
              className="h-full w-full"
              src={QRCode}
              alt={`${t('links-qr:QRCodeOf')} ${title}`}
            />
          </a>
        </div>
      </div>
      <div className="bg-gray-50 px-4 py-5 sm:flex sm:flex-row-reverse sm:px-6">
        <a
          href={QRCode}
          download
          className="inline-flex w-full justify-center rounded-md border border-transparent bg-green-400 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm">
          {t('links-qr:download')}
        </a>
      </div>
    </Modal>
  );
};
