import { create } from 'zustand';

export interface ConfirmProps {
  isOpen: boolean;
  message?: string;
  title?: string;
  close: () => void;
  confirm: (params: ConfirmationParams) => Promise<void>;
  cancellationText?: string;
  confirmationText?: string;
  resolve?: (value: unknown) => void;
  reject?: (reason?: unknown) => void;
  icon?: IconParams;
}

export interface IconParams {
  isVisible: boolean;
  type?: 'warning' | 'success' | 'error' | 'info';
}

interface ConfirmationParams {
  title?: string;
  message?: string;
  confirmationText?: string;
  cancellationText?: string;
  icon?: IconParams;
}

export const [useConfirm] = create<ConfirmProps>(set => ({
  isOpen: false,
  title: undefined,
  message: '',
  close: () =>
    set({
      isOpen: false,
    }),
  confirm: ({
    cancellationText,
    confirmationText,
    message: description,
    title,
    icon,
  }) => {
    set({
      isOpen: true,
      message: description,
      title,
      cancellationText,
      confirmationText,
      icon,
    });
    return new Promise(function (resolve, reject) {
      set({ resolve, reject });
    });
  },
}));
