import { useMutation } from 'react-query';
import Api from 'src/api/Api';

export const useCheckValues = () => {
  const {
    mutateAsync,
    isLoading: loading,
    isSuccess: success,
    data,
    reset,
  } = useMutation(Api.checkUserValues);

  return {
    mutateAsync,
    loading,
    success,
    data,
    reset,
  };
};
