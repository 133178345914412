export const en = {
  location: 'Location',
  stores: 'Stores',
  store: 'Store',
  Timezone: 'Timezone',
  createStore: 'Add Store',
  phoneNumber: 'Phone Number',
  phoneNumberRequired: 'Phonenumber is Required',
  editStore: 'Edit Store',
  city: 'City',
  startTime: 'Start Time',
  endTime: 'End Time',
  addPOS: 'Add POS Device',
  categorySuccess: 'Category Added Successfully!',
  categoryUpdated: 'Category Updated Successfully!',
  categoryNotUpdated: 'Unable To Update Category',
  categoryError: 'Unable To Add Category',
  categoryDeleted: 'Category Deleted Successfully!',
  categoryNotDeleted: 'Unable To Delete Category',
  tables: 'Tables',
  tableDesc: 'Number of tables at the store',
  useGlobalSettings: 'Use Global Settings',
  globalSettingsHelper: 'if this is checked, this store will inherit the',
  globalSettingsRedirect: 'settings you set globally.',
  save: 'Save',
  country: 'Country',
  options: 'Options',
  neighbourhood: 'Neighbourhood',
  acceptingOrders: 'Accepting Orders',
  notAcceptingOrders: 'Not Accepting Orders',
  settings: 'Settings',
  edit: 'Edit',
  main: 'Main',
  storeHours: 'Store Hours',
  noDeviceMsg:
    'You have no devices. To receive orders for this store, you must add a device',
  storeType: 'Store Type',
  coffeeShop: 'Coffee Shop',
  restaurant: 'Restaurant',
  aboutPlaceHolder: 'Italian Restaurant',
  onlineCoffeeShop: 'Online Coffee Shop',
  onlineRestaurant: 'Online Restaurant',
  foodTruck: 'Food Truck',
  coffeeShopTruck: 'Coffee Shop Truck',
  either: 'Either',
  searchLocation: 'Search Location',
  locateMe: 'Locate Me',
  addLocation: 'Add Location',
  storeUpdatedSuccessfully: 'Store Successfully Updated!',
  hideLocation: 'Hide this location from customers',
  hideLocationDesc:
    'This is a good option for busineseses operating from private locations (i.e. homes, apartments)',
  deleteStoreMsg:
    'will be deleted from your stores. This means the store will be permanently removed from the system.',
  storeDeletedSuccessfully: 'Store deleted!',
  storeAddedSuccessfully: 'Store added successfully',
  mustEnterLocation: 'Please enter a location for your store',
  storesSubtitle:
    'Provide digital menu or receive online orders via stores (i.e. Burger King)',
  notReceivingOrders: 'Not Receiving Orders',
  receiveOrders: 'Receive Orders',
  open: 'Open',
  closed: 'Closed',
  branchName: 'Branch Name',
  branchPlaceHolder: 'Downtown',
  storeTypeRequired: 'Store type is required',
  settingsEmpty: 'Once you create the store, you can modify the settings',
  goBack: 'Go Back',
  emptyStoresPlaceHolder: 'Create your first store and start adding menu items',
  allBranches: ' All Branches',
  aboutIsRequired: 'About is required for',
  nameIsRequired: 'Name is required for',
  unLinkStore: 'Unlink Store',
  removeStore: 'Remove Store',
  hideCoupon: 'Hide coupon',
  cancelMessage:
    'If you cancel, all of your unsaved changes will be lost. Are you sure?',
  saveAndClose: 'Save & Close',
  updateAndClose: 'Update & Close',
  saveAndExit: 'Save & Exit',
  updateAndExit: 'Update & Exit',
  saveAndContinue: 'Save & Continue',
  updateAndContinue: 'Update & Continue',
  negativeTaxMessage: 'Minimum allowed tax percentage is zero',
  taxPercentage: 'Tax percentage',
  invalidPhoneNumber: 'Phone number is invalid',
  setWorkingHours: 'Set store working hours',
  setup: 'Set up',
  deleteTableMessage: 'Are you sure you want to delete table {{tableNumber}}',
  negativeTableNoMessage: 'Table number must be greater then zero',
  negativeTableSizeMessage: 'Table size must be greater then zero',
  addTable: 'Add table',
  deliveryManagement: 'Delivery Management',
  negativeNotAllowed: 'This value cannot be less then zero',
  enterLocation: 'Enter location',
  unSavedChanges:
    'Looks like you have some unsaved changes. If you leave the page, all of those changes will be lost. Do you wish to continue?',
  mustHavePaymentMethod: 'You must have at least one payment method selected',
  hideStore: 'Hide store',
  hideStoreDescription:
    'When turned on, This store will not be visible to customers if they are going to the link where they can select a store. They can still access the store if the store link is accessed',
  enableAssignCourier: 'Enable Assign Courier',
  enableCourierDesc:
    "When enabled, You'd be able to assign drivers to your delivery orders in the POS app.",
  viewDrivers: 'To view/edit drivers',
};

export const ar: typeof en = {
  enableCourierDesc:
    'عند تفعيل الخاصية، بإمكانك تعيبن مناديب خاصين بك لكل طلب توصيل عن طريق التطبيق Foodbit POS أو عن طريق الداشبورد',
  viewDrivers: 'عرض السائقين',
  enableAssignCourier: 'خاصية تعيبن المناديب',
  hideStoreDescription:
    'عند تفعيل هذا الخيار، سيتم اخفاء المتجر من العميل من خلال الرابط الذي يحتوي على جميع المتاجر. بإمكان العميل الدخول على رابط المتجر نفسه',
  hideStore: 'اخفاء المتجر',
  mustHavePaymentMethod: 'لابد من اختيار طريق دفع واحدة كحد أدنى',
  unSavedChanges:
    'يبدو أن لديك بعض التغييرات غير المحفوظة. إذا غادرت الصفحة ، فستفقد كل هذه التغييرات. هل ترغب في الاستمرار؟',
  enterLocation: 'إدخال العنوان',
  negativeNotAllowed: 'لا يمكن أن تكون هذه القيمة أقل من الصفر',
  deliveryManagement: 'إدارة التوصيل',
  addTable: 'اضف طاولة',
  negativeTableSizeMessage: 'يجب أن يكون عدد الأشخاص أكبر من الصفر',
  negativeTableNoMessage: 'يجب أن يكون عدد الأشخاص أكبر من الصفر',
  deleteTableMessage: 'هل متأكد من حذف الطاولة: {{tableNumber}}',
  setup: 'اعدادات',
  setWorkingHours: 'تحديد ساعات عمل المتجر',
  invalidPhoneNumber: 'الرقم خطأ',
  taxPercentage: 'نسبة القيمة المضافة',
  negativeTaxMessage: 'الحد الأدنى لنسبة القيمة المضافة المسموح بها هو صفر',
  saveAndContinue: 'احفظ والتالي',
  updateAndContinue: 'احفظ وتقدم للتالي',
  saveAndExit: 'احفظ وانهي',
  updateAndExit: 'حدث وانهي',
  saveAndClose: 'احفظ واغلق',
  updateAndClose: 'حدث واغلق',
  cancelMessage: 'إلغاء',
  hideCoupon: 'اخفاء خانة الكوبون',
  removeStore: 'حذف المتجر',
  unLinkStore: 'ازالة ربط المتجر',
  aboutIsRequired: 'النبذه مطلوبة ل',
  nameIsRequired: 'الأسم مطلوب ل',
  location: 'العنوان',
  Timezone: 'الوحدة الزمنية',
  stores: 'المتاجر',
  store: 'متجر',
  city: 'المدينة',
  startTime: 'بداية العمل',
  goBack: 'الرجوع للخلف',
  categoryDeleted: 'تم حذف القسم بنجاح',
  settingsEmpty: 'عند انِشأ المتجر، بإمكانك تحديث الإعدادات',
  storeTypeRequired: 'لابد من إدخال نوع المتجر',
  phoneNumberRequired: 'لابد من إدخال رقم الهاتف',
  categoryUpdated: 'تم تحديث القسم',
  allBranches: 'جميع المتاجر',
  categoryNotDeleted: 'لايمكن حذف القسم',
  tables: 'الطاولات',
  categorySuccess: 'تم حذف القسم',
  categoryError: 'لايمكن اضافة القسم',
  categoryNotUpdated: 'لايمكن تحديث القسم',
  endTime: 'نهاية العمل',
  country: 'الدولة',
  useGlobalSettings: 'استخدم الأعدادت العامة',
  globalSettingsHelper: 'اذا تم تفعيل هذا الأختيار، سيتم استخدام',
  globalSettingsRedirect: 'الأعدادات العامة',
  createStore: 'اضف متجر',
  phoneNumber: 'رقم الهاتف',
  editStore: 'تعديل المتجر',
  options: 'الخيارات',
  addPOS: 'اضف منصة طلبات',
  save: 'احفظ',
  neighbourhood: 'المقاطعة',
  acceptingOrders: 'استلام طلبات',
  notAcceptingOrders: 'لا يستلم طلبات',
  settings: 'الأعدادات',
  edit: 'تعديل',
  main: 'ألاساسية',
  storeHours: 'ساعات العمل',
  noDeviceMsg:
    'لايوحد منصة طلبات لهذا المتجر. لاستلام طلبات، لابد من اضافة منصة الطلبات',
  storeType: 'نوع المتجر',
  coffeeShop: 'قهوة',
  restaurant: 'مطعم',
  aboutPlaceHolder: 'مطعم ايطالي',
  onlineCoffeeShop: 'مقهى اونلاين',
  onlineRestaurant: 'مطعم اون لاين',
  foodTruck: 'فود ترك',
  coffeeShopTruck: 'كوفي شوب ترك',
  either: 'اما',
  searchLocation: 'ابحث عن العنوان',
  locateMe: 'حدد موقعي',
  addLocation: 'اضف عنوان',
  storeUpdatedSuccessfully: 'تم تحديث المتجر بنجاح',
  hideLocation: 'اخفاء عنوان المتجر من العميل',
  hideLocationDesc:
    'هذا الخيار مناسب للمنشاًت الناتجة من مواقع خاصة مثل مقر السكن',
  deleteStoreMsg: 'سيتم حذف هذا المتجر بشكل نهائي من النظام',
  storeDeletedSuccessfully: 'تم حذف المتجر',
  storeAddedSuccessfully: 'تم اضافة المتجر بنجاح',
  mustEnterLocation: 'الرجاء ادخال مقر أو عنوان للمتجر',
  storesSubtitle: 'قدم منيو الكتروني أو استلم طلبات عن طريق المتاجر',
  notReceivingOrders: 'لايستلم طلبات',
  receiveOrders: 'استلام الطلبات',
  open: 'مفتوح',
  closed: 'مغلق',
  branchName: 'اسم الفرع',
  branchPlaceHolder: 'العليا',
  emptyStoresPlaceHolder: 'قم بإنشأ متجرك وثم اضف معلومات قائمة الأصناف',
  tableDesc: 'عدد الطاولات في المتجر',
};
