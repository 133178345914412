import Api from 'src/api/Api';
import { create } from 'zustand';

interface AuthProps {
  isLoggedIn: boolean;
  setLoggedIn: (access_token: string) => void;
  setIsAdmin: (isAdmin: boolean) => void;
  isAdmin: boolean;
  token?: string;
}

const { isLoggedIn, token } = Api.isUserLoggedIn();

export const [useAuthStore] = create<AuthProps>(set => ({
  isLoggedIn: isLoggedIn,
  token: token?.access_token ?? '',
  isAdmin: token?.is_super_admin ? true : false,
  setIsAdmin: (isAdmin: boolean) => set({ isAdmin }),
  setLoggedIn: access_token => set({ isLoggedIn: true, token: access_token }),
}));
