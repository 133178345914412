import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as accountStrings from 'src/account/assets/strings';
import * as commonStrings from 'src/common/assets/strings';
import * as couponsTranlations from 'src/customerLoyalty/coupons/assets/strings';
import * as discountsTranslations from 'src/customerLoyalty/discounts/assets/strings';
import * as customersStrings from 'src/customers/assets/strings';
import * as deviceStrings from 'src/devices/assets/strings';
import * as driversStrings from 'src/drivers/assets/strings';
import * as feedbackStrings from 'src/feedback/assets/strings';
import * as forgotPasswordStrings from 'src/forgot-password/assets/strings';
import { languageDetector } from 'src/i18n/languageDetector';
import * as insightStrings from 'src/insights/assets/strings';
import * as integrationStrings from 'src/integrations/assets/strings';
import * as linksQrStrings from 'src/links-qrcodes/assets/strings';
import * as menuStrings from 'src/menu/assets/strings';
import * as onlineMenuStrings from 'src/online-menu/assets/strings';
import * as ordersStrings from 'src/orders/assets/strings';
import * as paymentStrings from 'src/payment/assets/strings';
import * as quickSightStrings from 'src/quicksight/assets/strings';
import * as resetStrings from 'src/reset-password/assets/strings';
import * as settingsStrings from 'src/settings/assets/strings';
import * as signupStrings from 'src/signup/assets/strings';
import * as storesStrings from 'src/stores/assets/strings';
import * as translationsStrings from 'src/translations/assets/strings';

export const defaultNS = 'common';

export const resources = {
  en: {
    insights: insightStrings.en,
    onlineMenu: onlineMenuStrings.en,
    menu: menuStrings.en,
    orders: ordersStrings.en,
    customers: customersStrings.en,
    devices: deviceStrings.en,
    integrations: integrationStrings.en,
    common: commonStrings.en,
    stores: storesStrings.en,
    signup: signupStrings.en,
    'links-qr': linksQrStrings.en,
    settings: settingsStrings.en,
    translations: translationsStrings.en,
    payment: paymentStrings.en,
    account: accountStrings.en,
    forgotPassword: forgotPasswordStrings.en,
    resetPassword: resetStrings.en,
    feedback: feedbackStrings.en,
    quickSight: quickSightStrings.en,
    coupons: couponsTranlations.en,
    discounts: discountsTranslations.en,
    drivers: driversStrings.en,
  },
  ar: {
    insights: insightStrings.ar,
    onlineMenu: onlineMenuStrings.ar,
    menu: menuStrings.ar,
    orders: ordersStrings.ar,
    customers: customersStrings.ar,
    devices: deviceStrings.ar,
    integrations: integrationStrings.ar,
    common: commonStrings.ar,
    stores: storesStrings.ar,
    signup: signupStrings.ar,
    'links-qr': linksQrStrings.ar,
    settings: settingsStrings.ar,
    translations: translationsStrings.ar,
    payment: paymentStrings.ar,
    account: accountStrings.ar,
    forgotPassword: forgotPasswordStrings.ar,
    resetPassword: resetStrings.ar,
    feedback: feedbackStrings.ar,
    quickSight: quickSightStrings.ar,
    coupons: couponsTranlations.ar,
    discounts: discountsTranslations.ar,
    drivers: driversStrings.ar,
  },
} as const;

export const init = () =>
  i18next
    .use(languageDetector)
    .use(initReactI18next)
    .init({
      ns: ['common', 'insights', 'onlineMenu', 'menu'],
      fallbackLng: 'en',
      resources: {
        defaultNS: { ar: resources.ar.common, en: resources.en.common },
        ...resources,
      },
      defaultNS,
    });
