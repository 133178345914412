import {
  ArchiveBoxArrowDownIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  DevicePhoneMobileIcon,
  HomeIcon,
  LinkIcon,
  Squares2X2Icon,
} from '@heroicons/react/24/outline';
import { t } from 'src/i18n/translate';

export interface SideMenuLink {
  name: string;
  href?: string;
  icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  children?: SideMenuLink[];
  component?: any;
}

export const ROUTES = {
  STORES: '/',
  ITEMS: '/items',
  ADD_STORE: '/add-store',
  EDIT_STORE: '/edit-store',
  ADD_MENU: '/add-menu',
  EDIT_MENU: '/edit-menu',
  EDIT_OPTION: '/edit-option',
  EDIT_ITEM: '/edit-item',
  EDIT_CATEGORY: '/edit-category',
  ADD_ITEM: '/add-item',
  OPTIONS: '/options',
  ADD_OPTIONـSET: '/add-optionset',
  EDIT_OPTIONSET: '/edit-optionset',
  OPTION_SETS: '/option-sets',
  ADD_CATEGORY: '/add-category',
  CATEGORIES: '/categories',
  MENUS: '/menus',
  ORDERS: '/orders',
  DEVICES: '/devices',
  EDIT_DEVICE: '/edit-device',
  ADD_OPTION: '/add-option',
  INSIGHTS: '/insights',
  CUSTOMERS: '/customers',
  SETTINGS: '/settings',
  LINKS: '/links',
  ONLINE_MENU: '/online-menu',
  FEEDBACK: '/feedback',
  CUSTOMER_DETAILS: 'customer-details',
  COUPONS: '/coupons',
  ADD_COUPON: '/add-coupon',
  DISCOUNTS: '/discounts',
  ADD_DISCOUNT: '/add-discount',
  CUSTOMER_LOYALTY: '/customer-loyalty',
  INTEGRATIONS_REDIRECT: '/integrations/:integrationId/redirect',
  PAYMENT: '/payment',
  PAYMENT_SUCCESS: '/payment-success',
  SIGNUP: '/signup',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  NOT_FOUND: '*',
};

export const ROUTECOMPONENTS = {
  ADD_STORE: () => import('src/stores/components/AddStorePage/AddStorePage'),
  EDIT_STORE: () => import('src/stores/components/EditStorePage/EditStorePage'),
  ADD_ITEM: () =>
    import('src/menu/menu-item/components/add-menuitem-page/AddMenuItemPage'),
  EDIT_ITEM: () =>
    import('src/menu/menu-item/components/edit-menuitem-page/EditMenuItemPage'),
  ADD_MENU: () => import('src/menu/add-menu/AddMenuPage'),
  EDIT_MENU: () => import('src/menu/edit-menu/EditMenuPage'),
  ADD_OPTION: () => import('src/menu/options-page/add-option-page'),
  EDIT_OPTION: () => import('src/menu/options-page/edit-option-page'),
  ADD_OPTIONـSET: () =>
    import('src/menu/option-sets/components/AddOptionSetPage'),
  EDIT_OPTION_SET: () =>
    import('src/menu/option-sets/components/EditOptionSetPage'),
  ADD_CATEGORY: () =>
    import('src/menu/menu-category/components/AddCategoryPage'),
  EDIT_CATEGORY: () => () =>
    import(
      'src/menu/menu-category/components/edit-category-page/EditCategoryPage'
    ),
  COUPONS: () => () =>
    import('src/customerLoyalty/coupons/components/CouponsPage'),
};

export const NAVIGATIONS: SideMenuLink[] = [
  { name: `${t('stores:stores')}`, href: ROUTES.STORES, icon: HomeIcon },
  {
    name: t('common:menu'),
    children: [
      { name: t('menu:items'), href: ROUTES.ITEMS },
      { name: t('menu:options'), href: ROUTES.OPTION_SETS },
      { name: t('menu:categories'), href: ROUTES.CATEGORIES },
      { name: t('menu:menus'), href: ROUTES.MENUS },
    ],
  },
  {
    name: t('orders:orders'),
    href: ROUTES.ORDERS,
    icon: ArchiveBoxArrowDownIcon,
  },
  {
    name: t('devices:posLogins'),
    href: ROUTES.DEVICES,
    icon: DevicePhoneMobileIcon,
  },
  {
    name: t('common:insights'),
    href: ROUTES.INSIGHTS,
    icon: ChartPieIcon,
  },
  {
    name: t('customers:customers'),
    href: ROUTES.CUSTOMERS,
    icon: Squares2X2Icon,
  },
  { name: t('settings:settings'), href: ROUTES.SETTINGS, icon: Cog6ToothIcon },
  { name: t('links-qr:links'), href: ROUTES.LINKS, icon: LinkIcon },
  { name: t('common:app'), href: ROUTES.ONLINE_MENU, icon: Squares2X2Icon },
];
