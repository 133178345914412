import { useMutation, useQueryClient } from 'react-query';
import Api from 'src/api/Api';
import { ReactQueryKey } from 'src/common/enums';
import { useAlertStore } from 'src/common/hooks/useGlobalAlert';
import { t } from 'src/i18n/translate';

export const useUpdateMerchant = () => {
  const { setAlert } = useAlertStore();

  const queryCache = useQueryClient();

  const { mutate: updateMerchant, isLoading: isUpdatingMerchant } = useMutation(
    Api.updateMerchantPatch,
    {
      onSuccess: () => {
        setAlert('success', t('settings:settingsUpdated'));
        queryCache.invalidateQueries(ReactQueryKey.Account);
      },
      onError: () => {
        setAlert('error', t('settings:settingsNotUpdated'));
      },
    },
  );

  return {
    updateMerchant,
    isUpdatingMerchant,
  };
};
