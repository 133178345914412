import { ChevronDoubleLeftIcon } from '@heroicons/react/20/solid';
import { MouseEventHandler } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useCurrentLanguage } from 'src/i18n/hooks/useCurrentLanguage';
import { isRTL } from 'src/translations/utils/languageUtils';

import { useHeaderInfo } from './hooks/useHeaderInfo';
import { useHistoryStack } from './hooks/useHistoryStack';

export const Header = () => {
  const headerInfo = useHeaderInfo();

  const navigate = useNavigate();
  const stack = useHistoryStack();

  const { language } = useCurrentLanguage();

  const isRtl = isRTL(language);

  const { title, CTAs, action, component, href, subTitle, backButtonProps } =
    headerInfo ?? {};

  const { isVisible, onClick: onClickBack, type } = backButtonProps ?? {};

  const onBack: MouseEventHandler<HTMLButtonElement> = e =>
    onClickBack ? onClickBack(e) : navigate(-1);

  if (!headerInfo) {
    return null;
  }

  return (
    <div className="bg-white py-4 sm:py-6 shadow">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
        <div className="px-4 bg-white sm:px-6 lg:px-8">
          <div
            className="sm:flex sm:items-center gap-4"
            dir={isRtl ? 'rtl' : 'ltr'}>
            <div className="sm:flex-auto">
              <div className="flex items-center">
                {isVisible && stack.length > 0 && (
                  <button
                    onClick={type === 'submit' ? undefined : onBack}
                    className="inline-flex items-center self-start mt-1"
                    {...(backButtonProps ?? {})}>
                    <ChevronDoubleLeftIcon
                      className="ltr:-ml-5 rtl:-mr-5 sm:ltr:-ml-7 md:ltr:-ml-8 sm:rtl:-mr-7 md:rtl:-mr-8 h-5 w-5 text-green-400 rtl:rotate-180 "
                      aria-hidden="true"
                    />
                  </button>
                )}
                <div>
                  <h1 className="text-xl font-semibold text-gray-900">
                    {title}
                  </h1>
                  {subTitle && (
                    <p className="mt-2 text-sm text-gray-700">{subTitle}</p>
                  )}
                </div>
              </div>
            </div>
            {action && href ? (
              <div className=" mt-4 sm:mt-0 ltr:sm:ml-16 sm:flex-none">
                <Link
                  onMouseEnter={component}
                  to={href}
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-green-400 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:w-auto">
                  {action}
                </Link>
              </div>
            ) : null}
            {CTAs}
          </div>
        </div>
      </div>
    </div>
  );
};
