import QRCode from 'qrcode';
import { useEffect, useState } from 'react';
import { Images } from 'src/common/assets/images';

export const useGenerateQrCode = (url: string) => {
  const [data, setData] = useState('');
  const generateQR = async (text: string) => {
    try {
      const canvas = await QRCode.toCanvas(text, { margin: 1, scale: 8 });
      //adding a log at center
      // const imgDim = { width: 110, height: 40 }; //logo dimention
      // const context = canvas.getContext('2d');
      const imageObj = new Image();
      imageObj.src = Images.qrLogo;
      imageObj.onload = () => {
        // context.drawImage(
        //   imageObj,
        //   canvas.width / 2 - imgDim.width / 2,
        //   canvas.height / 2 - imgDim.height / 2,
        //   imgDim.width,
        //   imgDim.height,
        // );
        setData(canvas.toDataURL());
      };

      // setTimeout(() => {}, 2000);
    } catch (err) {
      console.error('QR Code', err);
    }
  };

  useEffect(() => {
    if (url) {
      generateQR(url);
    }
  }, [url]);

  return data;
};
