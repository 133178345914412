import {
  DetailedHTMLProps,
  HTMLAttributes,
  MouseEventHandler,
  ReactNode,
} from 'react';

interface Props
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: ReactNode | ((props: { id: string }) => ReactNode);
  id: string;
  handleOpen?: MouseEventHandler<HTMLDivElement>;
  open?: string;
}

const Collapsed = ({ children, handleOpen, id, open, ...props }: Props) => (
  <div
    {...props}
    onClick={e => {
      handleOpen?.(e);
      props.onClick?.(e);
    }}
    data-id={id}>
    {typeof children === 'function' ? children({ id: open }) : children}
  </div>
);

export default Collapsed;
