import React, { ComponentType, ReactNode, Suspense } from 'react';
import TopBarProgress from 'react-topbar-progress-indicator';

TopBarProgress.config({
  barColors: {
    0: '#0BD19F',
  },
  barThickness: 5,
});

export const withSuspense = <T extends ComponentType<any>>(
  WrappedComponent: T,
  props?: React.ComponentProps<T>,
  fallback: ReactNode = <TopBarProgress />,
) => (
  <Suspense fallback={fallback}>
    <WrappedComponent {...props} />
  </Suspense>
);
