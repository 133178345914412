import { ComponentType } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { PageLayout } from 'src/PageLayout';
import { withSuspense } from 'src/PageLayout/withSuspense';
import { Search } from 'src/search';

import { usePrivateRoutes, usePublicRoutes } from './useRoutes';

export const AppRouter = () => {
  const privateRoutes = usePrivateRoutes();

  const publicRoutes = usePublicRoutes();

  return (
    <Router>
      <Search />
      <Routes>
        <Route path="/" element={<PageLayout />}>
          {privateRoutes.map(route => (
            <Route
              {...route}
              element={withSuspense(route.element as ComponentType<any>)}
              key={route.path}
            />
          ))}
        </Route>

        {publicRoutes.map(route => (
          <Route
            {...route}
            key={route.path}
            element={withSuspense(route.element as ComponentType<any>)}
          />
        ))}
      </Routes>
    </Router>
  );
};
