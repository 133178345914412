import { ChangeEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { Images } from 'src/common/assets/images';
import { useAlertStore } from 'src/common/hooks/useGlobalAlert';
import { useGoogleTranslate } from 'src/translations/hooks/useGoogleTranslate';
import { Language } from 'src/translations/uiTypes';
import { isRTL } from 'src/translations/utils/languageUtils';

import { StyledInput } from '../Inputs/StyledInput';
import { Loader } from '../Loader';

interface Props {
  language: Language;
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  error?: string;
  entityName?: string;
  googleTranslateProps?: {
    sourceContent: string;
    onTranslationSuccess: (params: {
      language: Language;
      translation: string;
    }) => void;
  };
}

export const TranslationInput = ({
  language,
  onChange,
  value,
  error,
  entityName,
  googleTranslateProps,
}: Props) => {
  const { t } = useTranslation();

  const { setAlert } = useAlertStore();

  const { mutate: tranlsate, isLoading } = useGoogleTranslate();

  const onClickGoogleTranslate = () => {
    if (googleTranslateProps) {
      const { onTranslationSuccess, sourceContent } = googleTranslateProps;

      if (!sourceContent) {
        setAlert('error', t('translations:addContentInSource'));
        return;
      }

      tranlsate(
        { content: [sourceContent], target: language.code },
        {
          onSuccess: res => {
            if (res.error) {
              setAlert('error', t('common:genericError'));
              return;
            }

            onTranslationSuccess({
              language,
              translation: res.translations[0]?.translatedText,
            });
          },
        },
      );
    }
  };

  return (
    <div className="flex flex-col gap-2 p-4 sm:px-6 bg-gray-100 mt-4 rounded shadow-sm">
      <div className="flex items-end justify-between">
        <label
          htmlFor={language?.code}
          className="text-sm font-medium text-gray-700 text-left rtl:text-right">
          {entityName || t('translations:translation')} ({language?.name} -{' '}
          {language?.nativeName})
        </label>
        {googleTranslateProps && (
          <button
            onClick={onClickGoogleTranslate}
            disabled={isLoading}
            className="px-4 py-2 bg-green-50 hover:bg-green-100 border border-green-500 rounded disabled:cursor-not-allowed flex items-center gap-2"
            type="button">
            {isLoading ? (
              <Loader loaderClassName="w-5 h-5" />
            ) : (
              <img
                src={Images.googleTranslate}
                className="w-5 h-5 object-contain"
              />
            )}
            <span className="text-green-500">
              {t('translations:translate')}
            </span>
          </button>
        )}
      </div>
      <StyledInput
        type="text"
        id={language?.code}
        name={language?.code}
        value={value}
        onChange={onChange}
        onBlur={onChange}
        className="px-4 py-2"
        dir={isRTL(language?.code) ? 'rtl' : 'ltr'}
      />
      <p className="text-red-500 ltr:text-left rtl:text-right">{error ?? ''}</p>
    </div>
  );
};
