export const en = {
  dashboardSignup: 'Dashboard Signup',
  signup: 'Signup',
  login: 'Login',
  name: 'Name',
  nameRequired: 'Name is required',
  namePlaceholder: 'Enter Name..',
  signupSuccessMessage: 'Signup Success! now you can login.',
  fillYourDetails: 'Please Fill in your details',
  alreadyRegistered: 'Already registered?',
  emailTaken: 'Email Already Taken',
  phoneNumberTaken: 'Phone Number Already Taken',
  usernameTaken: 'Username Already Taken',
  signupIn: 'Signup in',
  lessThan2Mints: 'less than 2 minutes',
  businessInfo: 'Enter Business Info',
  businessUrl: 'Choose a URL for Your Business',
  headline:
    ' Few steps away from giving your customers a delightful experience',
  firstName: 'First Name *',
  firstNamePlaceholder: 'Enter First Name..',
  firstNameRequired: 'First Name is required',
  lastName: 'Last Name',
  lastNamePlaceholder: 'Enter Last Name..',
  phoneNumberRequired: 'Phone Number is required',
  emailAddress: 'Email Address *',
  emailAddressPlaceholder: 'Enter Email Address..',
  emailRequired: 'Email is required',
  username: 'Username *',
  usernameFourCharacters: 'Username must be 4 characters long',
  usernameThirtyCharacters: 'Username can only be 30 characters long',
  businessNameThirtyCharacters: 'Business Name can only be 30 characters long',
  usernamePlaceholder: 'Enter Username..',
  usernameRequired: 'Username is required',
  password: 'Password *',
  passwordCharacter: 'Password must be 8 characters long.',
  passwordPlaceholder: 'Enter Password..',
  passwordRequired: 'Password is required',
  businessName: 'Business Name *',
  businessNamePlaceholder: 'Enter Business Name..',
  businessNameRequired: 'Business Name is required',
  countryPlaceholder: 'Select Country i.e. USA',
  noSpaceAllowed: 'No spaces are allowed',
  emailPattern: 'Entered value does not match email format',
  linkPattern: 'Entered value does not match required format',
  urlRequired: 'URL is required',
  formStepOne: `Let's Get Started`,
  formStepTwo: 'Your Info',
  formStepThree: 'Email And Password',
  formStepFour: 'Merchant URL',
  imageTextHeadingOne: `Table Mobile Ordering`,
  imageTextHeadingTwo: 'Table Payments',
  imageTextHeadingThree: 'Insights',
  imageTextHeadingFour: 'Merchant URL',
  imageSubHeadingTextOne: `85% of diners prefer ordering via Mobile. Join the movement.`,
  imageSubHeadingTextTwo: `Your diners do not have to wait for the check anymore`,
  imageSubHeadingTextThree: `Identify your diners and discover insights that boost your sales`,
  imageSubHeadingTextFour: `This will be will be your main `,
  signin: 'Sign in',
  signinToAccount: 'Sign in to your account',
  or: 'Or',
  becomeNewMember: 'Become a new member',
  forgotPassword: 'Forgot password?',
  emailUsername: 'Email address / Username',
};

export const ar: typeof en = {
  formStepOne: 'مرحباً، ابدأ بتسجيل حسابك',
  formStepTwo: 'معلوماتك الشخصية',
  formStepThree: 'البريد الالكتروني ورمز الدخول',
  formStepFour: 'الرابط الأساسي لمنشأتك',
  imageTextHeadingOne: 'اطلب من الطاولة',
  imageTextHeadingTwo: 'الدفع عن طريق الجوال',
  imageTextHeadingThree: 'تحليل البيانات',
  imageTextHeadingFour: 'رابط المنشأة',
  imageSubHeadingTextOne:
    '٨٥٪؜ من عملاء المطاعم يفضلون الطلب ذاتياً عن طريق الجوال',
  imageSubHeadingTextTwo:
    'بإمكان عملائك الدفع المباشر من الطاولة بدون انتظار الفاتورة',
  imageSubHeadingTextThree: 'ارفع مبيعاتك واكتشف العملاء الأولياء',
  imageSubHeadingTextFour: 'سيكون هذا هو رابط منشأتك الأساسي',
  name: 'الأسم',
  emailPattern: 'القيمة المدخلة لاتطابق صيغة البريد الالكتروني',
  urlRequired: 'الرابط مطلوب',
  linkPattern: 'القيمة المدخلة لاتطابق صيغة الرابط',
  namePlaceholder: 'ادخل الإسم...',
  nameRequired: 'الأسم مطلوب',
  dashboardSignup: 'الاشتراك في لوحة التحكم',
  signup: 'الأشتراك',
  login: 'سجل الدخول',
  noSpaceAllowed: 'عفوا،  الفراغات غير مسموحة',
  signupSuccessMessage: 'تم الأشتراك بنجاح',
  fillYourDetails: 'الرجاء ادخال معلوماتك',
  alreadyRegistered: 'لديك حساب؟',
  usernameFourCharacters: 'اسم تسجيل الدخول لابد ان يحتوي على ٤ حروف على الأقل',
  usernameThirtyCharacters: 'اسم تسجيل الدخول لابد ان يحتوي على اقل من ٣٠ حرف',
  businessNameThirtyCharacters: 'اسم المنشأة  لابد ان يحتوي على اقل من ٣٠ حرف',
  businessName: 'اسم المنشأة أو الشركة',
  countryPlaceholder: 'اختر الدولة',
  businessNamePlaceholder: 'مشويات الممكلة',
  businessNameRequired: 'لابد من ادخل اسم المنشأة',
  passwordRequired: 'لابد من ادخل اسم الرمز السري',
  passwordPlaceholder: 'ادخل الرمز السري',
  passwordCharacter: 'الحد الأدنى للرمز السري هو ٨',
  password: 'الرمز السري',
  usernamePlaceholder: 'الرجاء ادخل اسم المستخد بالانجليزي',
  usernameRequired: 'اسم المستخدم (انجليزي)',
  firstName: 'الاسم *',
  firstNamePlaceholder: 'ماجد',
  firstNameRequired: 'لابد من ادخل الاسم',
  lastName: 'اسم العائلة',
  lastNamePlaceholder: 'الماجد',
  phoneNumberRequired: 'لابد من ادخل رقم الجوال',
  emailTaken: 'تم استخدام البريد الالكتروني من قبل',
  phoneNumberTaken: 'تم استخدام رقم الجوال من قبل',
  emailAddress: 'البريد الالكتروني',
  emailAddressPlaceholder: 'majed@majed.com',
  emailRequired: 'لابد من ادخال البريد الالكتروني',
  username: 'اسم المستخدم',
  usernameTaken: 'تم استخدام اسم المستخدم من قبل',
  signupIn: 'تسجيل حساب',
  lessThan2Mints: 'اقل من دقيقتين',
  businessInfo: 'الرجاء ادخل معلوماتك',
  businessUrl: 'رابط المتجر',
  headline: 'خطوات بسيطة وسيكون لك متجر خاص يوفر لعملائك تجربة استثنائية',
  signin: 'تسجيل الدخول',
  signinToAccount: 'سجل الدخول لحسابك',
  or: 'أو',
  becomeNewMember: 'اشترك وانِشأ حساب جديد',
  forgotPassword: 'نسيت كلمة المرور',
  emailUsername: 'الأيميل أو اسم المستخدم',
};
