import React from 'react';
import { useTranslation } from 'react-i18next';
import { getLanguageDir } from 'src/i18n/getLanguageDir';
import { RtlThemeProvider } from 'src/theme/RtlThemeProvider';
import { createTheme } from 'src/theme/theme';

export const withTheme =
  <P extends object>(Comp: React.ComponentType<P>) =>
  (props: P) => {
    useTranslation();

    const direction = getLanguageDir();

    const theme = createTheme({
      direction,
      overrides: {
        MuiCssBaseline: {
          '@global': {
            body: {
              flip: false as any, // don't allow RtlTheme to flip
              direction,
            },
          },
        },
      },
    });

    return (
      <RtlThemeProvider theme={theme}>
        <Comp {...props} />
      </RtlThemeProvider>
    );
  };
