import { BuildingStorefrontIcon } from '@heroicons/react/24/outline';
import { labelOrDefault } from 'src/common/utils/labelLanguageUtils';
import { useCurrentLanguage } from 'src/i18n/hooks/useCurrentLanguage';
import { useFetchLinksV2 } from 'src/links-qrcodes/hooks/useFetchLinksV2';

import { Link } from './Link';

export const MenuLinks = () => {
  const { data, isLoading } = useFetchLinksV2();

  const { language } = useCurrentLanguage();

  const { menu = [] } = data ?? {};

  return (
    <div className="border border-t-0 rounded-b flex">
      <div className="w-0 sm:w-[76px]" />
      <div className="flex-1 flex flex-col gap-2 sm:gap-4 pb-4">
        {isLoading &&
          [1, 2].map(idx => (
            <div className="animate-pulse border-t w-full" key={idx.toString()}>
              <div className="px-2 sm:px-4 py-4 border-b bg-white w-full">
                <div className="gap-2 flex ">
                  <div className="rounded bg-gray-100 w-6 h-4" />
                  <div className="rounded bg-gray-100 w-full h-4" />
                  <div className="rounded bg-gray-100 w-6 h-4" />
                  <div className="rounded bg-gray-100 w-6 h-4" />
                  <div className="rounded bg-gray-100 w-6 h-4" />
                </div>
              </div>
            </div>
          ))}
        {menu.map(link => (
          <div
            className="border border-[#52C41A] rounded overflow-hidden flex-1 mx-2 sm:mx-4"
            key={link.url}>
            <div className="flex items-center gap-2 px-2 sm:px-4 py-2 bg-[#eafad4] text-[#52C41A] rounded">
              <BuildingStorefrontIcon className="w-4 h-4" />
              <span className="text-sm font-semibold text-gray-700">
                {labelOrDefault(link.target?.name, language)}
              </span>
            </div>
            <Link link={link} key={link.url} />
          </div>
        ))}
      </div>
    </div>
  );
};
