import { SnackbarOrigin } from '@material-ui/core';
import { create } from 'zustand';

export type AlertType = 'success' | 'error' | 'warning' | 'info';

export interface AlertProps {
  alertIsOpen: boolean;
  alertType?: AlertType;
  alertMessage?: string | undefined;
  alertTitle?: string | undefined;
  closeAlert?: () => void;
  autoHide?: boolean;
  hideClose?: boolean;
  anchorOrigin?: SnackbarOrigin;
  setAlert: (
    type: AlertType,
    message: string | undefined,
    title?: string | undefined,
    isInternal?: boolean,
  ) => void;
}

export const [useAlertStore] = create<AlertProps>(set => ({
  alertType: undefined,
  alertIsOpen: false,
  alertTitle: undefined,
  alertMessage: '',

  setAlert: (type, message, title) =>
    set({
      alertIsOpen: true,
      alertType: type,
      alertMessage: message,
      alertTitle: title,
    }),
  closeAlert: () =>
    set({
      alertIsOpen: false,
      alertMessage: undefined,
      alertTitle: undefined,
    }),
}));
