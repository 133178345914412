import { StylesConfig, Theme } from 'react-select';

import { Option } from '.';

type IsMulti = boolean;

export const selectStyles: StylesConfig<Option, IsMulti> = {
  control: (defaultStyles, { isDisabled }) => ({
    ...defaultStyles,
    ':active': {
      ...defaultStyles[':active'],
    },
    ':hover': {
      ...defaultStyles[':hover'],
      // gray-100
      borderColor: '#d1d5db',
    },
    borderColor: '#d1d5db',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    boxShadow: defaultStyles.boxShadow
      ? // green-400
        defaultStyles.boxShadow.slice(0, -7) + '#42CFB9'
      : '0 0 0 0px #42CFB9',
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    //bg-green-50
    backgroundColor: isSelected ? '#F0FFFB' : 'transparent',
    color: isSelected ? '#42CFB9' : 'black',
    cursor: 'pointer',
    ':hover': {
      ...styles[':hover'],
      // bg-gray-50
      backgroundColor: isSelected ? '#F0FFFB' : '#f9fafb',
    },
  }),
  menuPortal: styles => ({ ...styles, zIndex: 60 }),
};

export const theme = (theme_: Theme) => ({
  ...theme_,
  borderRadius: 4,
  colors: {
    ...theme_.colors,
    primary: '#20C3AF',
  },
});
