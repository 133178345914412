import SafaryAnalytics from '../mixpanel-analytics/SafaryAnalytics';
import { Dict } from 'mixpanel-browser';
import { useCallback } from 'react';
import { Event } from './Event';

export const useAnalytics = () => {
  const logEvent = useCallback(
    (event: Event, params: Dict = {}) =>
      SafaryAnalytics.logEvent(event, params),
    [],
  );

  const registerSuperProperties = useCallback(
    (params: Dict = {}) => SafaryAnalytics.registerSuperProperties(params),
    [],
  );

  const signupCustomer = useCallback(
    (params: string) => SafaryAnalytics.signup(params),
    [],
  );

  const loginCustomer = useCallback(
    (params: string) => SafaryAnalytics.login(params),
    [],
  );

  return { logEvent, registerSuperProperties, loginCustomer, signupCustomer };
};
