import { Label } from 'src/stores/apiTypes';

import { useTranslationLanguages } from './useTranslationLanguages';

export const useValidateLabel = () => {
  const { activeLanguages } = useTranslationLanguages();

  const validateLabel = (label: Label) => {
    const isValid = activeLanguages.every(lang => !!label[lang.code]);
    const invalidLanguages = activeLanguages.filter(lang => !label[lang.code]);

    return { isValid, invalidLanguages };
  };

  return validateLabel;
};
