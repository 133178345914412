import {
  ArchiveBoxArrowDownIcon,
  ChartPieIcon,
  ChatBubbleLeftEllipsisIcon,
  Cog6ToothIcon,
  DevicePhoneMobileIcon,
  HomeIcon,
  TrophyIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { ROUTES, SideMenuLink } from 'src/utils/Links';

import { MenuIcon } from '../Desktop';

export const POSDeviceIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    fill="#9ca3af"
    height="800px"
    width="800px"
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 493.662 493.662"
    xmlSpace="preserve"
    {...props}>
    <g>
      <path
        d="M329.587,114h-19.756V10.5c0-5.799-4.701-10.5-10.5-10.5h-105c-5.799,0-10.5,4.701-10.5,10.5V114h-19.756
		c-17.561,0-31.85,14.287-31.85,31.849v315.964c0,17.561,14.288,31.849,31.85,31.849h165.512c17.561,0,31.849-14.287,31.849-31.849
		V145.85C361.436,128.288,347.149,114,329.587,114z M298.806,175.974v70.39H194.857v-70.39H298.806z M288.831,21v133.564l-84-0.001
		V21H288.831z M340.436,461.813c0,5.982-4.867,10.849-10.849,10.849H164.075c-5.982,0-10.85-4.867-10.85-10.849V145.849
		c0-5.982,4.867-10.849,10.85-10.849h19.756v19.589c-2.593,0.13-5.055,1.205-6.899,3.049c-1.969,1.969-3.075,4.64-3.075,7.425v91.8
		c0,5.799,4.701,10.5,10.5,10.5h124.949c5.799,0,10.5-4.701,10.5-10.5v-91.8c0-5.622-4.422-10.198-9.975-10.474V135h19.756
		c5.982,0,10.849,4.867,10.849,10.85V461.813z"
      />
      <path
        d="M231.148,299.735h-47.05c-5.799,0-10.5,4.701-10.5,10.5c0,5.799,4.701,10.5,10.5,10.5h47.05c5.799,0,10.5-4.701,10.5-10.5
		C241.648,304.436,236.947,299.735,231.148,299.735z"
      />
      <path
        d="M309.564,299.735h-47.049c-5.799,0-10.5,4.701-10.5,10.5c0,5.799,4.701,10.5,10.5,10.5h47.049
		c5.799,0,10.5-4.701,10.5-10.5C320.064,304.436,315.362,299.735,309.564,299.735z"
      />
      <path
        d="M231.148,354.175h-47.05c-5.799,0-10.5,4.701-10.5,10.5s4.701,10.5,10.5,10.5h47.05c5.799,0,10.5-4.701,10.5-10.5
		S236.947,354.175,231.148,354.175z"
      />
      <path
        d="M309.564,354.175h-47.049c-5.799,0-10.5,4.701-10.5,10.5s4.701,10.5,10.5,10.5h47.049c5.799,0,10.5-4.701,10.5-10.5
		S315.362,354.175,309.564,354.175z"
      />
      <path
        d="M231.148,409.683h-47.05c-5.799,0-10.5,4.701-10.5,10.5c0,5.799,4.701,10.5,10.5,10.5h47.05c5.799,0,10.5-4.701,10.5-10.5
		C241.648,414.384,236.947,409.683,231.148,409.683z"
      />
      <path
        d="M309.564,409.683h-47.049c-5.799,0-10.5,4.701-10.5,10.5c0,5.799,4.701,10.5,10.5,10.5h47.049
		c5.799,0,10.5-4.701,10.5-10.5C320.064,414.384,315.362,409.683,309.564,409.683z"
      />
    </g>
  </svg>
);

export const useNavigationLinks = () => {
  const { t } = useTranslation();

  const NAVIGATIONS: SideMenuLink[] = [
    {
      name: t('stores:stores'),
      href: ROUTES.STORES,
      icon: HomeIcon,
      component: () => import('src/stores/components/StoresPage/StoresPage'),
    },
    {
      name: t('common:menu'),
      icon: MenuIcon,
      children: [
        {
          name: t('menu:menus'),
          href: ROUTES.MENUS,
          component: () => import('src/menu/menu-page/Menupage'),
        },
        {
          name: t('menu:categories'),
          href: ROUTES.CATEGORIES,
          component: () =>
            import(
              'src/menu/menu-category/components/categories-page/CategoriesPage'
            ),
        },
        {
          name: t('menu:items'),
          href: ROUTES.ITEMS,
          component: () =>
            import('src/menu/menu-item/components/menu-item-page/MenuItemPage'),
        },
        {
          name: t('menu:options'),
          href: ROUTES.OPTIONS,
          children: [
            {
              name: t('menu:optionSets'),
              href: ROUTES.OPTION_SETS,
              component: () =>
                import(
                  'src/menu/option-sets/components/option-sets-page/OptionsPage'
                ),
            },
            {
              name: t('menu:optionItems'),
              href: ROUTES.OPTIONS,
              component: () => import('src/menu/options-page/'),
            },
          ],
        },
      ],
    },
    {
      name: t('orders:orders'),
      href: ROUTES.ORDERS,
      icon: ArchiveBoxArrowDownIcon,
      component: () => import('src/orders/components/OrdersPage/OrdersPage'),
    },

    {
      name: t('customers:customers'),
      href: ROUTES.CUSTOMERS,
      icon: UserGroupIcon,
      component: () =>
        import('src/customers/components/CustomersPage/CustomersPage'),
    },
    {
      name: t('feedback:feedback'),
      href: ROUTES.FEEDBACK,
      icon: ChatBubbleLeftEllipsisIcon,
      component: () => import('src/feedback/components/FeedbacksPage'),
    },
    {
      name: t('coupons:custLoyalty'),
      icon: TrophyIcon,
      children: [
        {
          name: t('coupons:coupons'),
          href: ROUTES.COUPONS,
          component: () =>
            import('src/customerLoyalty/coupons/components/CouponsPage'),
        },
        {
          name: t('discounts:discounts'),
          href: ROUTES.DISCOUNTS,
          component: () => import('src/customerLoyalty/discounts'),
        },
      ],
    },
    {
      name: t('common:app'),
      href: ROUTES.ONLINE_MENU,
      icon: DevicePhoneMobileIcon,
      component: () => import('src/online-menu'),
    },
    {
      name: t('devices:posLogins'),
      href: ROUTES.DEVICES,
      icon: POSDeviceIcon,
      component: () => import('src/devices/containers/DevicesPageContainer'),
    },
    {
      name: t('common:insights'),
      href: ROUTES.INSIGHTS,
      icon: ChartPieIcon,
      component: () => import('src/quicksight'),
    },
    {
      name: t('settings:settings'),
      href: ROUTES.SETTINGS,
      icon: Cog6ToothIcon,
      component: () => import('src/account/components/AccountDetails'),
    },
  ];

  return NAVIGATIONS;
};
