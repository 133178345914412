import {
  DocumentDuplicateIcon,
  EyeIcon,
  LinkIcon,
  PencilIcon,
  QrCodeIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { useBoolean } from 'src/common/components/CustomDialog';
import { useCopyText } from 'src/common/hooks/useCopyText';
import { QRDialog } from 'src/links-qrcodes/components/LinksPage/QRDialog';
import { UpdateMainLinkModal } from 'src/online-menu/components/UpdateMainLinkModal';
import { LinksV2 } from 'src/translations/apiTypes';

interface Props {
  link: LinksV2;
  isEditable?: boolean;
}

export const Link = ({ link, isEditable = false }: Props) => {
  const { copyText } = useCopyText({ showAlert: true });

  const { t } = useTranslation();

  const {
    setTrue: openQRModal,
    setFalse: closeQRModal,
    value: isQRModalOpen,
  } = useBoolean();

  const {
    setTrue: openLinkModal,
    setFalse: closeLinkModal,
    value: isLinkModalOpen,
  } = useBoolean();

  return (
    <div className="py-2 px-2 sm:px-4 flex flex-col sm:flex-row items-end sm:items-center gap-2">
      <UpdateMainLinkModal open={isLinkModalOpen} onClose={closeLinkModal} />

      <QRDialog
        title={t('onlineMenu:appLink')}
        link={link?.url}
        open={isQRModalOpen}
        handleClose={closeQRModal}
      />
      <div className="flex items-center gap-2 flex-1 self-start">
        <LinkIcon className="h-3.5 w-3.5" aria-hidden="true" />
        <a href={link.url} target="_blank" rel="noreferrer">
          <span>{link.displayUrl}</span>
        </a>
      </div>
      <div className="flex items-center gap-2">
        {isEditable && (
          <button type="button" onClick={openLinkModal}>
            <PencilIcon className="w-4 h-4 text-green-500" />
          </button>
        )}
        <button type="button" onClick={() => copyText(link.url)}>
          <DocumentDuplicateIcon className="w-4 h-4 text-green-500" />
        </button>
        <a href={link.url} target="_blank" rel="noreferrer">
          <EyeIcon className="w-4 h-4 text-green-500" />
        </a>
        <button type="button" onClick={openQRModal}>
          <QrCodeIcon className="w-4 h-4" />
        </button>
      </div>
    </div>
  );
};
