export const en = {
  totalSales: 'Total Sales',
  totalOrders: 'Total Orders',
  totalDineIns: 'Total DineIns',
  maxSales: 'Max Sales',
  minSales: 'Min Sales',
  averageSales: 'Average Sales',
  newCustomers: 'New Customers',
  totalCustomers: 'Total Customers',
  repeatCustomers: 'Repeat Customers',
  averageCustomerValue: 'Average Customer Value',
  maxCustomerValue: 'Max Customer Value',
  minCustomerValue: 'Min Customer Value',
};

export const ar: typeof en = {
  averageCustomerValue: '',
  averageSales: '',
  maxCustomerValue: '',
  maxSales: '',
  minCustomerValue: '',
  minSales: '',
  newCustomers: '',
  repeatCustomers: '',
  totalCustomers: '',
  totalDineIns: '',
  totalOrders: '',
  totalSales: '',
};
