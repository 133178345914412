export const en = {
  orders: 'Orders',
  order: 'Order',
  submitted: 'Submitted',
  new: 'New',
  inReview: 'In Review',
  orderETA: 'Order ETA',
  inProgress: 'In Progress',
  cancelled: 'Cancelled',
  assignedCourier: 'Assigned Driver',
  ordersSubtitle: 'Browse and filter orders',
  deliveryInProgress: 'In Delivery',
  driveThruReadyForPickup: 'Ready For Pickup',
  inStoreReadyForPickup: 'Ready For Pickup',
  pendingPayment: 'Pending Payment',
  paymentInfo: 'Payment Info',
  viewCustomerDetails: 'View Details',
  courierSuccess: 'Driver Assigned Successfully',
  refund: 'Refund',
  refundPlaceholder: 'Enter Refund Amount',
  courierError: 'Unable To Assign Driver',
  selectCourier: 'Select Driver',
  assignCourier: 'Assign Driver',
  markDeliveryInProgress: 'Mark Delivery In Progress',
  courierHelper:
    'Once you select the Driver, we will send an SMS to the driver with the link which will include the order details',
  delivered: 'Delivered',
  completed: 'Completed',
  accepted: 'Accepted',
  rejected: 'Rejected',
  fulfilled: 'Fulfilled',
  securingCourier: 'Finding Driver',
  unknown: 'Unknown',
  browse: 'Browse',
  customerName: 'Customer',
  customerInfo: 'Customer Information',
  customerCar: 'Customer Car',
  date: 'Date',
  storeName: 'Store Name',
  orderType: 'Type',
  total: 'Total',
  subtotal: 'Subtotal',
  totalItems: 'Total Items',
  status: 'Status',
  notes: 'Order Notes',
  rejectionNotes: 'Rejection Notes',
  noRejectionNotes: 'No Rejection Notes..',
  noNotes: 'no notes...',
  currentDay: 'Current Day',
  previousDay: 'Previous Day',
  last7Days: 'Last 7 Days',
  last30Days: 'Last 30 Days',
  custom: 'Custom',
  searchOrders: 'Search Orders by ID',
  details: 'Details',
  orderNumber: 'Number',
  placedOn: 'Placed On',
  tax: 'Tax',
  completeOrder: 'Complete Order',
  acceptOrder: 'Accept Order',
  rejectOrder: 'Reject Order',
  more: 'More',
  storeInfo: 'Store Information',
  deliveryLocation: 'Delivery Location',
  sortBy: 'Sort By',
  edit: 'Edit',
  noOrders: 'No Orders Found!',
  pleaseTell: 'Please tell',
  rejectingOrder: 'Why you are rejecting their order.',
  rejectOrderMsg: 'I am rejecting this order because ...',
  tableNumber: 'Table No.',
  searchHistory: 'Try searching entire history?',
  loadMore: 'Load More',
  deliveryAgent: 'Delivery Agent',
  viewOnMap: 'View Map',
  deliveryFee: 'Delivery Fee',
  trackDriver: 'Track Driver',
  orderRefundSuccess: 'Order Refunded Successfully!',
  orderRefundError: 'Unable To Refund Order',
  minutes: 'Minute(s)',
  upgradeMemberShipPlaceHolder: 'Orders are available for premium members only',
  refundTitle: 'Please note that refunds will take up to 5 business days',
  totalRefundAllowed: 'Total Refund Allowed is',
  refunded: 'Refunded',
  updatingStatus: 'Updating Status',
  forDeliveryOrders: 'For Delivery Orders',
  alertPickup: 'Customer has arrived and ready to pickup the order',
  feedback: 'Feedback',
  color: 'Color',
  model: 'Model',
  make: 'Make',
  noOrdersYet:
    'No orders yet. Once you receive online orders, they will show up here',
  discounts: 'Discounts',
};

export const ar: typeof en = {
  discounts: 'التخفيضات',
  feedback: 'تقببم العميل',
  color: 'اللون',
  model: 'موديل السيارة',
  make: 'ماركة السيارة',
  refund: 'استرجاع المبلغ',
  refunded: 'تم استرجاع المبلغ',
  inReview: 'قيد المراجعة',
  alertPickup: 'العميل وصل وجاهز لاستلام الطلب',
  noOrdersYet: 'لايوجد طلبات',
  updatingStatus: 'وضع التحديث',
  refundPlaceholder: 'ادخل المبلغ للاسترجاع',
  orderRefundSuccess: 'تم استرجاع المبلغ بنجاح',
  refundTitle: 'نود التنويه الى ان عملية الأسترجاع قد تستغرق ٥ ايام عمل',
  orderRefundError: 'لايمكن استرجاع المبلغ',
  totalRefundAllowed: 'قيمة الاسترجاع المسموحة',
  forDeliveryOrders: 'لطلبات التوصيل',
  orders: 'الطلبات',
  viewOnMap: 'الخريطة',
  orderETA: 'وقت التحظير',
  submitted: 'تم إرساله',
  customerInfo: 'معلومات العميل',
  deliveryFee: 'رسوم التوصيل',
  placedOn: 'تاريخ الطلب',
  paymentInfo: 'طريقة الدفع',
  customerCar: 'سيارة العميل',
  assignCourier: 'تعين مندوب',
  courierError: 'خطأ في تعين المندوب',
  courierHelper:
    'عند اختيارك للمندوب، سنقوم بإرسال رسالة للمندوب بمعلومات الطلب',
  courierSuccess: 'تم تعين المندوب',
  markDeliveryInProgress: 'جاري التوصيل',
  selectCourier: 'اختر المندوب',
  viewCustomerDetails: 'عرض معلومات العميل',
  driveThruReadyForPickup: 'جاهز للاستلام',
  inStoreReadyForPickup: 'جاهز للاستلام',
  accepted: 'تم قبوله',
  securingCourier: 'جاري تعين المندوب',
  rejected: 'تم رفضه',
  pendingPayment: 'الدفع قيد التنفيذ',
  rejectionNotes: 'ملاحظات الرفض',
  noRejectionNotes: 'لايوجد ملاحظات رفض',
  totalItems: 'مجموع المحتويات',
  fulfilled: 'تم تنفيذه',
  browse: 'تصفح',
  deliveryLocation: 'عنوان التوصيل',
  customerName: 'اسم العميل',
  date: 'التاريخ',
  storeName: 'اسم المتجر',
  orderType: 'نوع الطلب',
  total: 'المجموع',
  subtotal: 'قيمة الطلب',
  status: 'وضع الطلب',
  notes: 'ملاحظات',
  noNotes: 'لايجود ملاحظات',
  currentDay: 'اليوم',
  previousDay: 'اليوم السابق',
  last7Days: 'اخر ٧ ايام',
  last30Days: 'اخر ٣٠ يوم',
  custom: 'تحديد التاريخ',
  searchOrders: 'البحث عن طلب',
  new: 'جديد',
  inProgress: 'قيد التحضير',
  cancelled: 'ملغى',
  assignedCourier: 'تم تعين مندوب',
  ordersSubtitle: 'تصفح وابحث عن طلباتك',
  deliveryInProgress: 'قيد التوصيل',
  delivered: 'تم التوصيل',
  completed: 'تم اكمال الطلب',
  unknown: 'غير معروف',
  details: 'التفاصيل',
  orderNumber: 'رقم الطلب',
  order: 'الطلب',
  tax: 'الضريبة',
  completeOrder: 'اكمل الطلب الطلب',
  acceptOrder: 'اقبل الطلب',
  rejectOrder: 'ارفض الطلب',
  more: 'المزيد',
  storeInfo: 'معلومات المتجر',
  sortBy: 'الترتيب',
  edit: 'تعديل',
  noOrders: 'لايوجد طلبات',
  pleaseTell: 'الرجاء الاخبار',
  rejectingOrder: 'سبب رفض الطلب؟',
  rejectOrderMsg: 'سبب رفض الطلب',
  tableNumber: 'رقم الطاولة',
  searchHistory: 'ابحث في جميع الطلبات السابقه',
  loadMore: 'عرض المزيد',
  deliveryAgent: 'المندوب',
  trackDriver: 'تتبع المندوب',
  minutes: 'دقائق أو دقيقة',
  upgradeMemberShipPlaceHolder: 'خاصية الطلبات متوفرة للأعضاء فقط',
};
