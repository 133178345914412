import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Select, {
  components,
  GroupTypeBase,
  MenuListComponentProps,
  Props,
  Styles,
} from 'react-select';

import { selectStyles, theme } from './styles';

export type Option = { label: string; value: string };

interface MenuProps
  extends MenuListComponentProps<Option, true, GroupTypeBase<Option>> {
  label: ReactNode;
  onClickAdd(): void;
}

export const MenuListWithAddButton = (props: MenuProps) => {
  const { t } = useTranslation();

  return (
    <components.MenuList {...props}>
      <div className="px-3 pt-2 pb-3 flex items-center justify-between border-b">
        <p className="text-sm text-gray-400">{t('common:previouslySaved')}</p>
        <button
          className="bg-green-500 rounded px-4 py-2 text-white hover:bg-green-600"
          type="button"
          onClick={props.onClickAdd}>
          {props.label}
        </button>
      </div>
      {props.children}
    </components.MenuList>
  );
};

export interface GroupBase<Option> {
  readonly options: readonly Option[];
  readonly label?: string;
}

function StyledSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: Props<Option, IsMulti, Group>) {
  return (
    <Select
      {...props}
      styles={selectStyles as Partial<Styles<Option, IsMulti, Group>>}
      menuPortalTarget={document.body}
      theme={theme}
    />
  );
}

export default StyledSelect;
