import { t } from 'src/i18n/translate';

import { useAlertStore } from './useGlobalAlert';

export const useCopyText = ({
  showAlert = true,
}: { showAlert?: boolean } = {}) => {
  const { setAlert } = useAlertStore();
  const copyText = async (text: string) => {
    if (text) {
      await navigator.clipboard.writeText(text);
      if (showAlert) {
        setAlert('success', t('common:copied'));
      }
    }
  };
  return {
    copyText,
  };
};
