import classNames from 'classnames';
import { useCurrentLanguage } from 'src/i18n/hooks/useCurrentLanguage';
import { WhatsappIcon } from 'src/online-menu/components/Socials/assets/icons';
import { isRTL } from 'src/translations/utils/languageUtils';

export const Support = () => {
  const { language } = useCurrentLanguage();

  const rtl = isRTL(language);

  return (
    <button
      onClick={() =>
        window.open(
          process.env.REACT_APP_SUPPORT_LINK,
          '_blank',
          'noreferrer noopener',
        )
      }
      dir={rtl ? 'rtl' : 'ltr'}
      className={classNames(
        'w-12 h-12 m-4 fixed bottom-0 z-10 bg-green-500 rounded-full flex items-center justify-center gap-2 opacity-70 hover:opacity-100 transition-all text-white',
        rtl ? 'left-0' : 'right-0',
      )}
      type="button">
      <WhatsappIcon className="w-8 h-8" />
    </button>
  );
};
