import mixpanel, { Dict } from 'mixpanel-browser';

import { Analytics } from './Analytics';

export default class MixpanelAnalytics implements Analytics {
  constructor(key: string) {
    if (typeof window !== 'undefined') {
      mixpanel.init(key);
    }
  }

  public logEvent(event: string, params: Dict): void {
    mixpanel.track(event, params);
  }

  public registerSuperProperties(params: Dict): void {
    // mixpanel.people.set(params);
    mixpanel.register(params);
  }

  public signup(customerId: string) {
    mixpanel.alias(customerId);
    mixpanel.identify(customerId);
  }

  public login(customerId: string) {
    mixpanel.identify(customerId);
  }

  public logout() {}
}
