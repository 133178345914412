import { useMutation, useQueryClient } from 'react-query';
import Api from 'src/api/Api';
import { ReactQueryKey } from 'src/common/enums';
import { useAlertStore } from 'src/common/hooks/useGlobalAlert';
import { t } from 'src/i18n/translate';
import { useFetchAccount } from 'src/user/hooks/useFetchAccount';

export const useMakeLink = (storeId?: string, onSuccess?: () => void) => {
  const { setAlert } = useAlertStore();
  const { merchant } = useFetchAccount();
  const queryCache = useQueryClient();
  const {
    mutate,
    isLoading: loading,
    isSuccess: success,
    isError: error,
    reset,
  } = useMutation(Api.makeLink, {
    onSuccess: () => {
      queryCache.invalidateQueries([
        storeId,
        ReactQueryKey.FetchLinksByStoreId,
      ]);
      queryCache.getQueryData(ReactQueryKey.AllLinks);
      queryCache.invalidateQueries(ReactQueryKey.Account);
      onSuccess?.();
      setAlert('success', t('common:successLink'));
      reset();
    },
  });

  const data = merchant?.mainLinks?.find(link => link.isOnlineOrdering);

  const createLink = (linkValue: string) => {
    const link = linkValue
      ? linkValue
          .toLowerCase()
          .trim()
          .replace(/ /g, '-')
          .replace(/[\\`&\\#,+()$~%.'":*?<>{}]/g, '')
      : '';

    const payload = {
      id: link,
      name: {
        en: 'Online Ordering',
      },
      merchantId: merchant?.id,
      params: {
        type: '0',
      },
      targetType: 'MAIN',
      isMain: true,
    };
    mutate(payload);
  };

  return {
    createLink,
    link: data,
    loading,
    error,
    success,
  };
};
