import classNames from 'classnames';
import {
  DetailedHTMLProps,
  forwardRef,
  InputHTMLAttributes,
  LegacyRef,
} from 'react';

export const StyledInput = forwardRef(
  (
    props: DetailedHTMLProps<
      InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    ref,
  ) => (
    <input
      ref={ref as LegacyRef<HTMLInputElement>}
      {...props}
      className={classNames(
        'focus-visible:shadow-[0px_0px_0px_4px] focus-visible:shadow-green-100 focus-visible:outline-none focus-visible:ring-1 focus-visible:!ring-green-500 focus-visible:!border-transparent text-sm sm:px-2 px-2 py-1 rounded border !border-gray-300 w-full',
        props.className,
      )}
    />
  ),
);
