import 'react-phone-number-input/style.css';

import { GlobeAltIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { DevTool } from '@hookform/devtools';
import { ErrorMessage } from '@hookform/error-message';
import { CountryCode } from 'libphonenumber-js/types';
import React, { useEffect } from 'react';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { usePatchAccount } from 'src/account/hooks/usePatchAccount';
import { PrimaryButton } from 'src/common/components/Btn/PrimaryButton';
import { useBoolean } from 'src/common/components/CustomDialog';
import { Modal } from 'src/common/components/Modal';
import TranslationDialog from 'src/common/components/TranslationDialog';
import { useTranslationLanguages } from 'src/common/components/TranslationDialog/hooks/useTranslationLanguages';
import { useAlertStore } from 'src/common/hooks/useGlobalAlert';
import { useCheckValues } from 'src/signup/hooks/useCheckValues';
import { Label } from 'src/stores/apiTypes';
import { Account } from 'src/user/apiTypes';
import { useFetchAccount } from 'src/user/hooks/useFetchAccount';

interface Props {
  isVisible: boolean;
  onClose(): void;
}

export const EditAccountModal = ({ isVisible, onClose }: Props) => {
  const { t } = useTranslation();

  const { merchant, account } = useFetchAccount();

  const methods = useForm<Account>({ defaultValues: account });

  const {
    control,
    reset,
    watch,
    setValue,
    register,
    formState: { dirtyFields },
    setError,
  } = methods;

  const { merchants } = watch();

  const { businessName } = merchants?.[0] ?? {};

  const { value: showTranslationModal, toggle: toggleTranslationModal } =
    useBoolean();

  const { mutateAsync, loading: checkingValues } = useCheckValues();

  const { mutate, isLoading: updatingAccount } = usePatchAccount();

  const { primaryLanguage, activeLanguages } = useTranslationLanguages();

  const { setAlert } = useAlertStore();

  useEffect(() => {
    reset(account);
  }, [reset, account]);

  useEffect(() => {
    activeLanguages.forEach(l =>
      register(`merchants[0].businessName.${l.code}`),
    );
  }, [activeLanguages, register]);

  const isLoading = checkingValues || updatingAccount;

  const onSubmit: SubmitHandler<Account> = async data => {
    if (Object.keys(dirtyFields).length < 1) {
      onClose();
      return;
    }

    const primaryBusinessName =
      data.merchants?.[0]?.businessName?.[primaryLanguage?.code];

    const changedValues: Record<string, string | unknown> = {};

    if (dirtyFields.name) {
      changedValues.name = data.name;
    }

    if (dirtyFields.merchants?.[0]?.businessName?.[primaryLanguage?.code]) {
      changedValues.businessName = {
        en: primaryBusinessName,
        ar: primaryBusinessName,
      };
    }

    if (dirtyFields?.users?.[0]?.emailAddress) {
      changedValues.emailAddress = data.users?.[0]?.emailAddress;
    }

    if (dirtyFields?.users?.[0]?.phoneNumber) {
      changedValues.phoneNumber = data?.users?.[0]?.phoneNumber;
    }

    if (Object.keys(changedValues).length < 1) {
      return;
    }

    let isTaken = false;

    if (changedValues.emailAddress || changedValues.phoneNumber) {
      const response = await mutateAsync({
        emailAddress: changedValues.emailAddress,
        phoneNumber: changedValues.phoneNumber,
      });

      if (response.email) {
        setError('users[0].emailAddress', {
          type: 'manual',
          message: t('signup:emailTaken'),
        });

        isTaken = true;
      }

      if (response.phone) {
        setError('users[0].phoneNumber', {
          type: 'manual',
          message: t('signup:phoneNumberTaken'),
        });

        isTaken = true;
      }
    }

    if (isTaken) {
      return;
    }

    mutate({ id: account.id, ...changedValues } as any, {
      onSuccess: () => {
        setAlert('success', t('common:genericSuccessTitle'));
        onClose();
      },
      onError: () => setAlert('error', t('common:genericError')),
    });
  };

  const onSaveBusinessName = (businessName_: Label) => {
    setValue('merhcants[0].businessName', businessName_, {
      shouldDirty: true,
    });
  };

  return (
    <Modal
      isOpen={isVisible}
      onClose={onClose}
      dialogPanelClassName="w-full !max-w-3xl">
      <div>
        <TranslationDialog
          label={businessName}
          closeDialog={toggleTranslationModal}
          show={showTranslationModal}
          onSave={onSaveBusinessName}
          entityName={t('common:name')}
        />
        <div className="flex items-center justify-between bg-gray-50 p-4 sm:p-6">
          <h3 className="text-xl font-semibold">{t('account:account')}</h3>
          <button
            type="button"
            className="self-start mt-1 rounded-md  text-gray-500 hover:text-gray-600 focus:ring-2 focus:ring-green-500"
            onClick={onClose}>
            <span className="sr-only">Close panel</span>
            <XMarkIcon
              strokeWidth={2.5}
              className="h-8 w-8"
              aria-hidden="true"
            />
          </button>
        </div>
        <div className="p-4 sm:p-6">
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(onSubmit)}
              id="edit-account-form">
              <div className="grid grid-cols-6 gap-4">
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium leading-6 text-gray-900 text-left rtl:text-right">
                    {t('signup:name')} *
                  </label>
                  <div className="mt-2">
                    <Controller
                      control={control}
                      name="name"
                      rules={{
                        required: {
                          value: true,
                          message: t('signup:nameRequired'),
                        },
                      }}
                      render={props => (
                        <input
                          type="text"
                          {...props}
                          placeholder={t('signup:namePlaceholder')}
                          id="name"
                          className="focus-visible:shadow-[0px_0px_0px_4px] focus-visible:shadow-green-100 focus-visible:outline-none focus-visible:ring-1 focus-visible:!ring-green-500 focus-visible:!border-transparent text-sm sm:px-2 px-2 py-1 rounded border !border-gray-300 w-full"
                        />
                      )}
                    />
                  </div>
                  <ErrorMessage
                    name="name"
                    render={({ message }) => (
                      <p className="text-danger">{message}</p>
                    )}
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor={`merchants[0].businessName.${primaryLanguage?.code}`}
                    className="block text-sm font-medium leading-6 text-gray-900 text-left rtl:text-right">
                    {t('signup:businessName')} ({primaryLanguage?.name})
                  </label>
                  <div className="mt-2">
                    <Controller
                      control={control}
                      name={`merchants[0].businessName.${primaryLanguage?.code}`}
                      rules={{
                        required: {
                          value: true,
                          message: t('signup:businessNameRequired'),
                        },
                      }}
                      render={props => (
                        <input
                          type="text"
                          {...props}
                          placeholder={t('signup:businessNamePlaceholder')}
                          id={`merchants[0].businessName.${primaryLanguage?.code}`}
                          className="focus-visible:shadow-[0px_0px_0px_4px] focus-visible:shadow-green-100 focus-visible:outline-none focus-visible:ring-1 focus-visible:!ring-green-500 focus-visible:!border-transparent text-sm sm:px-2 px-2 py-1 rounded border !border-gray-300 w-full"
                        />
                      )}
                    />
                    <button
                      className="flex items-center gap-1 text-green-500 mt-1"
                      type="button"
                      onClick={toggleTranslationModal}>
                      <GlobeAltIcon className="w-4 h-4" />
                      <span>{t('translations:translate')}</span>
                    </button>
                  </div>
                  <ErrorMessage
                    name={`merchants[0].businessName.${primaryLanguage?.code}`}
                    render={({ message }) => (
                      <p className="text-danger">{message}</p>
                    )}
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="users[0].phoneNumber"
                    className="block text-sm font-medium leading-6 text-gray-900 text-left rtl:text-right">
                    {t('stores:phoneNumber')} *
                  </label>
                  <div className="mt-2" dir="ltr">
                    <Controller
                      control={control}
                      name="users[0].phoneNumber"
                      rules={{
                        validate: phone =>
                          !!phone && !isValidPhoneNumber(phone)
                            ? t('stores:invalidPhoneNumber')
                            : true,
                        required: {
                          value: true,
                          message: t('common:phoneNumberRequired'),
                        },
                      }}
                      render={props => (
                        <PhoneInput
                          {...props}
                          className="bg-white"
                          autoComplete="off"
                          defaultCountry={
                            merchant?.countryCode?.toUpperCase() as CountryCode
                          }
                          numberInputProps={{
                            className:
                              'focus-visible:shadow-[0px_0px_0px_4px] focus-visible:shadow-green-100 focus-visible:outline-none focus-visible:ring-1 focus-visible:!ring-green-500 focus-visible:!border-transparent text-sm sm:px-2 px-2 py-1 rounded border !border-gray-300 w-full',
                            name: 'users[0].phoneNumber',
                            id: 'users[0].phoneNumber',
                          }}
                        />
                      )}
                    />
                    <ErrorMessage
                      name="users[0].phoneNumber"
                      render={({ message }) => (
                        <p className="text-danger">{message}</p>
                      )}
                    />
                  </div>
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="users[0].emailAddress"
                    className="block text-sm font-medium leading-6 text-gray-900 text-left rtl:text-right">
                    {t('signup:emailAddress')}
                  </label>
                  <div className="mt-2">
                    <Controller
                      control={control}
                      name="users[0].emailAddress"
                      rules={{
                        required: {
                          value: true,
                          message: t('signup:emailRequired'),
                        },
                      }}
                      render={props => (
                        <input
                          type="email"
                          {...props}
                          placeholder={t('signup:emailAddressPlaceholder')}
                          id="users[0].emailAddress"
                          className="focus-visible:shadow-[0px_0px_0px_4px] focus-visible:shadow-green-100 focus-visible:outline-none focus-visible:ring-1 focus-visible:!ring-green-500 focus-visible:!border-transparent text-sm sm:px-2 px-2 py-1 rounded border !border-gray-300 w-full"
                        />
                      )}
                    />
                  </div>
                  <ErrorMessage
                    name="users[0].emailAddress"
                    render={({ message }) => (
                      <p className="text-danger">{message}</p>
                    )}
                  />
                </div>
              </div>

              <DevTool control={methods.control} />
            </form>
          </FormProvider>
        </div>
        <div className="flex flex-col sm:flex-row-reverse items-stretch sm:items-center justify-between bg-gray-50 p-4 sm:p-6 gap-2 sm:gap-4">
          <PrimaryButton
            type="submit"
            form="edit-account-form"
            className="flex-1"
            isLoading={isLoading}>
            {t('common:save')}
          </PrimaryButton>
          <button
            className="border px-4 py-2 rounded bg-white hover:bg-gray-50 flex-1"
            type="button"
            onClick={onClose}>
            {t('common:cancel')}
          </button>
        </div>
      </div>
    </Modal>
  );
};
