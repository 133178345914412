import {
  ChevronDownIcon,
  ChevronUpIcon,
  QrCodeIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Accordion from 'src/common/components/Accordion';
import { useBoolean } from 'src/common/components/CustomDialog';
import { Loader } from 'src/common/components/Loader';
import { Modal } from 'src/common/components/Modal';
import { useFetchAccount } from 'src/user/hooks/useFetchAccount';

import { DineInLinks } from './components/DineInLinks';
import { Main, Menu, OnlineOrdering, TableOrdering } from './components/Icons';
import { MainLink } from './components/MainLink';
import { MenuLinks } from './components/MenuLinks';
import { OnlineLinks } from './components/OnlineLinks';

export const Links = () => {
  const { t } = useTranslation();

  const {
    setTrue: handleClickOpen,
    setFalse: handleClose,
    value: open,
  } = useBoolean();

  const { loading: isLoading } = useFetchAccount();

  return (
    <>
      <button
        type="button"
        disabled={isLoading}
        onClick={handleClickOpen}
        className="inline-flex items-center gap-x-2 rounded-md bg-green-400 py-2 px-2 sm:px-4 text-xs sm:text-sm font-semibold text-white hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-400 disabled:cursor-not-allowed">
        {isLoading ? (
          <Loader loaderClassName="w-4 h-4" />
        ) : (
          <div className="flex items-center gap-2">
            <QrCodeIcon className="h-5 w-5" aria-hidden="true" />
            <span className="hidden sm:block">{t('links-qr:linksQr')}</span>
          </div>
        )}
      </button>
      <Modal
        isOpen={open}
        onClose={handleClose}
        dialogPanelClassName="!w-full sm:!max-w-4xl">
        <div className="p-4 sm:p-6">
          <div className="flex items-center justify-between">
            <h2 className="text-2xl font-semibold capitalize  text-gray-900">
              {t('links-qr:links')}
            </h2>
            <button
              type="button"
              className="rounded-md  text-gray-500 hover:text-gray-600 focus:ring-2 focus:ring-green-500"
              onClick={handleClose}>
              <span className="sr-only">Close dialog</span>
              <XMarkIcon
                strokeWidth={2.5}
                className="h-8 w-8"
                aria-hidden="true"
              />
            </button>
          </div>
          <Accordion initial="menu">
            <Accordion.Item>
              <Accordion.Collapsed id="menu" className="mt-4 cursor-pointer">
                {({ id }) => (
                  <div
                    className={classNames(
                      'flex items-center gap-2 sm:gap-4 px-2 sm:px-4 py-4 border',
                      id === 'menu' ? 'border-b-0 rounded-t' : 'rounded',
                    )}>
                    <Menu />
                    <div className="flex-1 ltr:text-left rtl:text-right">
                      <h3 className="text-lg font-semibold">
                        {t('links-qr:menuOnly')}
                      </h3>
                      <span className="text-gray-500">
                        {t('links-qr:menuOnlyDesc')}
                      </span>
                    </div>
                    {id === 'menu' ? (
                      <ChevronUpIcon className="w-6 h-6 self-start" />
                    ) : (
                      <ChevronDownIcon className="w-6 h-6 self-start" />
                    )}
                  </div>
                )}
              </Accordion.Collapsed>
              <Accordion.Expanded>
                <MenuLinks />
              </Accordion.Expanded>
            </Accordion.Item>
            <Accordion.Item>
              <Accordion.Collapsed id="main" className="mt-4 cursor-pointer">
                {({ id }) => (
                  <div
                    className={classNames(
                      'flex items-center gap-2 sm:gap-4 px-2 sm:px-4 py-4 border',
                      id === 'main' ? 'border-b-0 rounded-t' : 'rounded',
                    )}>
                    <Main />
                    <div className="flex-1 ltr:text-left rtl:text-right">
                      <h3 className="text-lg font-semibold">
                        {t('links-qr:allInOneMerchant')}
                      </h3>
                      <span className="text-gray-500">
                        {t('links-qr:allInOneMerchantDesc')}
                      </span>
                    </div>
                    {id === 'main' ? (
                      <ChevronUpIcon className="w-6 h-6 self-start" />
                    ) : (
                      <ChevronDownIcon className="w-6 h-6 self-start" />
                    )}
                  </div>
                )}
              </Accordion.Collapsed>
              <Accordion.Expanded>
                <MainLink />
              </Accordion.Expanded>
            </Accordion.Item>
            <Accordion.Item>
              <Accordion.Collapsed id="dineIn" className="mt-4 cursor-pointer">
                {({ id }) => (
                  <div
                    className={classNames(
                      'flex items-center gap-2 sm:gap-4 px-2 sm:px-4 py-4 border',
                      id === 'dineIn' ? 'border-b-0 rounded-t' : 'rounded',
                    )}>
                    <TableOrdering />
                    <div className="flex-1 ltr:text-left rtl:text-right">
                      <h3 className="text-lg font-semibold">
                        {t('links-qr:tableOrdering')}
                      </h3>
                      <span className="text-gray-500">
                        {t('links-qr:tableOrderingDesc')}
                      </span>
                    </div>
                    {id === 'dineIn' ? (
                      <ChevronUpIcon className="w-6 h-6 self-start" />
                    ) : (
                      <ChevronDownIcon className="w-6 h-6 self-start" />
                    )}
                  </div>
                )}
              </Accordion.Collapsed>
              <Accordion.Expanded>
                <DineInLinks />
              </Accordion.Expanded>
            </Accordion.Item>
            <Accordion.Item>
              <Accordion.Collapsed
                id="onlineOrdering"
                className="mt-4 cursor-pointer">
                {({ id }) => (
                  <div
                    className={classNames(
                      'flex items-center gap-2 sm:gap-4 px-2 sm:px-4 py-4 border',
                      id === 'onlineOrdering'
                        ? 'border-b-0 rounded-t'
                        : 'rounded',
                    )}>
                    <OnlineOrdering />

                    <div className="flex-1 ltr:text-left rtl:text-right">
                      <h3 className="text-lg font-semibold">
                        {t('common:onlineOrdering')}
                      </h3>
                      <span className="text-gray-500">
                        {t('links-qr:onlineOrderingDesc')}
                      </span>
                    </div>
                    {id === 'onlineOrdering' ? (
                      <ChevronUpIcon className="w-6 h-6 self-start" />
                    ) : (
                      <ChevronDownIcon className="w-6 h-6 self-start" />
                    )}
                  </div>
                )}
              </Accordion.Collapsed>
              <Accordion.Expanded>
                <OnlineLinks />
              </Accordion.Expanded>
            </Accordion.Item>
          </Accordion>
        </div>
      </Modal>
    </>
  );
};
