import { useEffect } from 'react';
import { useCurrentLanguage } from 'src/i18n/hooks/useCurrentLanguage';
import { SupportedLanguage } from 'src/i18n/types';
import StringsHelper from 'src/utils/StringsHelper';

export const LanguageSwitcher = () => {
  const { language, setCurrentLanguage } = useCurrentLanguage();

  const handleChangeLanguage = (value: SupportedLanguage) => {
    setCurrentLanguage(value);
  };

  useEffect(() => {
    const language_ = localStorage.getItem('fdbt-dab-lng');

    if (language_) {
      setCurrentLanguage(language_ as SupportedLanguage);
    }
  }, [setCurrentLanguage]);

  return (
    <div className="flex flex-shrink-0 w-full border-gray-200">
      <span className="relative z-0  inline-flex rounded-md shadow-sm">
        <button
          type="button"
          onClick={() => handleChangeLanguage(SupportedLanguage.English)}
          className={StringsHelper.classNames(
            language === 'en' ? 'text-green-500 font-bold' : 'text-gray-500',
            'relative inline-flex items-center ltr:rounded-l-md rtl:rounded-r-md border border-gray-300 bg-white px-4 py-2 text-sm  hover:bg-gray-50 focus:z-10 focus:border-green-500 focus:outline-none focus:ring-1 focus:ring-green-500',
          )}>
          <span className="sr-only">EN</span>
          EN
        </button>
        <button
          type="button"
          onClick={() => handleChangeLanguage(SupportedLanguage.Arabic)}
          className={StringsHelper.classNames(
            language === 'ar' ? 'text-green-500 font-bold' : 'text-gray-500',
            'relative -ml-px inline-flex items-center ltr:rounded-r-md rtl:rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm   hover:bg-gray-50 focus:z-10 focus:border-green-500 focus:outline-none focus:ring-1 focus:ring-green-500',
          )}>
          <span className="sr-only">عربى</span>
          عربى
        </button>
      </span>
    </div>
  );
};
