export const en = {
  feedback: 'Feedback',
  restaurantRating: 'Restaurant Rating',
  basedOn: 'Based on',
  ratings: 'ratings',
  last7Days: 'Last 7 days',
  last30Days: 'Last 30 days',
  last60Days: 'Last 60 days',
  last6Months: 'Last 6 months',
  custom: 'Custom',
  allStores: 'All stores',
  allRatings: 'All scores',
  customerComments: 'Customer comments',
  customer: 'Customer',
  rating: 'Rating',
  comments: 'Comments',
  store: 'Store',
  date: 'Date',
  period: 'Period',
  ratingRange: 'Ratings Score',
  avgRatings: 'Average Rating',
  ratingsBreakdown: 'Ratings Breakdown',
  forSelectedRange: 'For selected range',
  star: 'Star',
  stars: 'Stars',
  customerRatings: 'Customer Ratings',
  check: 'Check',
  order: 'Order',
  table: 'Table',
  orders: 'Orders',
  selectAll: 'Select all',
  unSelectAll: 'Unselect all',
  apply: 'Apply',
  alert: 'Alert',
  invalidSelection: 'Must have at least one rating selected',
  message: 'Message',
  sendMessage: 'Send message',
  sendMessageUsing: 'Send a message to the customer using:',
  whatsapp: 'WhatsApp',
  sms: 'SMS',
  cancel: 'Cancel',
  noPhoneNumber: 'This customer does not have a phone number',
  viewDetails: 'View details',
  checkNumber: 'Check Number',
  orderNumber: 'Order Number',
  feedbackSubtitle: 'Customer ratings and their feedback',
  mustHaveStore: 'You must have at least one store selected',
};

export const ar: typeof en = {
  mustHaveStore: 'لابد من اختيار متجر واحد كحد أدنى',
  feedback: 'التقييم',
  allRatings: 'الجميع',
  allStores: 'جميع المتاجر',
  basedOn: 'بناءا على',
  comments: 'ملاحظات',
  custom: 'خاص',
  customer: 'العميل',
  customerComments: 'ملاحظات العميل',
  date: 'التاريخ',
  last30Days: 'اخر ٣٠ يوم',
  last60Days: 'اخر ٦٠ يوم',
  last6Months: 'اخر ٦ شهور',
  last7Days: 'اخر ٧ ايام',
  rating: 'التقييم',
  ratings: 'التقييم',
  restaurantRating: 'تقييم المتجر',
  store: 'المتجر',
  period: 'الفترة',
  ratingRange: 'النجمات',
  avgRatings: 'متوسط التقييم',
  ratingsBreakdown: 'تفاصيل التقييم',
  forSelectedRange: 'للفترة المختارة',
  star: 'نجمة',
  stars: 'نجمات',
  customerRatings: 'تقييم العملاء',
  check: 'الفاتورة',
  order: 'الطلب',
  table: 'الطاولة',
  orders: 'الطلبات',
  selectAll: 'الجميع',
  unSelectAll: 'ازالة اختيار الجميع',
  apply: 'تحديث',
  alert: 'تنبيه',
  invalidSelection: 'اختيار خاطئ',
  message: 'تواصل',
  sendMessage: 'ارسل رسالة',
  sendMessageUsing: 'ارسل رسالة للعميل عن طريق:',
  whatsapp: 'WhatsApp',
  sms: 'رسالة نصية',
  cancel: 'الغاء',
  noPhoneNumber: 'لايوجد رقم مسجل في ملف العميل',
  viewDetails: 'عرض التفاصيل',
  checkNumber: 'رقم الفاتورة',
  orderNumber: 'رقم الطلب',
  feedbackSubtitle: 'تقييم العملاء وملاحظاتهم',
};
