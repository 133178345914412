import { BuildingStorefrontIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { labelOrDefault } from 'src/common/utils/labelLanguageUtils';
import { useCurrentLanguage } from 'src/i18n/hooks/useCurrentLanguage';
import { useFetchLinksV2 } from 'src/links-qrcodes/hooks/useFetchLinksV2';
import { LinksV2 } from 'src/translations/apiTypes';

import { Link } from './Link';

export const OnlineLinks = () => {
  const { data, isLoading } = useFetchLinksV2();

  const { language } = useCurrentLanguage();

  const { onlineOrdering = [] } = data ?? {};

  const { t } = useTranslation();

  const getTarget = (link: LinksV2) => {
    const isAllStores = [
      'ALL_STORES_ONLINE_ORDERING',
      'ALL_STORES_DINE_IN',
    ].includes(link.type);

    if (isAllStores) {
      return t('devices:allStores');
    }

    return labelOrDefault(link.target?.name, language);
  };

  return (
    <div className="border border-t-0 rounded-b flex">
      <div className="w-0 sm:w-[76px]" />
      <div className="flex-1 flex flex-col gap-2 sm:gap-4 pb-4">
        {isLoading &&
          [1, 2].map(idx => (
            <div className="animate-pulse border-t w-full" key={idx.toString()}>
              <div className="px-2 sm:px-4 py-4 border-b bg-white w-full">
                <div className="gap-2 flex ">
                  <div className="rounded bg-gray-100 w-6 h-4" />
                  <div className="rounded bg-gray-100 w-full h-4" />
                  <div className="rounded bg-gray-100 w-6 h-4" />
                  <div className="rounded bg-gray-100 w-6 h-4" />
                  <div className="rounded bg-gray-100 w-6 h-4" />
                </div>
              </div>
            </div>
          ))}
        {onlineOrdering.map(link => (
          <div
            className="border border-[#1890FF] rounded overflow-hidden flex-1 mx-2 sm:mx-4"
            key={link.url}>
            <div className="flex items-center gap-2 px-2 sm:px-4 py-2 bg-[#e6f7ff] text-[#1890FF] rounded">
              <BuildingStorefrontIcon className="w-4 h-4" />
              <span className="text-sm font-semibold text-gray-700">
                {getTarget(link)}
              </span>
            </div>
            <Link link={link} key={link.url} />
          </div>
        ))}
      </div>
    </div>
  );
};
