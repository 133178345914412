export const en = {
  menu: 'Menu',
  menuCategories: 'Menu',
  menus: 'Menus',
  type: 'Type',
  addCategory: 'Add Category',
  editMenu: 'Edit Menu',
  timeAvailability: 'Time Availability',
  editCategory: 'Edit Category',
  addItem: 'Add Item',
  addMenu: 'Add Menu',
  item: 'Item',
  code: 'POS Code',
  items: 'Items',
  menuItemsSubtitle: 'i.e. Cheese Pizza, Pepsi',
  categoriesSubtitle:
    'Categories (i.e. Pizza, Drinks) group menu items together',
  menusSubtitle:
    'Menu groups categories together. For instance, Christmas Menu, Special Events Menu',
  menuItemOptionSetSubtitle: 'Set of options like Toppings, Size..etc',
  menuItemOptionSubtitle:
    'An individual option such as Olives Topping, Small Size etc',
  menuItemSuccess: 'Menu Item Added Successfully!',
  menuError: 'Unable To Add Menu Item!',
  menuUpdateSuccess: 'Menu Item Updated Successfully!',
  menuUpdateError: 'Unable To Update Menu Item!',
  atleastOneItem: 'You need at least one category in an item.',
  deleteItemHelper: 'Item will be deleted permanently',
  deleteMenuHelper: 'Menu will be deleted permanently',
  optionSet: 'Options Set',
  optionSets: 'Option Sets',
  options: 'Options',
  optionItems: 'Option Items',
  option: 'Option Item',
  general: 'General',
  newOptions: 'New Options',
  editOptionsSet: 'Edit Options Set',
  save: 'Save',
  editItem: 'Edit Item',
  addOption: 'Add Option Item',
  optionSetsSelected: 'Option Sets Selected',
  addOptionSet: 'Add Option Set',
  addOptions: 'Add Option Items',
  price: 'Price',
  calories: 'Calories',
  category: 'Category',
  categories: 'Categories',
  theCategory: 'Category',
  alertMenuOption: 'Please Fill Details for Options',
  alertMenuOptionPrice: 'Please Fill The Menu Option Price',
  alertMenuItem: 'Please Fill Menu Item',
  selectFromFollowing: 'Select from the following',
  selectExistingOptions: 'Select from existing options',
  selectNewOptions: 'Enter new options',
  disableHide: 'Hide from Menu',
  enableSpeech: 'Enable Speech',
  unavailable: 'Unavailable',
  tempUnavailable: 'Out of Stock from Stores',
  hidden: 'Hidden',
  name: 'Name',
  minimumSelections: 'Minimum Selections',
  maximumSelections: 'Maximum Selections',
  minSelectionPlaceholder: 'Enter Minimum Selections',
  maxSelectionPlaceholder: 'Enter Maximum Selections',
  required: 'Required',
  deleteOptions: 'Delete Options',
  deleteOption: 'Delete Option',
  editOption: 'Edit Option',
  deleteItem: 'Delete Item',
  optionAvailabilityStartTime: 'Availability Start Time',
  optionAvailabilityEndTime: 'Availability End Time',
  posCode: 'POS Code',
  deleteCategory: 'Delete Category',
  deleteMenu: 'Delete Menu',
  confirmDeleteOption: 'Are you sure you want to remove this option',
  deleteItemsFirst:
    'This category contains items! You can only delete an empty category',
  deleteItemsFirstMenu:
    'This menu contains categories! You can only delete an empty menu',
  viewMenu: 'View Menu',
  createStoreFirst: 'To add menu items, please create a store first',
  addMenuItem: 'Add Menu Item',
  isRequired: 'Is Required',
  maximumNumberOfSelections: 'Maximum Number of Selections',
  minimumNumberOfSelections: 'Minimum Number of Selections',
  singleChoice: 'Single Selection',
  selectionRequired: 'Selection Required',
  selectionOptional: 'Selection Optional',
  allowMultiple: 'Multiple Selection',
  collapseAll: 'Collapse all',
  optionSuccess: 'Option Added Successfully!',
  optionEditSuccess: 'Option Updated Successfully!',
  optionEditError: 'Unable To Update Option',
  optionError: 'Unable To Add Option',
  description: 'Description',
  optionSelected: 'Options Selected',
  itemsSelected: 'Items Selected',
  categoriesSelected: 'Categories Selected',
  menusSelected: 'Menus Selected',
  storesSelected: 'Stores Selected',
  selectCategory: 'Select Category',
  nameError: 'Name is required for ',
  menuItems: 'Menu Items',
  unavailableInStores: 'Out of Stock from Stores',
  optionDeletedSuccess: 'Option Deleted Successfully!',
  optionDeletedError: 'Unable To Delete Option',
  connectedItems: 'Connected Menu Items',
  menuDeleteSuccessfully: 'Menu Deleted Successfully!',
  someOptionsUnavailable: 'Some options are unavailable',
  menuDeleteError: 'Unable to Delete Menu!',
  notTaxable: 'Not Taxable',
  firstCategory: 'Create your category',
  firstMenuItem: 'Create your first menu item',
  addFirstOption: 'Create your first option',
  addFirstOptionsSet: 'Create your first options set',
  firstMenu: 'Create your first menu',
  notTaxableDescription: 'This menu item will not have tax',
  reviewOrders: 'Review Orders',
  deleteMenuMessage:
    'Are you sure you want to delete this menu? All of your data will be permanently removed. This action cannot be undone.',
  reviewOrdersDescription:
    'Waiters can review orders before sending them to kitchen',
  unavailableInStoresTip:
    'If enabled, the item will be unavailable in the selected stores. If no stores are selected, the item will be unavailable across all stores ',
  tempUnavailableTip:
    'If enabled, the item will show as unavailable in the menu and customers will not be able to order it.' +
    ' This is useful when you want your customers to still see the item but it is out of stock',
  deleteCategoryMessage:
    'Are you sure you want to delete this category? All of your data will be permanently removed. This action cannot be undone.',
  deleteOptionMessage:
    'Are you sure you want to delete this option? All of your data will be permanently removed. This action cannot be undone.',
  deleteOptionSetMessage:
    'Are you sure you want to delete this optionset? All of your data will be permanently removed. This action cannot be undone.',
  deleteItemMessage:
    'Are you sure you want to delete this item? All of your data will be permanently removed. This action cannot be undone.',
  optionRequiredDesc: 'This option is required for this item',
  deleteStoreMessage: 'Are you sure you want to delete this store?',
  maxMustBeGreaterThanMin:
    'Maximum number of selections must be greater than minimum number of selections',
  enterMax: 'Please enter maximum number of selections',
  enterMin: 'Please enter minimum number of selections',
  minGreaterThan0: 'Minimum number of selections cannot be less than 0',
  unlinkMenu: 'Unlink Menu',
  removeCategory: 'Remove Category',
  removeItem: 'Unlink Item',
  insertCalories: 'Insert calories',
  removeOptionSet: 'Unlink Option Set',
  enableMax: 'Enable maximum selections',
  enableMin: 'Enable minimum selections',
  removeOption: 'Unlink option',
  unlinkCategory: 'Unlink Category',
  textToSpeech: 'Text to Speech',
  textToSpeechDesc:
    'When enabled, Customers will be able to hear an authentic pronunciation of this dish',
  textToSpeechLang: 'Text to Speech Language',
  optionSetCreated: 'Option Set Created Successfully!',
  priceIsRequired: 'Price is required',
  hideFromStores: 'Hide from Stores',
  defaultMenu: 'Default menu',
  mustHaveMenu: 'You must have at least one menu',
  categoriesAndItems: 'Categories & Items',
  rearrangeItemsAndCategories: 'Rearrange Items & Categories',
};

export const ar: typeof en = {
  rearrangeItemsAndCategories: 'تغير ترتيب الأصناف',
  categoriesAndItems: 'الأقسام والأصناف',
  mustHaveMenu: 'لابد من وجود منيو واحد كحد أدنى',
  defaultMenu: 'المنيو الأساسي',
  hideFromStores: 'اخفي من المتاجر',
  priceIsRequired: 'السعر مطلوب',
  optionSetCreated: 'تم الإنشأ بنجاح',
  textToSpeech: 'حول نص الى حديث',
  textToSpeechDesc: 'خاصية تسمح للعميل بالسماع الى اسم الصنف',
  textToSpeechLang: 'لغة المتحدث',
  unlinkCategory: 'ازالة ربط القسم',
  removeOption: 'ازالة الخيار',
  enableMin: 'الحد الأدنى',
  enableMax: 'الحد الأعلى',
  removeOptionSet: 'حذف مجموعة الخيارات',
  insertCalories: 'إدخل السعرات الحرارية',
  removeItem: 'حذف الصنف',
  removeCategory: 'حذف القسم',
  unlinkMenu: 'حذف قائمة الطعام',
  menu: 'قائمة الأصناف',
  menuCategories: 'القائمة',
  deleteStoreMessage: 'هل متأكد من عملية الحذف؟',
  editOptionsSet: 'تحديث مجموعة الخيارات ',
  optionRequiredDesc: 'هذا الخيار مطلوب لهذا العنصر',
  menus: 'قوائم الطعام',
  optionSet: 'مجموعة الخيارات',
  optionSets: 'مجموعة الخيارات',
  deleteMenuMessage: 'هل انت متأكد من عملية الحذف؟',
  deleteCategoryMessage: 'هل انت متأكد من عملية الحذف؟',
  deleteOptionMessage: 'هل انت متأكد من عملية الحذف؟',
  deleteOptionSetMessage: 'هل انت متأكد من عملية الحذف؟',
  deleteItemMessage: 'هل انت متأكد من عملية الحذف؟',
  reviewOrders: 'النادل يقوم بمراجعة الطلب',
  reviewOrdersDescription:
    'النادل يقوم بمراجعة الطلب مع العميل قبل ارسال الطلب للمطبخ',
  firstCategory: 'القسم الأول',
  addFirstOption: 'اضف أول اختيار',
  addFirstOptionsSet: 'اضف أول مجموعة خيارات',
  deleteMenuHelper: 'سيتم حذف القائمة بشكل نهائي',
  firstMenuItem: 'انشأ او صنف',
  firstMenu: 'قم بأنشأ أو قائمة طعام',
  notTaxable: 'من دون نسبة ضريبية',
  notTaxableDescription: 'هذا الصنف لايشمل اي نسبة ضريبية',
  someOptionsUnavailable: 'بعض الأختيارات غير متواجدة',
  addMenu: 'اضف قائمة',
  deleteMenu: 'احذف القائمة',
  editMenu: 'تعديل القائمة',
  menuItems: 'الأصناف',
  menuDeleteSuccessfully: 'تم حذف القائمة بنجاح',
  menuDeleteError: 'عفواً، حصل خطأ في عملية حذف القائمة',
  connectedItems: 'الأصناف المربوطه',
  menuItemSuccess: 'تم اضافة الصنف بنجاح',
  deleteItemsFirstMenu:
    'قائمة الطعام تحتوي على اقسام. بإمكانك حذف القوائم الفارغة فقط',
  optionEditError: 'عفواً، حصل خطأ في عملية تحديث الاختيارات',
  categories: 'أقسام',
  optionEditSuccess: 'تم تحديث المجموعة بنجاح ',
  menuError: 'عفواً، حصل خطأ في عملية التحديث',
  menuUpdateSuccess: 'تم تحديث القائمة بنجاح',
  menuUpdateError: 'عفواً، حصل خطأ في عملية التحديث',
  unavailable: 'غير متواجد',
  editOption: 'تحديث الأختيار',
  optionSuccess: 'تم اضافة الأختيار بنجاح',
  optionError: 'عفوا، لايمكن اضافة الأختيار',
  selectionOptional: 'الأختيار اختياري',
  selectionRequired: 'الأختيار اجباري',
  optionDeletedSuccess: 'تم حذف الأختيار بنجاح',
  optionDeletedError: 'تم حذف الأختيار بنجاح',
  selectCategory: 'اختر القسم',
  optionSelected: 'خيارات مختارة',
  itemsSelected: 'أصناف مختارة',
  categoriesSelected: 'أقسام مختارة',
  menusSelected: 'قوائم الطعام مختارة',
  storesSelected: 'متاجر المختارة',
  minSelectionPlaceholder: 'الحد الأدنى للخيارات',
  maxSelectionPlaceholder: 'الحد الأعلى للخيارات',
  optionSetsSelected: 'مجموعة الخيارات المختارة',
  addOptionSet: 'اضف مجموعة خيارات',
  addOptions: 'اضف خيارات',
  type: 'النوع',
  addCategory: 'أضف قسم',
  addMenuItem: 'اضف صنف',
  editCategory: 'تحديث القسم',
  addItem: 'أضف صنف',
  atleastOneItem: 'لابد من تواجد قسم واحد (كحد ادني) لكل صنف',
  item: 'صنف',
  alertMenuOptionPrice: 'الرجاء إدخال السعر',
  alertMenuOption: 'الرجاء ادخال معلومات الأختيارات',
  alertMenuItem: 'الرجاء ادخال معلومات الصنف',
  general: 'عام',
  name: 'الاسم',
  timeAvailability: 'ساعات التواجد',
  code: 'رمز المنصة',
  deleteItemHelper: 'سيتم حذف الصنف بشكل نهائي',
  items: 'أصناف',
  menuItemsSubtitle: 'مثل بيتزا مارقاريتا، بيبسي',
  categoriesSubtitle: 'القسم مثل (بيتزا، مشروبات) يجمع الأصناف مع بعض',
  menusSubtitle:
    'المنيو يقوم بجمعع الأقسام مع بعض (مثال: منيو رمضان, منيو مناسبات خاصة) ',
  menuItemOptionSetSubtitle: 'مجموعة خيارات مثل الإضافات، الحجم...الخ',
  menuItemOptionSubtitle:
    'الأختيار مثل اضافة زيتون الى بيتزا،أو حجم صغير لمشروب',
  options: 'أختيارات',
  optionItems: 'أختيارات',
  option: 'الأختيار',
  newOptions: 'أضف مجموعة اختيارات',
  save: 'حفظ',
  editItem: 'تحديث الصنف',
  addOption: 'أضف اختيار',
  price: 'السعر',
  calories: 'السعرات الحرارية',
  category: 'قسم',
  theCategory: ' القسم',
  selectFromFollowing: 'اختر من التالي:',
  selectExistingOptions: 'اضافة اختيارات مدخلة من قبل',
  selectNewOptions: 'اضافة اختيارات جديدة',
  disableHide: 'اخفاء من القائمة',
  enableSpeech: 'فعل خاصية الحديث',
  tempUnavailable: 'غير متواجد مؤقتاً',
  hidden: 'مخفي',
  minimumSelections: 'حد ادني للخيارات',
  maximumSelections: 'حد اعلى للخيارات',
  required: 'مطلوب',
  deleteOptions: 'احذف مجموعة الأختيارات',
  deleteOption: 'احذف الأختيارا',
  deleteItem: 'احذف الطبق',
  optionAvailabilityStartTime: 'بداية تقديم الاختيار',
  optionAvailabilityEndTime: 'نهاية تقديم الاختيار',
  posCode: 'كود يعرض في المنصة',
  deleteCategory: 'احذف القسم',
  confirmDeleteOption: 'هل انت متأكد تريد حذف الأختيار؟',
  viewMenu: 'اعرض المنيو',
  createStoreFirst: 'لإضافة محتويات المنيو، الرجاء انشأ متجر أولاً',
  deleteItemsFirst:
    'يوجد محتويات في هذا القسم. بإمكانك حذف القس اذا كان لايملك اي محتويات ',
  isRequired: 'اختيار اجباري',
  maximumNumberOfSelections: 'الحد الأعلى للاختيارات',
  minimumNumberOfSelections: 'الحد الأدنى للاختيارات',
  singleChoice: 'أختيار واحد فقط',
  allowMultiple: 'السماح بأكثر من أختيار',
  collapseAll: 'تصغير جميع الأقسام',
  description: 'الوصف',
  nameError: 'لابد من إدخال الأسم ل',
  unavailableInStores: 'المتاجر اللتي لاتحتوي على الصنف. اذا لم يتم الأختيار،',
  unavailableInStoresTip:
    'اذا تم تفعيل الأختيار، سيتم ايقاف الطلبات للصنف،' +
    ' اذا لم يتم اختيار المتاجر، الصنف سيكون غير متواجد في جميع المتاجر',
  tempUnavailableTip:
    'اذا تم التفعيل، سيتم اظهار علامة في المنيو تدل على ان الصنف غير متواجد ولايمكن للعملاء بطلبه.' +
    ' هذه الخاصية مفيدة اذا رغبت في ابلاغ عملائك بأن الصنف غير متواجد بشكل مؤقت فقط',
  enterMax: 'ادخل الحد الأعلى للخيارات',
  enterMin: 'ادخل الحد الأدنى للخيارات',
  maxMustBeGreaterThanMin: 'يجب ان يكون الحد الأعلى اكبر من الحد الأدنى',
  minGreaterThan0: 'يجب ان تكون القيمة اكبر من 0',
};
