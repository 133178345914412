import { Disclosure } from '@headlessui/react';
import classNames from 'classnames';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useCurrentLanguage } from 'src/i18n/hooks/useCurrentLanguage';
import { isRTL } from 'src/translations/utils/languageUtils';
import { SideMenuLink } from 'src/utils/Links';
import StringsHelper from 'src/utils/StringsHelper';

const isActive = (item: SideMenuLink, pathname: string) => {
  if (item.href === pathname) {
    return true;
  }

  if (item.children) {
    for (const subItem of item.children) {
      if (isActive(subItem, pathname)) {
        return true;
      }
    }
  }

  return false;
};

export const SideMenuItemChildren = ({
  item,
  isSubItem = false,
  closeSideMenu,
}: {
  item: SideMenuLink;
  isSubItem?: boolean;
  isActive?: boolean;
  closeSideMenu?: () => void;
}) => {
  const { pathname } = useLocation();

  const { language } = useCurrentLanguage();

  const rtl = isRTL(language);

  const isCurrent = isActive(item, pathname);

  const navigate = useNavigate();

  return (
    <Disclosure
      defaultOpen={isCurrent}
      as="div"
      key={item.name}
      className="space-y-1">
      {({ open, close }) => (
        <>
          <Disclosure.Button
            onClick={() => {
              if (!isActive(item, pathname)) {
                navigate(item.children[0]?.href);
                close();
              }
            }}
            className={StringsHelper.classNames(
              isCurrent
                ? 'bg-gray-100 text-gray-900'
                : 'bg-white text-gray-500 hover:bg-gray-50 hover:text-gray-900',
              'group w-full flex items-center py-2 rtl:text-right ltr:text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-green-50-500',
            )}>
            {item?.icon ? (
              <item.icon
                className="h-6 w-6 flex-shrink-0 group-hover:text-gray-500 mx-2 !text-gray-400 hover:!text-gray-900"
                aria-hidden="true"
              />
            ) : (
              <div
                className={`h-6 w-6 flex-shrink-0 ${
                  isSubItem ? 'ml-2 mr-3' : 'mx-2'
                }`}
              />
            )}

            <span
              className={classNames(
                'flex-1',
                rtl ? 'text-right' : 'text-left',
              )}>
              {item.name}
            </span>
            <svg
              className={StringsHelper.classNames(
                open ? 'text-green-400' : 'text-gray-500',
                'mx-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-600',
                open ? 'rotate-90' : rtl ? 'rotate-180' : '',
              )}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={3}
              stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          </Disclosure.Button>

          <Disclosure.Panel className="space-y-1">
            {item.children.map((subItem, index) =>
              subItem.children ? (
                <SideMenuItemChildren
                  key={index}
                  item={subItem}
                  isSubItem
                  closeSideMenu={closeSideMenu}
                />
              ) : (
                <Link
                  onClick={closeSideMenu}
                  onMouseEnter={subItem.component}
                  to={subItem.href}
                  key={subItem.name}
                  className={StringsHelper.classNames(
                    pathname === subItem.href
                      ? 'text-green-500 bg-gray-100'
                      : 'text-gray-400 group-hover:text-gray-500',
                    `${
                      isSubItem ? 'px-[3.2rem]' : 'px-11'
                    } group flex w-full items-center rounded-md py-2 text-sm font-medium hover:bg-gray-50 hover:text-gray-900`,
                  )}>
                  {subItem.name}
                </Link>
              ),
            )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
