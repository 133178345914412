import { useEffect } from 'react';
import { changeLanguage } from 'src/i18n/changeLanguage';

import { useCurrentLanguage } from '../../i18n/hooks/useCurrentLanguage';
import { SupportedLanguage } from '../../i18n/types';
import { useAnalytics } from '../../mixpanel-analytics/useAnalytics';

export const LanguageSelection = () => {
  const { logEvent } = useAnalytics();

  const { setCurrentLanguage, language } = useCurrentLanguage();

  const handleLanguageChange = (language_: 'ar' | 'en') => {
    logEvent('Change Language Click', {
      $language: language_,
    });
    setCurrentLanguage(language_ as SupportedLanguage);
    changeLanguage(language_ as SupportedLanguage);
  };

  useEffect(() => {
    const lang = localStorage.getItem('fdbt-dab-lng');
    if (lang) {
      // update language on initial page load
      setCurrentLanguage(lang as SupportedLanguage);
    }
  }, [setCurrentLanguage]);

  return (
    <button
      className="border-none backdrop-blur-sm bg-black/50 text-white text-base font-semibold  py-2 px-4 rounded-full shadow-none w-24"
      onClick={() => handleLanguageChange(language === 'ar' ? 'en' : 'ar')}>
      {language === 'ar' ? 'English' : 'عربي'}
    </button>
  );
  return null;
};
