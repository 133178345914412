import { Images } from 'src/common/assets/images';

import { useCurrentLanguage } from '../../i18n/hooks/useCurrentLanguage';
import { useNavigationLinks } from './hooks/useNavigationLinks';
import { SideMenuItem } from './SideMenuItem';
import { SideMenuItemChildren } from './SideMenuItemChildren';

const VERSION = process.env.REACT_APP_VERSION;

export const MenuIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    {...props}>
    <path
      fill="#979EA9"
      d="M173.308,508.674H65.424C29.349,508.674,0,479.323,0,443.248V68.752
C0,32.677,29.349,3.326,65.424,3.326h234.096c36.075,0,65.424,29.351,65.424,65.424v155.538c0,8.43-6.835,15.266-15.266,15.266
s-15.266-6.836-15.266-15.266V68.752c0-19.241-15.652-34.892-34.892-34.892H65.424c-19.24-0.002-34.892,15.651-34.892,34.892
v374.497c0,19.241,15.652,34.892,34.892,34.892h107.884c8.431,0,15.266,6.836,15.266,15.266S181.74,508.674,173.308,508.674z"
    />

    <g>
      <path
        fill="#979EA9"
        d="M256.507,226.181H108.438c-8.431,0-15.266-6.836-15.266-15.266v-94.166
c0-8.43,6.835-15.266,15.266-15.266h148.069c8.431,0,15.266,6.836,15.266,15.266v94.166
C271.773,219.345,264.938,226.181,256.507,226.181z M123.704,195.649h117.537v-63.634H123.704V195.649z"
      />
      <path
        fill="#979EA9"
        d="M212.24,317.17h-59.533c-8.431,0-15.266-6.836-15.266-15.266c0-8.43,6.835-15.266,15.266-15.266
h59.535c8.431,0,15.266,6.836,15.266,15.266C227.507,310.334,220.671,317.17,212.24,317.17z"
      />
      <path
        fill="#979EA9"
        d="M212.24,401.741h-59.533c-8.431,0-15.266-6.836-15.266-15.266s6.835-15.266,15.266-15.266h59.535
c8.431,0,15.266,6.836,15.266,15.266S220.671,401.741,212.24,401.741z"
      />
    </g>

    <path
      fill="#979EA9"
      d="M496.734,508.674H252.477c-8.431,0-15.266-6.836-15.266-15.266v-33.584
	c0-75.759,61.635-137.395,137.395-137.395S512,384.064,512,459.824v33.584C512,501.837,505.165,508.674,496.734,508.674z
	 M267.743,478.141h213.725v-18.318c0-58.924-47.939-106.863-106.863-106.863S267.743,400.9,267.743,459.824V478.141z"
    />
    <path
      fill="#979EA9"
      d="M374.605,352.837c-22.447,0-40.71-18.263-40.71-40.71s18.263-40.71,40.71-40.71
	c22.447,0,40.71,18.263,40.71,40.71S397.053,352.837,374.605,352.837z M374.605,301.951c-5.612,0-10.178,4.566-10.178,10.178
	c0,5.612,4.566,10.178,10.178,10.178c5.612,0,10.178-4.566,10.178-10.178C384.783,306.517,380.217,301.951,374.605,301.951z"
    />
  </svg>
);

export const DesktopSideMenu = () => {
  const { language } = useCurrentLanguage();
  const NAVIGATIONS = useNavigationLinks();

  return (
    <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
      <div className="flex flex-grow flex-col overflow-y-auto border-r border-gray-200 bg-white pt-5">
        <div className="flex flex-shrink-0 items-center px-4">
          <img
            className="h-8 w-auto"
            src={language === 'ar' ? Images.logo_ar : Images.logo}
            alt="Workflow"
          />
        </div>
        <div className="mt-5 flex flex-grow flex-col">
          <nav className="flex-1 space-y-1 bg-white px-3" aria-label="Sidebar">
            {NAVIGATIONS.map((item, index) =>
              !item.children ? (
                <SideMenuItem key={index} item={item} />
              ) : (
                <SideMenuItemChildren key={index} item={item} />
              ),
            )}
          </nav>
          <span className="p-4 text-center">v{VERSION}</span>
        </div>
      </div>
    </div>
  );
};
