export const en = {
  manageContactSettings: 'Manage your Contact settings here!',
  manageReservationSettings: 'Manage your Reservation settings here!',
  addContacts: '+ Add Contacts',
  addReservations: '+ Add Reservations',
  contacts: 'Contacts',
  app: 'App',
  reservations: 'Reservations',
  contactInfo: 'Contact Information',
  contactInfoRequired: 'Contact Information is required',
  nameRequired: 'Name is required',
  noSocialsYet: 'No contacts added yet. Add one to get started!',
  noReservationsYet: 'No reservations added yet. Add one to get started!',
  descriptionReservation: 'add some description about the reservation...',
  urlRequired: 'Url is required',
  reservationNamePlaceholder: 'Reservation for My Pizzeria (Downtown)',
  linkGenerated: ' This is the merchant link we generated for you:',
  headerBackground: 'Header Background',
  storeLogo: 'Store Logo',
  colorTheme: 'Color Theme',
  background: 'Background',
  updateMainLink: 'Update Main Link',
  appLink: 'App Link',
  businessLogo: 'Business Logo',
  businessName: 'Business Name',
  businessAbout: 'About',
};

export const ar: typeof en = {
  manageContactSettings: 'إدارة حسابات التواصل',
  appLink: 'رابط المتجر الألكتروني',
  businessLogo: 'اللوقو',
  businessName: 'اسم المنشأة',
  manageReservationSettings: 'إدارة روابط الحجوزات',
  reservationNamePlaceholder: 'الحجوزات لبيتزاريا',
  updateMainLink: 'تحديث رابط المتجر الألكتروني',
  headerBackground: 'الخلفية العليا',
  background: 'الخلفية',
  storeLogo: 'لوقو المنشأة',
  colorTheme: 'لون الثيم',
  linkGenerated: 'هذا الرابط الأساسي لمتجرك الالكتروني',
  app: 'التطبيق',
  urlRequired: 'الرابط مطلوب',
  reservations: 'الحجوزات',
  addContacts: 'إضافة حساب',
  contactInfo: 'معلومات التواصل',
  descriptionReservation: 'اضف معلومات لرابط الحجز',
  contacts: 'طرق التواصل',
  addReservations: 'اضف رابط للحجوزات',
  contactInfoRequired: 'معلومات الأتصال مطلوبة',
  nameRequired: 'الأسم مطلوب',
  noSocialsYet: 'لايوجد حسابات للسوشل ميدا',
  noReservationsYet: 'لايوجد روابط للحجوزات',
  businessAbout: 'عن المنشأة',
};
