import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ROUTECOMPONENTS, ROUTES } from 'src/utils/Links';

export interface HeaderInfo {
  title: string;
  subTitle?: string;
  href?: string;
  action?: string;
  component?: (() => Promise<unknown>) | (() => () => Promise<unknown>);
  CTAs?: JSX.Element | JSX.Element[];
  backButtonProps?: DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > & { isVisible: boolean };
}

export const useHeaderInfo = (): HeaderInfo | undefined => {
  const { pathname } = useLocation();

  const { t } = useTranslation();

  const headers: {
    [route in keyof typeof ROUTES]?: HeaderInfo;
  } = {
    [ROUTES.STORES]: {
      title: t('stores:stores'),
      subTitle: t('stores:storesSubtitle'),
      href: ROUTES.ADD_STORE,
      component: () => ROUTECOMPONENTS.ADD_STORE,
      action: t('common:addStore'),
    },
    [ROUTES.MENUS]: {
      title: t('menu:menus'),
      subTitle: t('menu:menusSubtitle'),
      href: ROUTES.ADD_MENU,
      component: () => ROUTECOMPONENTS.ADD_MENU,
      action: t('menu:addMenu'),
    },
    [ROUTES.CATEGORIES]: {
      title: t('menu:categories'),
      subTitle: t('menu:categoriesSubtitle'),
      href: ROUTES.ADD_CATEGORY,
      component: () => ROUTECOMPONENTS.ADD_CATEGORY,
      action: t('menu:addCategory'),
    },
    [ROUTES.ITEMS]: {
      title: t('menu:menuItems'),
      subTitle: t('menu:menuItemsSubtitle'),
      href: ROUTES.ADD_ITEM,
      component: ROUTECOMPONENTS.ADD_ITEM,
      action: t('menu:addItem'),
    },
    [ROUTES.ORDERS]: {
      title: t('orders:order'),
      subTitle: t('orders:ordersSubtitle'),
    },
    [ROUTES.CUSTOMERS]: {
      title: t('customers:customers'),
      subTitle: t('customers:customersSubtitle'),
    },
    [ROUTES.CUSTOMER_DETAILS]: {
      title: t('customers:customerDetails'),
      backButtonProps: { isVisible: true },
    },
    [ROUTES.DEVICES]: {
      title: t('devices:posLogins'),
      subTitle: t('devices:devicesSubtitle'),
    },
    [ROUTES.OPTION_SETS]: {
      title: t('menu:optionSets'),
      subTitle: t('menu:menuItemOptionSetSubtitle'),
      href: ROUTES.ADD_OPTIONـSET,
      component: ROUTECOMPONENTS.ADD_OPTIONـSET,
      action: t('menu:addOptionSet'),
    },
    [ROUTES.SETTINGS]: {
      title: t('settings:settings'),
    },
    [ROUTES.OPTIONS]: {
      title: t('menu:optionItems'),
      subTitle: t('menu:menuItemOptionSubtitle'),
      href: ROUTES.ADD_OPTION,
      component: ROUTECOMPONENTS.ADD_OPTION,
      action: t('menu:addOption'),
    },
    [ROUTES.FEEDBACK]: {
      title: t('feedback:feedback'),
      subTitle: t('feedback:feedbackSubtitle'),
    },
    [ROUTES.EDIT_ITEM]: {
      title: t('menu:editItem'),
      backButtonProps: { isVisible: true },
    },
    [ROUTES.EDIT_STORE]: {
      title: t('stores:editStore'),
      backButtonProps: { isVisible: true },
    },
    [ROUTES.EDIT_MENU]: {
      title: t('menu:editMenu'),
      backButtonProps: { isVisible: true },
    },
    [ROUTES.EDIT_CATEGORY]: {
      title: t('menu:editCategory'),
      backButtonProps: { isVisible: true },
    },
    [ROUTES.EDIT_OPTIONSET]: {
      title: t('menu:editOptionsSet'),
      backButtonProps: { isVisible: true },
    },
    [ROUTES.EDIT_OPTION]: {
      title: t('menu:editOption'),
      backButtonProps: { isVisible: true },
    },
    [ROUTES.EDIT_DEVICE]: {
      title: t('devices:edit'),
      backButtonProps: { isVisible: true },
    },
    [ROUTES.COUPONS]: {
      title: t('coupons:coupons'),
      href: ROUTES.ADD_COUPON,
      action: t('coupons:addCoupon'),
      subTitle: t('coupons:addCouponDescription'),
    },

    [ROUTES.DISCOUNTS]: {
      title: t('discounts:discounts'),
      href: ROUTES.ADD_DISCOUNT,
      action: t('discounts:addDiscount'),
      subTitle: t('discounts:addDiscountDescription'),
    },
  };

  return headers[pathname];
};
